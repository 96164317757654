import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import {
  gql,
  useMutation,
  useQuery,
} from '@apollo/client';
import { BankAccountSelectField } from 'ovComponents/3-pattern/bankAccountSelectField/bankAccountSelect';
import {
  Box,
  Grid,
  Link,
  Typography,
} from 'ovComponents/1-primative';
import {
  Button,
  DateField,
  Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton, MenuItem, SelectField,
} from 'ovComponents/2-component';
import dayjs from 'dayjs';
import { useThemeTokens } from 'providers/themeTokenProvider';
import { AmountField } from 'ovComponents/3-pattern';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const CREATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation createScheduledIncomeFundTransfer($input: CreateScheduledIncomeFundTransferInput!) {
    createScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

export const UPDATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation updateScheduledIncomeFundTransfer($input: UpdateScheduledIncomeFundTransferInput!) {
    updateScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        dateOfBirth
        language
        bankAccounts {
          id
          name
          bankAccountNumber
        }
      }
    }
  }
`;

export const PaymentInstructionModal = ({
  open, setOpen, action = 'create', userId, refetch, account, fundTitle,
}: {
  open: boolean, setOpen: (open: boolean) => void, action?: string,
  userId: string, refetch?: any, account: any, fundTitle?: string,
}) => {
  const isEditMode = action === 'edit';
  const { t } = useTranslation(['paymentInstruction']);
  const { sys } = useThemeTokens();
  const [bankAccount, setBankAccount] = useState<{ id: string, name: string, bankAccountNumber: string, bankAccountNumberDisclosed?: string }>(
    {
      id: account?.scheduledIncomeFundTransfer?.bankAccount?.id ?? '',
      name: account?.scheduledIncomeFundTransfer?.bankAccount?.name ?? '',
      bankAccountNumber: account?.scheduledIncomeFundTransfer?.bankAccount?.bankAccountNumber ?? '',
      bankAccountNumberDisclosed: '',
    },
  );
  const [totalAnnualWithdrawal, setTotalAnnualWithdrawal] = useState<string>(account?.scheduledIncomeFundTransfer?.amountPayableType ? 'Custom' : 'Minimum');
  const [frequency, setFrequency] = useState<string>(account?.scheduledIncomeFundTransfer?.frequency ?? '');
  const [amount, setAmount] = useState<number | undefined>(account?.scheduledIncomeFundTransfer?.annualAmountCents ?? undefined);
  const [amountPayableType, setAmountPayableType] = useState<string>(account?.scheduledIncomeFundTransfer?.amountPayableType ?? undefined);
  const [scheduledDate, setScheduledDate] = useState<string>(account?.scheduledIncomeFundTransfer?.scheduledDate ?? '');
  const decideWhoseAgeWasSelected = (): string => {
    if (!account?.scheduledIncomeFundTransfer?.dateOfBirth) return '';
    if (account?.affiliations && account?.affiliations.length > 0 && account?.affiliations[0]?.user?.dateOfBirth === account?.scheduledIncomeFundTransfer?.dateOfBirth) {
      return 'MY_SPOUSE';
    }
    return 'MY_AGE';
  };
  const [age, setAge] = useState<string>(decideWhoseAgeWasSelected());
  const [focused, setFocused] = useState<string[]>([]);
  const getAge = (userDOB?: Date): string => {
    if (!userDOB) return '0';
    const today = new Date();
    const birthDate = new Date(userDOB);
    let myAge = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      myAge -= 1;
    }
    return myAge.toString();
  };

  const { data, loading } = useQuery(FETCH_USER, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });
  const getDOB = () => {
    if (age === 'MY_AGE') {
      return loading ? '' : data.fetchUser.user.dateOfBirth;
    }
    if (account.affiliations.length > 0) {
      return account.affiliations[0].user.dateOfBirth;
    }
    return '';
  };
  const [updateScheduledIncomeFundTransfer] = useMutation(UPDATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        scheduledIncomeFundTransferId: account?.scheduledIncomeFundTransfer?.id,
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount.id,
        frequency,
        scheduledDate,
        annualAmountCents: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? 0 : Number(amount),
        amountPayableType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        specialTaxRate: 0,
        transferredAmountCents: 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });
  const [createScheduledIncomeFundTransfer] = useMutation(CREATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        dateOfBirth: getDOB(),
        subAccountId: account.subAccounts && account.subAccounts.length > 0 ? account.subAccounts[0].id : '',
        bankAccountId: bankAccount.id,
        frequency,
        scheduledDate,
        annualAmountCents: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : Number(amount),
        amountPayableType: totalAnnualWithdrawal.toLowerCase() === 'minimum' ? undefined : amountPayableType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        transferredAmountCents: 0,
        specialTaxRate: 0,
      },
    },
    onCompleted: () => {
      refetch && refetch();
      setOpen(false);
    },
  });
  const customLabel = (label: string, hasMarginTop: boolean) => (
    <Typography display="flex" alignItems="center" colorVariant='variant' sx={{ mt: hasMarginTop ? 2 : 0 }}>
      {label}
      <InfoOutlinedIcon style={{ fontSize: '14px', marginLeft: sys.spacing.sm }} />
    </Typography>
  );
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ border: 'none', marginBottom: '0px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{t('paymentInstruction:modal.title')}</Typography>
          <IconButton data-testid="icon-btn" onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        <Typography component={'span'} variant='bodyMedium' sx={{ fontWeight: '400', mt: 2 }}>
          <span>{t('paymentInstruction:modal.subtitle.1', { fundPrefix: fundTitle })}</span>
          <Link sx={{ mx: 0.5 }} >{t('paymentInstruction:modal.subtitle.2')}</Link>
          <span>{t('paymentInstruction:modal.subtitle.3')}</span>
        </Typography>
      </DialogTitle>
      <Form onSubmit={() => {
        if (isEditMode) {
          updateScheduledIncomeFundTransfer().then();
        } else {
          createScheduledIncomeFundTransfer().then();
        }
      }}>
        <DialogContent>
          {customLabel(t('paymentInstruction:modal.ageSelectLabel'), false)}
          <SelectField
            data-testid="select-age"
            fullWidth
            label={''}
            value={age}
            onChange={(e: any) => setAge(e.target.value)}
            onBlur={() => setFocused([...focused, 'age'])}
            error={!age && focused.includes('age')}
          >
            {loading || !data?.fetchUser.user.dateOfBirth ? undefined : <MenuItem value='MY_AGE'>
              {t('paymentInstruction:modal.myAgeSelectOption', { age: getAge(data.fetchUser.user.dateOfBirth) })}
            </MenuItem>}
            {!loading && account.affiliations.length > 0 ? <MenuItem value='SPOUSE_AGE'>
              {t('paymentInstruction:modal.mySpousesAgeSelectOption', { age: getAge(account.affiliations[0].user.dateOfBirth) })}
            </MenuItem> : undefined}
          </SelectField>
          {customLabel(t('paymentInstruction:modal.totalAnnualWithdrawalLabel'), true)}
          <SelectField
            data-testid="total-withdrawal-amount"
            fullWidth
            label={''}
            value={totalAnnualWithdrawal}
            onChange={(e: any) => setTotalAnnualWithdrawal(e.target.value)}
            onBlur={() => setFocused([...focused, 'totalAnnualWithdrawal'])}
            error={!totalAnnualWithdrawal && focused.includes('totalAnnualWithdrawal')}
          >
            <MenuItem data-testid="total-withdrawal-amount-option-minimum" value='Minimum'>{t('paymentInstruction:modal.totalAnnualWithdrawalMinimumAmountOption')}</MenuItem>
            <MenuItem data-testid="total-withdrawal-amount-option-custom" value='Custom'>{t('paymentInstruction:modal.totalAnnualWithdrawalCustomAmountOption')}</MenuItem>
          </SelectField>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            {totalAnnualWithdrawal.toLowerCase() === 'custom' ? (
              <Grid item xs={6}>
                <AmountField
                  label={t('paymentInstruction:modal.amountLabel')}
                  amount={amount ? (amount / 100).toString() : ''}
                  setAmount={(e) => setAmount(Number(e) * 100)}
                  onBlur={() => setFocused([...focused, 'amount'])}
                  error={!amount && focused.includes('amount')}
                />
              </Grid>
            ) : undefined}
            <Grid item xs={6}>
              <SelectField
                data-testid="payment-frequency"
                fullWidth
                label={t('paymentInstruction:modal.paymentFrequencyLabel')}
                value={frequency}
                onChange={(e: any) => setFrequency(e.target.value)}
                onBlur={() => setFocused([...focused, 'paymentFrequency'])}
                error={!frequency && focused.includes('paymentFrequency')}
              >
                <MenuItem data-testid="payment-frequency-option-annually" value='ANNUALLY'>{t('paymentInstruction:modal.ANNUALLY')}</MenuItem>
                <MenuItem data-testid="payment-frequency-option-semi-annually" value='SEMI_ANNUALLY'>{t('paymentInstruction:modal.SEMI_ANNUALLY')}</MenuItem>
                <MenuItem data-testid="payment-frequency-option-quarterly" value='QUARTERLY'>{t('paymentInstruction:modal.QUARTERLY')}</MenuItem>
                <MenuItem data-testid="payment-frequency-option-monthly" value='MONTHLY'>{t('paymentInstruction:modal.MONTHLY')}</MenuItem>
              </SelectField>
            </Grid>
            {totalAnnualWithdrawal.toLowerCase() === 'custom' ? (
              <Grid item xs={6}>
                {customLabel(t('paymentInstruction:modal.withdrawalTypeLabel'), false)}
                <SelectField
                  data-testid="withdrawal-type"
                  fullWidth
                  label={''}
                  value={amountPayableType}
                  onChange={(e: any) => setAmountPayableType(e.target.value)}
                  onBlur={() => setFocused([...focused, 'amountPayableType'])}
                  error={!amountPayableType && focused.includes('amountPayableType')}
                >
                  <MenuItem data-testid="withdrawal-type-option-net" value='NET'>{t('paymentInstruction:modal.withdrawalTypeNetOption')}</MenuItem>
                  <MenuItem data-testid="withdrawal-type-option-gross" value='GROSS'>{t('paymentInstruction:modal.withdrawalTypeGrossOption')}</MenuItem>
                </SelectField>
              </Grid>
            ) : undefined}
            <Grid item xs={6}>
              <DateField
                data-testid="start-date"
                onChange={(date: any) => setScheduledDate(dayjs(date?.toString()).format('YYYY-MM-DD'))}
                disabled={loading}
                label={t('paymentInstruction:modal.startDateLabel')}
                fullWidth
                value={scheduledDate}
                onBlur={() => setFocused([...focused, 'scheduledDate'])}
                error={!scheduledDate && focused.includes('scheduledDate')}
              />
            </Grid>
          </Grid>
          <BankAccountSelectField
            data-testid="bank-account-select"
            sx={{ mt: 2 }}
            setBankAccount={(e: any) => setBankAccount(e)}
            bankAccount={bankAccount}
            userId={userId}
            onBlur={() => setFocused([...focused, 'bankAccount'])}
            error={!bankAccount?.id && focused.includes('bankAccount')}
          />
          <Box
            style={{
              backgroundColor: sys.color.surfaceContainer,
              borderRadius: sys.borderRadius.md,
              padding: sys.spacing.xl,
              marginTop: sys.spacing.xxl,
            }}
          >
            <Typography component="span" variant='bodyMedium' sx={{ fontWeight: '400' }}>
              <p>
                {t('paymentInstruction:modal.note1')}
              </p>
              <p>
                {t('paymentInstruction:modal.note2')}
              </p>
            </Typography>
          </Box>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button data-testid="done-btn" label={action === 'create' ? t('shared:done') : t('client:form.update')} type='submit' variant='tonal' />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
