import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, TextField, Grid,
} from '../../../..';

export const SimpleForm = ({
  options, onNext, data, onChangeSetData, stepLoading = false,
}: {
  options: any, onNext: any, data: any, onChangeSetData: Dispatch<SetStateAction<any>>, stepLoading?: boolean,
}) => {
  const { t } = useTranslation('workflowCompletions');
  const [focused, setFocused] = useState<string[]>([]);

  const submit = () => {
    const errors = invalidFields(options, data);
    if (errors.length === 0) {
      onNext();
    }
  };
  const errors = invalidFields(options, data);

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <Grid container spacing={2}>
        {Object.keys(options)?.map((key) => (
          options[key].enabled && (
            <Grid key={key} item xs={12} md={12}>
              <TextField
                label={translateBackend(options[key].label)}
                onChange={(e: any) => onChangeSetData((prev: any) => ({ ...prev, [key]: e.target.value }))}
                value={data[key] ?? ''}
                onBlur={() => setFocused([...focused, key])}
                error={!data[key] && focused.includes(key) && options[key]?.required !== 'NOT_REQUIRED'}
                fullWidth
                sx={{ mb: 2 }}
                disabled={stepLoading}
              />
            </Grid>
          )
        ))}
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' disabled={errors.length > 0 || stepLoading} />
      </Box>
    </Form>
  );
};

export default SimpleForm;
