import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import backgroundImage from 'assets/images/brand_background_dark.png';
import { Box, Button, Typography } from 'ovComponents';

export const Anchor = styled('a')(({ theme }) => ({
  color: 'white',
  '&:visited': {
    color: 'white',
  },
  '&:hover': {
    filter: 'brightness(85%)',
  },
}));

const ErrorBoundary = () => {
  const { t } = useTranslation(['errorBoundaries.json', 'workflowCompletions']);

  return (
    <section
      style={{
        position: 'absolute',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      <Box display='flex' flexDirection='column' alignItems='center' color='white' gap={3}>
        <h1 style={{ fontSize: '4em', margin: 2 }}>{t('errorBoundaries:base.title')}</h1>
        <Typography variant='displaySmall'>{t('errorBoundaries:base.subtitle')}</Typography>
        <Anchor href='/' style={{ marginTop: '48px' }}><Button variant='tonal' label={t('errorBoundaries:base.redirectLabel')} /></Anchor>
        <Anchor href='https://onevest.zendesk.com' color='white'>
          <Typography variant='bodyMedium'>{t('errorBoundaries:base.helpCenterLink')}</Typography>
        </Anchor>
      </Box>
    </section>
  );
};

export default ErrorBoundary;
