import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Badge, Tooltip } from 'ovComponents/2-component';
import { UserContext } from 'providers/userContextProvider';

export const PendingTransactionBadge = ({ transaction }: { transaction: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);

  if (!activeOrganization?.allowPendingTransactions || !transaction.pending) return null;

  const tooltipTitle = t('components:transaction.expectedSettlementDate', {
    settlementDate: transaction.settlementDate ? dayjs(transaction.settlementDate).format('YYYY-MM-DD') : 'TBD',
  });

  return (
    <Tooltip title={tooltipTitle} placement='top'>
      <span style={{ marginLeft: '8px' }}>
        <Badge label={t('components:transaction.requested')} />
      </span>
    </Tooltip>
  );
};
