import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { SelectField } from '../../2-component';
import { Theme } from '../../../interfaces';
import { translateBackend } from '../../../assets/i18n/config';

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        translatedName {
          en fr
        }
        translatedDescription {
          en fr
        }
        id
      }
      totalCount
    }
  }
`;
interface ThemeSelectProp {
  userId?: string
  label: string,
  onThemeSelect: (theme: Theme) => void,
  selectedTheme: Theme | undefined,
  sx?: any,
  size?: 'small' | 'medium',
  showValue?: boolean,
  filter?: any,
}
export const ThemeSelect = ({
  userId, label, onThemeSelect, selectedTheme, sx = {}, size, showValue = false, filter,
}: ThemeSelectProp) => {
  const { data } = useQuery(FETCH_THEMES, {
    variables: {
      input: {
        filter: filter || {
          userId,
        },
      },
    },
  });
  return (
    <SelectField
      onChange={(e: any) => onThemeSelect(data?.fetchThemes?.themes?.find((item: Theme) => item.id === e.target.value))}
      label={label}
      fullWidth
      sx={sx}
      size={size}
      value={selectedTheme?.id ?? ''}
    >
      {
        data?.fetchThemes?.themes?.map((theme: Theme) => (
          <MenuItem value={theme.id}>{translateBackend(theme.translatedName)}</MenuItem>
        )) ?? []
      }
    </SelectField>
  );
};
