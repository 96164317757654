import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, SelectionTile, Box, TextField,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { GoalTypes } from '../../../../../interfaces';
import { GoalData } from './createGoal';

export const CreateGoalVisual = ({
  options, goalData, loading, updateGoal, continueFunc, workflowCompletion,
}: {
  options: any, goalData: GoalData, loading: boolean, updateGoal: (g: GoalData) => void, continueFunc: () => void,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [otherNameIsEmpty, setOtherNameIsEmpty] = useState<boolean>(false);

  const goalConfig: Record<string, { value: string, label: string }> = {
    retirement: { value: 'RETIREMENT', label: t('goalType.RETIREMENT') },
    retirementIncome: { value: 'RETIREMENT_INCOME', label: t('goalType.RETIREMENT_INCOME') },
    buildWealth: { value: 'BUILD_WEALTH', label: t('goalType.BUILD_WEALTH') },
    legacy: { value: 'LEGACY', label: t('goalType.LEGACY') },
    majorPurchase: { value: 'MAJOR_PURCHASE', label: t('goalType.MAJOR_PURCHASE') },
    house: { value: 'HOUSE', label: t('goalType.HOUSE') },
    safetyNet: { value: 'SAFETY_NET', label: t('goalType.SAFETY_NET') },
    cashReserve: { value: 'CASH_RESERVE', label: t('goalType.CASH_RESERVE') },
    car: { value: 'CAR', label: t('goalType.CAR') },
    education: { value: 'EDUCATION', label: t('goalType.EDUCATION') },
    vacation: { value: 'VACATION', label: t('goalType.VACATION') },
    startABusiness: { value: 'START_A_BUSINESS', label: t('goalType.START_A_BUSINESS') },
    wedding: { value: 'WEDDING', label: t('goalType.WEDDING') },
    group: { value: 'GROUP', label: t('goalType.GROUP') },
    parentalLeave: { value: 'PARENTAL_LEAVE', label: t('goalType.PARENTAL_LEAVE') },
    other: { value: 'OTHER', label: t('goalType.OTHER') },
  };

  const submit = () => {
    const fields = invalidFields(options, goalData);
    const otherHasNoName = goalData.type === 'OTHER' && !goalData?.name?.trim();
    setOtherNameIsEmpty(otherHasNoName);

    if (fields.length === 0 && !otherHasNoName) {
      sendAnalytic(ovAnalyticsEvents.workflowsCreateGoalContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  if (!options) return <></>;

  const goalOptions = Object.keys(goalConfig)
    .filter((key: string) => (options[key] === true || options[key]?.enabled))
    .map((key: string) => {
      if (options[key].customLabelsTemp?.goalLabel.enabled) {
        const customLabel = translateBackend(options[key].customLabelsTemp.goalLabel);
        return {
          value: goalConfig[key].value,
          label: customLabel !== '' ? customLabel : goalConfig[key].label,
        };
      }
      return goalConfig[key];
    });

  const getGoalName = (type: GoalTypes) => {
    const goalOption = goalOptions.find((o) => o.value === type);
    return goalOption?.label ?? type;
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>

      <SelectionTile
        data-testid="goal-type"
        onChange={(e: any) => updateGoal({ type: e.target.value, name: getGoalName(e.target.value) })}
        value={goalData.type}
        options={goalOptions}
      />

      {goalData.type === 'OTHER' && (
        <TextField
          data-testid="goal-name"
          onChange={(e: any) => updateGoal({ ...goalData, name: e.target.value.trimStart() })}
          disabled={loading}
          label={t('goalName')}
          sx={{ mt: 2 }}
          fullWidth
          onBlur={() => setOtherNameIsEmpty(false)}
          error={otherNameIsEmpty}
          value={goalData.name}
        />
      )}

      <Box display='flex' justifyContent='end'>
        <Button
          data-testid="continue-button"
          label={t('continue')}
          disabled={loading || !goalData.type}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default CreateGoalVisual;
