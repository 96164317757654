import {
  Box, Tabs, Tab, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../providers/userContextProvider';
import { usePageState } from '../../util/usePageState';
import Activities from './components/activities';
import Flags from './components/flags';
import Jobs from './components/jobs';

const TabPanel = (props: any) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const Reconciliation = () => {
  const { permissions } = usePermissions();
  const { t } = useTranslation(['reconciliation']);
  const [value, setValue] = usePageState(0, 'tab');
  useNavigate();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          { permissions.includes('read:flags') && (
            <Tab label={t('tabs.flags')} {...a11yProps(0)} disableRipple />
          )}
          { permissions.includes('read:custodian_activity_basic') && (
            <Tab label={t('tabs.activities')} {...a11yProps(1)} disableRipple />
          )}
          { permissions.includes('read:recon_jobs ') && (
            <Tab label={t('tabs.jobs')} {...a11yProps(1)} disableRipple />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Flags />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Activities />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Jobs />
      </TabPanel>
    </Paper>
  );
};

export default Reconciliation;
