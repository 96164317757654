import {
  Typography, Box, CircularProgress, Table, TableHead, TableRow,
  TableCell, TableBody, Pagination,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { translateBackend } from '../../../assets/i18n/config';
import {
  Button,
  Dialog, DialogContent, DialogTitle, IconButton, Typography as OVTypography,
} from '../../../ovComponents';
import { NotificationDefinitionForm } from '../../../ovComponents/4-module/notificationDefinitionForm/notificationDefinitionForm';

const FETCH_NOTIFICATION_DEFINITIONS = gql`
  query fetchNotificationDefinitions($input: FetchNotificationDefinitionsInput!) {
    fetchNotificationDefinitions(input: $input) {
      notificationDefinitions {
        id
        name
        titleTemplate { en fr }
        bodyTemplate { en fr }
        linkTemplate
        linkText { en fr }
        triggerObjectType
        triggerEventType
        roleProfiles { id translatedName { en } }
        notificationGroup {
          id
          name { en fr }
        }
        organization {
          name
        }
      }
    }
  }
`;

const NotificationDefinitions = () => {
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<'edit' | 'create'>('edit');
  const [activeNotificationDefinition, setActiveNotificationDefinition] = useState<any>({});
  const pageSize = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_NOTIFICATION_DEFINITIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
        pagination: { perPage: 50 },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1, mr: 1, mb: 1,
          }}>
            <Button onClick={() => {
              setAction('create');
              setOpen(true);
            }} label={t('notificationDefinitionTable.add')} variant='outlined' leadingIcon={Add} />
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.name')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.object')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.event')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.notificationGroup')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.roleProfiles')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('notificationDefinitionTable.organization')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.fetchNotificationDefinitions?.notificationDefinitions?.map((notificationDefinition: any) => (
                <TableRow
                  key={notificationDefinition.id}
                  sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => {
                    setActiveNotificationDefinition(notificationDefinition);
                    setAction('edit');
                    setOpen(true);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {notificationDefinition.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {notificationDefinition.triggerObjectType}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {notificationDefinition.triggerEventType || t('notificationDefinitionTable.workflow')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {translateBackend(notificationDefinition.notificationGroup.name)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {notificationDefinition.roleProfiles.map((roleProfile: any) => roleProfile.translatedName.en).join(', ')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {notificationDefinition.organization.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchNotificationDefinitions?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          <Dialog open={open} onClose={() => {
            setOpen(false);
            setActiveNotificationDefinition({});
          }} fullWidth maxWidth='sm'>
            <DialogTitle>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <OVTypography variant='headingSmall'>{t('notificationDefinitionModal.title')}</OVTypography>
                <IconButton onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <NotificationDefinitionForm baseNotificationDefinition={activeNotificationDefinition} action={action} afterSubmit={() => {
                setOpen(false);
                setActiveNotificationDefinition({});
                refetch();
              }} onRemove={() => {
                setOpen(false);
                setActiveNotificationDefinition({});
                refetch();
              }}triggerEvent={action === 'create' || activeNotificationDefinition.triggerEventType !== null} />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default NotificationDefinitions;
