/* eslint-disable no-console */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../providers/globalToastProvider';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean> ;// Return success

function useCopyToClipboard(): [CopyFn] {
  const { showToast } = useGlobalToast();
  const { t } = useTranslation('client');
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text: string) => {
    if (!navigator?.clipboard) {
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      // selected
      console.log(copiedText);
      showToast({ severity: 'info', message: `${t('copied')}` });
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copy];
}

export default useCopyToClipboard;
