export const WIDGET_TYPES: any = {
  INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'PERSONAL_INFORMATION',
    'RELATIONSHIP_INFORMATION',
    'EMPLOYMENT_INFORMATION',
    'RESIDENCY_INFORMATION',
    'PERSONS_OF_INTEREST',
    'INCOME_ASSETS_AND_DEBTS',
    'SUITABILITY',
    'ID_VERIFICATION',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ACCESS',
    'ADVISOR_CONTACT_INFORMATION',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'COMPLIANCE',
    'REVIEW_BANK_ACCOUNT_INFORMATION',
    'TRANSFERS',
    'HTML_TEMPLATE',
    'MODEL_PORTFOLIO_HOLDINGS',
  ],
  NON_INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NON_INDIVIDUAL_INFORMATION',
    'RESIDENCY_INFORMATION',
    'PERSONS_OF_INTEREST',
    'SUITABILITY',
    'ID_VERIFICATION',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ACCESS',
    'ADVISOR_CONTACT_INFORMATION',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'COMPLIANCE',
    'REVIEW_BANK_ACCOUNT_INFORMATION',
    'TRANSFERS',
    'RELATIONSHIPS',
    'HTML_TEMPLATE',
    'MODEL_PORTFOLIO_HOLDINGS',
  ],
  GOAL: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'LEDGER',
    'GOAL_SUITABILITY',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'SUB_ACCOUNTS',
    'REFINITIV',
    'PORTFOLIO',
    'TRANSFERS',
    'BULK_TRADE_REQUESTS',
    'MODEL_PORTFOLIO_HOLDINGS',
  ],
  ACCOUNT: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'DOCUMENTS',
    'EDIT_ACCOUNT',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'SUB_ACCOUNTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'PROJECTED_INCOME_REPORT',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'TRANSFERS',
    'MODEL_PORTFOLIO_HOLDINGS',
  ],
  SUB_ACCOUNT: [
    'MARKET_VALUE_CHART',
    'HOLDINGS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'PORTFOLIO',
    'TRANSFERS',
    'BULK_TRADE_REQUESTS',
    'MODEL_PORTFOLIO_HOLDINGS',
  ],
  HOUSEHOLD: [
    'MARKET_VALUE_CHART',
    'ACCOUNTS',
    'GOALS',
    'GOALS_AND_ACCOUNTS',
    'HOLDINGS',
    'DOCUMENTS',
    'LEDGER',
    'SCHEDULED_TRANSFERS',
    'UPCOMING_TRANSACTIONS',
    'NEWS_AND_INSIGHTS',
    'CONTACTS',
    'EXPOSURE',
    'RECENT_TRANSACTIONS',
    'REFINITIV',
    'ADVISOR_CONTACT_INFORMATION',
    'PROJECTED_INCOME_REPORT',
    'NOTES',
    'AUDIT_LOG',
    'TRADE_ORDERS',
    'FEE_DETAILS',
    'HOUSE_HOLD_MEMBERS',
    'TRANSFERS',
  ],
};
