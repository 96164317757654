import { MaritalStatus } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { FieldOptions } from '../utils';
import { AffiliateType } from './components/affiliate';

export enum SectionType {
  AFFILIATE = 'AFFILIATE',
  RRIF_PAYMENT_INSTRUCTIONS = 'RRIF_PAYMENT_INSTRUCTIONS',
  RESP_GRANTS = 'RESP_GRANTS',
  JURISDICTION = 'JURISDICTION',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  LINK_TO_HOUSEHOLD = 'LINK_TO_HOUSEHOLD',
  CUSTODIAN = 'CUSTODIAN',
  BASE_CURRENCY = 'BASE_CURRENCY',
  MULTI_CURRENCY = 'MULTI_CURRENCY',
}

export enum RelationType {
  CHILD = 'CHILD',
  COMMON_LAW = 'COMMON_LAW',
  GRANDCHILD = 'GRANDCHILD',
  GRANDPARENT = 'GRANDPARENT',
  GUARDIAN = 'GUARDIAN',
  NEPHEW_NIECE = 'NEPHEW_NIECE',
  OTHER = 'OTHER',
  PARENT = 'PARENT',
  PRIMARY_CAREGIVER = 'PRIMARY_CAREGIVER',
  SIBLING = 'SIBLING',
  SPOUSE = 'SPOUSE',
}

export interface AccountConfigInterface {
  [key: string]: {
    sections: {
      type: SectionType,
      affiliateType?: AffiliateType,
      defaultRelationType?: RelationType,
      multi?: boolean,
      showAllocation?: boolean,
      showRelationship?: boolean,
      excludeProvinces?: string[],
      edittableAfterOpen: boolean,
      fields?: {
        type?: string,
        options?: {
          label: string,
          value: string,
        }[],
      }[],
      title?: string,
      excludeMaritalStatus?: string[],
      useAccountHoldersAddress?: boolean,
      titlePrefix?: string,
      required?: boolean,
    }[],
    fieldOptions?: FieldOptions,
  }
}

export const AccountConfig = (): AccountConfigInterface => {
  const { t } = useTranslation('affiliationTypes');

  return ({
    PERSONAL: {
      sections: [
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    CASH_JOINT: {
      sections: [
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RDSP: {
      sections: [
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RESP_FAMILY_JOINT: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          required: true,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'gender' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
              ],
            },
          ],
        },
        {
          type: SectionType.RESP_GRANTS,
          edittableAfterOpen: false,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RIF_SPOUSAL: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.CONTRIBUTOR,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          useAccountHoldersAddress: true,
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:rifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RESP_SINGLE_JOINT: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          required: true,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'gender' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
              ],
            },
          ],
        },
        {
          type: SectionType.RESP_GRANTS,
          edittableAfterOpen: false,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RRSP: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: true,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    TFSA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'taxId' },
            { type: 'physicalAddress' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: true,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    FHSA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'taxId' },
            { type: 'physicalAddress' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: true,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    LRSP: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RLSP: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    LIRA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    LRIF: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:rifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    PRIF: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:rifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    LIF: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:rifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RLIF: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          required: true,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:lifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.JURISDICTION,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        jurisdiction: { required: true },
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RRSP_SPOUSAL: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.CONTRIBUTOR,
          required: true,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          useAccountHoldersAddress: true,
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RRIF: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
            { type: 'employmentStatus' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
          title: t('affiliationTypes:button.SPOUSE_OR_COMMON_LAW_PARTNER'),
          useAccountHoldersAddress: true,
          excludeMaritalStatus: [MaritalStatus.SINGLE, MaritalStatus.DIVORCED, MaritalStatus.SEPARATED, MaritalStatus.WIDOWED],
        },
        {
          type: SectionType.RRIF_PAYMENT_INSTRUCTIONS,
          edittableAfterOpen: true,
          titlePrefix: t('paymentInstruction:rifTitle'),
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.SUCCESSOR,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
              ],
            },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          showAllocation: true,
          showRelationship: true,
          excludeProvinces: ['QC'],
          edittableAfterOpen: false,
          fields: [
            { type: 'fullName' },
            { type: 'relation' },
            { type: 'allocation' },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RESP_FAMILY: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          required: true,
          affiliateType: AffiliateType.OTHER,
          defaultRelationType: RelationType.GUARDIAN,
          multi: false,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'primaryEmail' },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          defaultRelationType: RelationType.PRIMARY_CAREGIVER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: true,
          required: true,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'gender' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
              ],
            },
          ],
        },
        {
          type: SectionType.RESP_GRANTS,
          edittableAfterOpen: false,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RESP_SINGLE: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          defaultRelationType: RelationType.GUARDIAN,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'primaryEmail' },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.OTHER,
          defaultRelationType: RelationType.PRIMARY_CAREGIVER,
          multi: false,
          required: true,
          showAllocation: false,
          showRelationship: false,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'primaryEmail' },
            { type: 'taxId' },
          ],
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          required: true,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'gender' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
              ],
            },
          ],
        },
        {
          type: SectionType.RESP_GRANTS,
          edittableAfterOpen: false,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    RESP_ADULT: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          required: true,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: false,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'gender' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
          ],
        },
        {
          type: SectionType.RESP_GRANTS,
          edittableAfterOpen: false,
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    USA_BROKERAGE: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'primaryEmail' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    USA_IRA: {
      sections: [
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'primaryEmail' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    USA_RO_IRA: {
      sections: [
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'primaryEmail' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    USA_RT_IRA: {
      sections: [
        {
          type: SectionType.SOURCE_OF_FUNDS,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          showAllocation: true,
          showRelationship: true,
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            { type: 'primaryEmail' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.CUSTODIAN,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.BASE_CURRENCY,
          edittableAfterOpen: true,
        },
        {
          type: SectionType.MULTI_CURRENCY,
          edittableAfterOpen: true,
        },
      ],
      fieldOptions: {
        sourceOfFunds: { required: true },
        custodianConnection: { required: true },
        baseCurrency: { required: true },
      },
    },
    USA_ESA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.PRIMARY_BENEFICIARY,
          multi: false,
          showAllocation: false,
          showRelationship: true,
          edittableAfterOpen: true,
          useAccountHoldersAddress: true,
          required: true,
          fields: [
            { type: 'fullName' },
            { type: 'physicalAddress' },
            { type: 'dateOfBirth' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
              ],
            },
          ],
        },
        {
          type: SectionType.LINK_TO_HOUSEHOLD,
          edittableAfterOpen: true,
        },
      ],
    },
    USA_INH_IRA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.DECEDENT,
          multi: false,
          showAllocation: false,
          showRelationship: true,
          edittableAfterOpen: false,
          required: true,
          fields: [
            { type: 'fullName' },
            { type: 'dateOfBirth' },
            { type: 'dateOfDeath' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
      ],
    },
    USA_INH_RT_IRA: {
      sections: [
        {
          type: SectionType.AFFILIATE,
          affiliateType: AffiliateType.DECEDENT,
          multi: false,
          showAllocation: false,
          showRelationship: true,
          edittableAfterOpen: false,
          required: true,
          fields: [
            { type: 'fullName' },
            { type: 'dateOfBirth' },
            { type: 'dateOfDeath' },
            { type: 'taxId' },
            {
              type: 'relation',
              options: [
                { label: t('affiliationRelationship.CHILD'), value: RelationType.CHILD },
                { label: t('affiliationRelationship.COMMON_LAW'), value: RelationType.COMMON_LAW },
                { label: t('affiliationRelationship.GRANDCHILD'), value: RelationType.GRANDCHILD },
                { label: t('affiliationRelationship.GRANDPARENT'), value: RelationType.GRANDPARENT },
                { label: t('affiliationRelationship.GUARDIAN'), value: RelationType.GUARDIAN },
                { label: t('affiliationRelationship.NEPHEW_NIECE'), value: RelationType.NEPHEW_NIECE },
                { label: t('affiliationRelationship.OTHER'), value: RelationType.OTHER },
                { label: t('affiliationRelationship.PARENT'), value: RelationType.PARENT },
                { label: t('affiliationRelationship.PRIMARY_CAREGIVER'), value: RelationType.PRIMARY_CAREGIVER },
                { label: t('affiliationRelationship.SIBLING'), value: RelationType.SIBLING },
                { label: t('affiliationRelationship.SPOUSE'), value: RelationType.SPOUSE },
              ],
            },
          ],
        },
      ],
    },
  });
};
