import {
  Box,
  IconButton, ListItem, ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Delete } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_LOGO } from '../../../components/layout/organizationLogo';
import { InlineImage } from '../../client/components/documents/inlineImage';
import SelectableFileInput from '../../client/components/selectableFileInput';
import { DELETE_FILE_DOCUMENT } from '../../client/components/documents/documentViewer';
import ConfirmationModal from '../../../components/modals/confirmationModal';

const EditOrganizationLogo = ({ title, organizationId, onFileChosen }: { title: string, organizationId: string, onFileChosen: (file: File | undefined) => void }) => {
  const { t } = useTranslation(['client', 'orgSettings']);
  const [documents, setDocuments] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { loading } = useQuery(FETCH_LOGO, {
    variables: {
      filter:
      {
        organizationId,
        types: ['LOGO'],
        objectTypes: ['ORGANIZATION'],
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setDocuments(data.fetchFileDocuments.fileDocuments);
    },
  });

  const [deleteFileDocument] = useMutation(DELETE_FILE_DOCUMENT, {
    variables: {
      fileDocumentId: documents[0]?.id ?? '',
    },
    refetchQueries: [FETCH_LOGO],
    onCompleted() {
      setOpenModal(false);
    },
  });

  const textStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    m: '10px',
  };

  if (loading) return <></>;

  return (
    <>
      {documents.length > 0
        ? (<ListItem >
          <Box sx={{ maxHeight: '50px', maxWidth: '50px' }}>
            <InlineImage fileDocumentId={documents[0].id} />
          </Box>
          <ListItemText sx={textStyle} primary={documents[0].name} />
          <IconButton sx={{ color: '#1D1D1D' }} onClick={() => {
            setOpenModal(true);
          }}>
            <Delete />
          </IconButton>
        </ListItem>)
        : <SelectableFileInput
          title={title ?? t('droppableFileInput.chooseAFile')}
          onFileChosen={(dropFile: File | undefined) => {
            onFileChosen(dropFile);
          }}
        />
      }
      <ConfirmationModal
        title={t('orgSettings:deleteLogoModal.title')}
        bodyText={t('orgSettings:deleteLogoModal.subtitle')}
        open={openModal}
        maxWidth='sm'
        confirmButton={t('orgSettings:deleteLogoModal.yesDelete')}
        cancelButton={t('orgSettings:deleteLogoModal.cancel')}
        onConfirm={() => deleteFileDocument()}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default EditOrganizationLogo;
