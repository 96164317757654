import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import NumberFormat from 'react-number-format';
import React from 'react';
import { formatAmountValueCents } from '../../util';

interface FormatterProps {
  onChange: (event: { target: { valueCents: undefined | number; name: string; value: string } }) => void;
  name: string;
}

type AmountFieldProps = TextFieldProps & { useFormatAmountValueCents?: boolean };

const CurrencyFormatter = React.forwardRef<NumberFormat<string>, FormatterProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          if (Number.isNaN(Number(values.value))) {
            return;
          }
          let formattedValue: string;
          if (values.value) {
            formattedValue = ((Number(values.value) * 100) % 1 === 0) ? values.value : Number(values.value).toFixed(2);
            formattedValue = Math.abs(Number(formattedValue)).toString();
          } else {
            formattedValue = '';
          }
          onChange({
            target: {
              name: props.name,
              value: formattedValue,
              valueCents: formattedValue === '' ? undefined : Math.round(Number(formattedValue) * 100),
            },
          });
        }}
        fixedDecimalScale
        thousandSeparator
        isNumericString
        defaultValue={0}
      />
    );
  },
);

const AmountField = ({ useFormatAmountValueCents, ...props }: AmountFieldProps) => {
  const parseValue = (value: unknown): string => {
    if (value === undefined || value === '') {
      return '';
    }

    return useFormatAmountValueCents ? formatAmountValueCents(Number(value)) : (Number(value) / 100).toString();
  };

  return (
    <TextField
      {...props}
      placeholder={props.placeholder ?? '0'}
      InputProps={{
        ...props.InputProps,
        inputComponent: CurrencyFormatter as any,
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }}
      value={parseValue(props.value)}
    />
  );
};

export default AmountField;
