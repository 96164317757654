/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '../../../1-primative';
import {
  Card,
  Pagination,
  Table,
  TableBody, TableCell,
  TableHeadCell,
  TableRow,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { SceduleTransferCell } from './scheduleTransferCell';
import { useClientContext } from '../../../../pages/client';
import { ScheduleTransferState } from '../../../../interfaces';

export const FETCH_SCHEDULED_TRANSFERS_QUERY = (permissions: string[]) => gql`
query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
  fetchScheduledTransfers(input: $input) {
    scheduledTransfers {
      id
      amountCents
      state
      type
      subAccount {
        id
        account {
          id
          type
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
          }
        }
        goal {
          name
        }
      }
      toSubAccount {
        id
        goal {
          name
        }
        account {
          type
        }
      }
      bankAccount {
        name
        institutionNumber
        bankAccountNumber
      }
      scheduledDate
      frequency
    }
    totalCount
  }
}
`;

const DEFAULT_TABLE = [
  {
    label: {
      en: 'Account',
      fr: 'Compte',
    },
    type: 'account',
  },
  {
    label: {
      en: 'Type',
      fr: 'Type',
    },
    type: 'type',
  },
  {
    label: {
      en: 'Source',
      fr: 'Source',
    },
    type: 'source',
  },
  {
    label: {
      en: 'Amount',
      fr: 'Amount',
    },
    type: 'amount',
  },
  {
    label: {
      en: 'Frequency',
      fr: 'Fréquence',
    },
    type: 'frequency',
  },
  {
    label: {
      en: 'State',
      fr: 'State',
    },
    type: 'state',
  },
  {
    label: {
      en: 'Next Scheduled Date',
      fr: 'Date suivante programmée',
    },
    type: 'nextScheduledDate',
  },
];

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalId: objectId };
    case PageObjectType.ACCOUNT:
      return { accountId: objectId };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountId: objectId };
    default:
      return {};
  }
};

export const ScheduleTransfers = ({ objectId, objectType, options }: { objectId: string; objectType: PageObjectType; options: any }) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes', 'client']);
  const [page, setPage] = useState<any>(1);
  const { permissions } = usePermissions();
  const [pagination, setPagination] = useState<any>({
    offSet: 0,
    perPage: 15,
  });
  const { activeOrganization } = useContext(UserContext);
  const [scheduleTransfers, setScheduleTransfers] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const clientContext = useClientContext();

  const [fetchScheduleTransfer, { loading }] = useLazyQuery(FETCH_SCHEDULED_TRANSFERS_QUERY(permissions), {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setScheduleTransfers(data?.fetchScheduledTransfers?.scheduledTransfers);
      setTotalCount(Number(data.fetchScheduledTransfers.totalCount || 0));
    },
  });

  const organizationId = clientContext?.orgSettings?.id ?? activeOrganization.id;

  const queryFilter = {
    ...baseFilter(objectType, objectId),
    organizationId: organizationId ?? activeOrganization.id,
    states: [ScheduleTransferState.Active, ScheduleTransferState.Draft],
  };

  useEffect(() => {
    const variables = {
      input: {
        filter: queryFilter,
      },
    };
    fetchScheduleTransfer({ variables });
  }, [objectType, objectId, pagination]);

  useEffect(() => {
    setPagination({ offSet: (page - 1) * pagination.perPage, perPage: pagination.perPage });
  }, [page]);

  return (
    <>
      <Typography minHeight={40} display='flex' alignItems='center' variant='headingSmall'>
        {options.customTitle ? translateBackend(options.customTitle) : ''}
      </Typography>
      <Card sx={{ overflowX: 'auto', mt: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              {(options.table || DEFAULT_TABLE).map((x: any) => (
                <TableHeadCell key={x} right={['amount'].includes(x.type)}>
                  {translateBackend(x.label)}
                </TableHeadCell>
              ))}
            </TableRow>
            {loading ? (
              <>
                {[...Array(15)].map((x, i) => (
                  <TableRow key={i}>
                    {(options.table || DEFAULT_TABLE).map(() => (
                      <TableCell dense>
                        <Skeleton width='100%' height='16px' />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {scheduleTransfers.map((scheduleTransfer: any) => (
                  <TableRow key={scheduleTransfer.id} hover>
                    {(options.table || DEFAULT_TABLE).map((x: any) => (
                      <SceduleTransferCell key={x.key} scheduleTransfer={scheduleTransfer} type={x.type} />
                    ))}
                  </TableRow>
                ))}
              </>
            )}
            {scheduleTransfers.length === 0 && loading && (
              <TableRow>
                <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>
                  {t('components:activityTable.noTransfersExist')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box display='flex' justifyContent='flex-end' sx={{ p: 1 }}>
          <Pagination size='small' page={page} count={Math.ceil(totalCount / pagination.perPage)} onChange={(e, newPage) => setPage(newPage)} />
        </Box>
      </Card>
    </>
  );
};
