/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { flatten, uniq } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useMutation } from '@apollo/client';
import remarkGfm from 'remark-gfm';
import {
  FormControlLabel, MenuItem, Chip as MuiChip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { translateBackend } from 'assets/i18n/config';
import { UserContext } from 'providers/userContextProvider';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import {
  Button, Typography, Form, Box, Checkbox, Grid, Chip, CircularProgress, Autocomplete, SelectField,
  Card,
  CardContent,
} from '../../../..';
import { Affiliate, AffiliateType } from './components/affiliate';
import { SectionType, AccountConfig } from './accountConfig';
import { PaymentInstruction } from './components/paymentInstruction';
import { FieldOptions, FormErrors, validateFields } from '../utils';
import { HouseholdSelection, ObjectTypes } from '../editGoal/components/householdSelection';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { getCurrencyFlag, hasCurrencyFlag, isCurrencyCode } from '../../../../../util/currency';
import { CustodianConnection, CustodianConnectionAccountTypeSettings } from '../../../../../interfaces/custodianConnection';
import { RepCodeSelect } from '../../../../3-pattern/repCodeSelect/repCodeSelect';
import { CustomFieldRenderer } from '../../../../3-pattern/customField/customFieldRenderer';
import { UPDATE_ACCOUNT } from './editAccount';

export const EditAccountVisual = ({
  options,
  accountData,
  loading,
  loadingData,
  updateAccount,
  continueFunc,
  grid = false,
  refetch,
  skip,
  updateMode = false,
  userId,
  getCurrentJurisdictionOption,
  getJurisdictionOptions,
  getSourceOfFundsOptions,
  activeCustomFields,
  custodianConnections,
  workflowCompletion,
  skipEditAccount,
}: {
  options: any;
  accountData: any;
  loading: boolean;
  loadingData: boolean;
  updateAccount: any;
  continueFunc: any;
  grid?: boolean;
  refetch?: any;
  skip: () => void;
  updateMode?: boolean;
  userId?: string;
  filterApplicableProvinces?: any;
  getCurrentJurisdictionOption?: any;
  getJurisdictionOptions?: any;
  getSourceOfFundsOptions?: any;
  activeCustomFields?: string[];
  custodianConnections?: any[];
  workflowCompletion?: any;
  skipEditAccount?: boolean;
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [skipped, setSkipped] = useState(false);
  const [edited, setEdited] = useState(false);
  const [errors, setErrors] = useState<FormErrors>(null);
  const [focused, setFocused] = useState<string[]>([]);
  const { t } = useTranslation(['client', 'accountsDetail']);
  const accountConfig = AccountConfig();
  const { activeOrganization, custodianConnection: defaultCustodianConnection } = useContext(UserContext);
  const enableHousehold: boolean = activeOrganization.availableFeatureFlags?.includes('HOUSEHOLD') ?? false;
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);
  const [requireFieldValidated, setErrorsuireFieldValidated] = useState(false);

  const [selectedCustodianConnection, setSelectedCustodianConnection] = useState<CustodianConnection | null>(null);
  const [selectedAccountTypeSettings, setSelectedAccountTypeSettings] = useState<CustodianConnectionAccountTypeSettings | null>(null);
  const [isMultiCurrencyAccount, setIsMultiCurrencyAccount] = useState<boolean>(false);

  const fieldOptions: FieldOptions = useMemo(() => accountConfig[accountData.type]?.fieldOptions || {}, [accountConfig]);

  const validate = useCallback(
    (candidateFields?: string[]): FormErrors => {
      const newErrors = validateFields(fieldOptions, accountData, candidateFields);
      setErrors(newErrors);
      return newErrors;
    },
    [accountData, fieldOptions],
  );

  const [updateAccountData] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  useEffect(() => {
    if (loadingData) {
      return;
    }

    const custodianConnectionToSelect = accountData.custodianConnection ?? defaultCustodianConnection;

    const custodianConnectionInList = custodianConnections?.find((custodianConnection) => custodianConnection.id === custodianConnectionToSelect.id);

    if (custodianConnectionInList) {
      setSelectedCustodianConnection(custodianConnectionInList);

      return;
    }

    custodianConnections?.push(custodianConnectionToSelect);
    setSelectedCustodianConnection(custodianConnectionToSelect);
  }, [defaultCustodianConnection, custodianConnections, loadingData]);

  useEffect(() => {
    if (loadingData || !selectedCustodianConnection) {
      return;
    }

    const accountTypeSettings = selectedCustodianConnection.accountTypeSettings?.find((settings: any) => settings.type === accountData.type);

    if (!accountTypeSettings) {
      const defaultAccountTypeSettings = {
        baseCurrency: CurrencyCodes.CAD,
        availableCurrencies: [CurrencyCodes.CAD],
        isMultiCurrencyEnabled: false,
      } as CustodianConnectionAccountTypeSettings;

      setSelectedAccountTypeSettings(defaultAccountTypeSettings);

      return;
    }

    setSelectedAccountTypeSettings(accountTypeSettings);
  }, [selectedCustodianConnection, accountData.type]);

  useEffect(() => {
    if (accountData.availableCurrencies?.length > 1) {
      setIsMultiCurrencyAccount(true);
    }
  }, [accountData.availableCurrencies]);

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    if (options.validateAffiliate && validateRequiredAffiliateFields()) {
      setErrorsuireFieldValidated(true);
      return;
    }
    setEdited(false);
    sendAnalytic(
      ovAnalyticsEvents.workflowsEditAccountContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    continueFunc();
  };

  const sections = (): any[] => {
    let secs = (accountConfig[accountData.type]?.sections || [])
      .filter((x: any) => !(x.excludeProvinces || []).includes(accountData.user.physicalAddress?.province))
      .filter((y: any) => !(y.excludeMaritalStatus || []).includes(accountData.user.maritalStatus));

    if (updateMode) {
      if (!options.affiliates) {
        secs = secs.filter((x: any) => x.type !== SectionType.AFFILIATE);
      }
      if (!options.respGrants) {
        secs = secs.filter((x: any) => x.type !== SectionType.RESP_GRANTS);
      }
      if (!options.rrifPaymentInstructions) {
        secs = secs.filter((x: any) => x.type !== SectionType.RRIF_PAYMENT_INSTRUCTIONS);
      }
    }
    if (!options.defaultLinkToHousehold || !enableHousehold) {
      secs = secs.filter((x: any) => x.type !== SectionType.LINK_TO_HOUSEHOLD);
    }
    if (!options.custodianSelector || !custodianConnections || custodianConnections.length === 1) {
      secs = secs.filter((x: any) => x.type !== SectionType.CUSTODIAN);
    }
    if (!options.baseCurrencySelector || !selectedAccountTypeSettings?.availableCurrencies || selectedAccountTypeSettings?.availableCurrencies.length === 1) {
      secs = secs.filter((x: any) => x.type !== SectionType.BASE_CURRENCY);
    }
    if (!options.multiCurrencySelector || !selectedAccountTypeSettings?.isMultiCurrencyEnabled) {
      secs = secs.filter((x: any) => x.type !== SectionType.MULTI_CURRENCY);
    }
    if (options.jurisdiction === false) secs = secs.filter((x: any) => x.type !== SectionType.JURISDICTION);
    if (options.sourceOfFunds === false) secs = secs.filter((x: any) => x.type !== SectionType.SOURCE_OF_FUNDS);

    const jurisdictionAndSourceOfFunds = secs.filter((x: any) => [SectionType.JURISDICTION, SectionType.SOURCE_OF_FUNDS].includes(x.type));

    if (jurisdictionAndSourceOfFunds.length > 1) {
      const otherSecs = secs.filter((x: any) => ![SectionType.JURISDICTION, SectionType.SOURCE_OF_FUNDS].includes(x.type));
      return [otherSecs, jurisdictionAndSourceOfFunds];
    }
    return [secs];
  };

  const validateRequiredAffiliateFields = () => {
    const errofields: any[] = [];
    const renderedSections = sections();
    renderedSections
      .filter((section: any) => section.type === SectionType.AFFILIATE && section.required)
      .map((sec: any) => {
        if (!isAffiliateAddedToTheAccount(sec)) {
          errofields.push(sec);
        }
      });
    return errofields.length !== 0;
  };

  const isAffiliateAddedToTheAccount = (section: any) => {
    let found = false;

    const affiliationData = options.useCustodianAffiliates ? accountData.custodianAffiliates : accountData.affiliations;
    affiliationData?.forEach((d: any) => {
      if (
        section.affiliateType === AffiliateType.OTHER
        && (section?.defaultRelationType ? d.relation === section.defaultRelationType && d.type === section.affiliateType : d.type === section.affiliateType)
      ) {
        found = true;
      } else if (d.type === section.affiliateType && section.affiliateType !== AffiliateType.OTHER) {
        found = true;
      }
    });
    return found;
  };

  const validateRequiredAffiliate = (section: any) => {
    if (section.type === SectionType.AFFILIATE && section.required) {
      return !isAffiliateAddedToTheAccount(section);
    }
    return false;
  };

  const affiliateData = () => {
    if (options.useCustodianAffiliates) {
      return accountData.custodianAffiliates;
    }
    return accountData.affiliations;
  };

  useEffect(() => {
    const shouldSkipEditAccount = !loadingData && accountData?.accountId && selectedCustodianConnection && selectedAccountTypeSettings && sections().length === 0;
    if (!skipped && (shouldSkipEditAccount || skipEditAccount)) {
      setSkipped(true);
      skip();
    }
  }, [loadingData, accountData, accountConfig, selectedCustodianConnection, selectedAccountTypeSettings, skipped, skip]);

  const renderSection = (section: any) => {
    switch (section.type) {
      case SectionType.AFFILIATE: {
        // in a case where a user can not edit after creating (edittableAfterOpen == false)
        // this allows them to create if they haven't created before.
        const hasNoAffiliates: boolean = !section.edittableAfterOpen && affiliateData().filter((item: { type: string; }) => item.type === section.affiliateType).length === 0;
        return (
            <Affiliate
                data-testid='affiliates'
                type={section.affiliateType}
                account={accountData}
                affiliates={affiliateData()?.filter((x: any) => x.type === section.affiliateType && (section.defaultRelationType ? x.relation === section.defaultRelationType : true)) || []}
                allAffiliates={affiliateData()}
                updateAccount={updateAccount}
                multi={section.multi}
                showAllocation={section.showAllocation && !options.hideAllocation}
                showRelationship={section.showRelationship && !options.hideRelationship}
                showMissingAffiliate={!options.useCustodianAffiliates}
                fields={section.fields}
                defaultRelationType={section.defaultRelationType}
                refetch={refetch}
                updateMode={updateMode}
                edittable={hasNoAffiliates || (section.edittableAfterOpen && updateMode && options.editableAffiliates) || !updateMode}
                onEdit={() => setEdited(true)}
                title={section.title}
                useAccountHoldersAddress={section.useAccountHoldersAddress}
                error={requireFieldValidated && validateRequiredAffiliate(section)}
                supportText={translateBackend(options.customSupportMessage)}
                errorText={t('shared:requireAffiliate', {
                  accountType: t(`accountTypes:${accountData.type}`),
                  affiliateType: t(`affiliationTypes:${section.affiliateType === 'OTHER' && section.defaultRelationType ? section.defaultRelationType : section.affiliateType}`),
                })}
            />
        );
      }
      case SectionType.RESP_GRANTS:
        return (
          <>
            <Typography variant='labelLarge' sx={{ mt: grid ? 0 : 3 }} weight='bold'>
              {t('accountsDetail:govGrants')}
            </Typography>
            {updateMode ? (
              <Box sx={{ mt: 1 }}>
                {accountData.applyForGovFunds.includes('BASIC_CESG') && <Chip data-testid='gov-fund-basic-cesg' text={t('accountsDetail:govFundOptions.BASIC_CESG')} />}
                {accountData.applyForGovFunds.includes('ADDITIONAL_CESG') && <Chip data-testid='gov-fund-additional-cesg' text={t('accountsDetail:govFundOptions.ADDITIONAL_CESG')} />}
                {accountData.applyForGovFunds.includes('CLB') && <Chip data-testid='gov-fund-clb' text={t('accountsDetail:govFundOptions.CLB')} />}
              </Box>
            ) : (
              <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
                <Checkbox
                  data-testid='gov-fund-basic-cesg'
                  chip
                  label={t('accountsDetail:govFundOptions.BASIC_CESG')}
                  checked={accountData.applyForGovFunds.includes('BASIC_CESG')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData,
                    applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'BASIC_CESG'] : accountData.applyForGovFunds.filter((x: string) => x !== 'BASIC_CESG'),
                  })
                  }
                />
                <Checkbox
                  data-testid='gov-fund-additional-cesg'
                  chip
                  label={t('accountsDetail:govFundOptions.ADDITIONAL_CESG')}
                  checked={accountData.applyForGovFunds.includes('ADDITIONAL_CESG')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData,
                    applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'ADDITIONAL_CESG'] : accountData.applyForGovFunds.filter((x: string) => x !== 'ADDITIONAL_CESG'),
                  })
                  }
                />
                <Checkbox
                  data-testid='gov-fund-clb'
                  chip
                  label={t('accountsDetail:govFundOptions.CLB')}
                  checked={accountData.applyForGovFunds.includes('CLB')}
                  onChange={(checked: boolean) => updateAccount({
                    ...accountData,
                    applyForGovFunds: checked ? [...accountData.applyForGovFunds, 'CLB'] : accountData.applyForGovFunds.filter((x: string) => x !== 'CLB'),
                  })
                  }
                />
              </Box>
            )}
          </>
        );
      case SectionType.RRIF_PAYMENT_INSTRUCTIONS:
        return <PaymentInstruction data-testid='rif-payment-instruction' userId={userId ?? ''} account={accountData} refetch={refetch} updateMode={updateMode} fundTitle={section.titlePrefix} />;
      case SectionType.LINK_TO_HOUSEHOLD:
        return <HouseholdSelection data-testid='house-hold-selection' data={accountData} updateData={updateAccount} objectType={ObjectTypes.ACCOUNT} />;
      case SectionType.JURISDICTION:
        return (
          <Box sx={{ mt: 2 }}>
            <SelectField
              data-testid='jurisdiction'
              fullWidth
              label={t('workflowCompletions:updateAccount.jurisdiction')}
              value={getCurrentJurisdictionOption(accountData.jurisdiction ?? '')}
              onChange={(e: any) => {
                updateAccountData({
                  variables: {
                    input: {
                      accountId: accountData.accountId,
                      jurisdiction: e.target.value,
                    },
                  },
                });
              }}
            >
              {getJurisdictionOptions().map((j: any) => (
                <MenuItem key={j.id} value={j.id}>
                  {j.label}
                </MenuItem>
              ))}
            </SelectField>
          </Box>
        );
      case SectionType.SOURCE_OF_FUNDS:
        return (
          <Box sx={{ mt: 2, mb: 2 }}>
            <SelectField
              data-testid='source-of-funds'
              fullWidth
              label={t('workflowCompletions:updateAccount.sourceOfFunds')}
              value={accountData.sourceOfFunds ?? undefined}
              onChange={(e: any) => {
                updateAccountData({
                  variables: {
                    input: {
                      accountId: accountData.accountId,
                      sourceOfFunds: e.target.value,
                    },
                  },
                });
              }}
            >
              {getSourceOfFundsOptions().map((j: any) => (
                <MenuItem key={j.id} value={j.id}>
                  {j.label}
                </MenuItem>
              ))}
            </SelectField>
          </Box>
        );
      case SectionType.CUSTODIAN:
        return (
          <Grid item xs={12} spacing={2} md={12} sx={{ mt: 2 }}>
            <SelectField
              fullWidth
              value={selectedCustodianConnection ?? ''}
              label={t('accountsDetail:custodianConnectionSelector')}
              onChange={(event: any) => {
                const selectedCustodian = event.target.value;
                const accountTypeSettings = selectedCustodian?.accountTypeSettings?.find((settings: any) => settings.type === accountData.type);

                setSelectedCustodianConnection(selectedCustodian);
                setIsMultiCurrencyAccount(false);
                updateAccount({
                  ...accountData,
                  custodianConnection: selectedCustodian,
                  baseCurrency: accountTypeSettings?.baseCurrency || '',
                  availableCurrencies: accountTypeSettings?.baseCurrency ? [accountTypeSettings?.baseCurrency] : [],
                });
              }}
              onBlur={() => setFocused([...focused, 'custodianConnection'])}
              errorText={errors?.custodianConnection?.message}
              error={!!errors?.custodianConnection}
              data-testid='custodianConnectionInput'
            >
              {custodianConnections?.map((custodian) => (
                <MenuItem key={custodian.id} value={custodian}>
                  {custodian.name} {custodian.default ? '(Default)' : ''}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        );
      case SectionType.BASE_CURRENCY:
        return (
          <Grid item xs={12} spacing={2} md={12} sx={{ mt: 2 }}>
            <SelectField
              fullWidth
              value={accountData.baseCurrency}
              label={t('accountsDetail:baseCurrencySelector')}
              onChange={(event: any) => {
                const availableCurrenciesWithoutPreviousBaseCurrency = accountData.availableCurrencies?.filter((currency: any) => currency !== accountData.baseCurrency) || [];

                updateAccount({
                  ...accountData,
                  baseCurrency: event.target.value,
                  availableCurrencies: Array.from(new Set([...availableCurrenciesWithoutPreviousBaseCurrency, event.target.value])),
                });
              }}
              onBlur={() => setFocused([...focused, 'baseCurrency'])}
              errorText={errors?.baseCurrency?.message}
              error={!!errors?.baseCurrency}
              data-testid='baseCurrencyInput'
            >
              {(selectedAccountTypeSettings?.availableCurrencies || [accountData.baseCurrency]).map((currency: CurrencyCodes) => {
                if (!isCurrencyCode(currency)) {
                  return <></>;
                }

                return (
                  <MenuItem key={currency} value={currency}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {currency && hasCurrencyFlag(currency) && <img src={getCurrencyFlag(currency)} alt={currency} style={{ width: '20px', height: '20px', marginRight: '12px' }} />}
                      <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>
                        {currency} {selectedAccountTypeSettings?.baseCurrency === currency ? '(Default)' : ''}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
            </SelectField>
          </Grid>
        );
      case SectionType.MULTI_CURRENCY:
        return (
          <>
            <Grid item xs={12} md={12} spacing={4} sx={{ mt: 2 }}>
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                  setIsMultiCurrencyAccount(!isMultiCurrencyAccount);

                  // We want to clear the available currencies when the checkbox is false
                  // The isMultiCurrencyAccount variable is not updated right away, so
                  // If the value is true here, it means that the user has clicked to uncheck it
                  // Thus, we clear the available currencies
                  if (isMultiCurrencyAccount) {
                    updateAccount({
                      ...accountData,
                      availableCurrencies: [accountData.baseCurrency],
                    });
                  }
                }}
                control={
                  <Checkbox
                    data-testid='toggleMultiCurrencyAccountInput'
                    label={t('accountsDetail:multiCurrencySelector.toggleMultiCurrency')}
                    infoTooltip={t('accountsDetail:multiCurrencySelector.toggleMultiCurrencyInfoTooltip')}
                    customStyle={{ paddingLeft: '12px' }}
                    checkBoxStyle={{ 'svg.MuiSvgIcon-root': { fontSize: '20px !important' } }}
                    disabled={loading}
                    labelVariant={'bodyMedium'}
                    centerInfoToolTip
                    checked={isMultiCurrencyAccount}
                    onChange={(checked: boolean) => {
                      setIsMultiCurrencyAccount(checked);
                    }}
                  />
                }
                label={''}
              />
            </Grid>
            {isMultiCurrencyAccount && (
              <Grid item xs={12} md={12} spacing={2} sx={{ mt: 1 }}>
                <Autocomplete
                  multiple
                  value={accountData.availableCurrencies?.filter((currency: CurrencyCodes) => currency !== accountData.baseCurrency) || []}
                  options={selectedAccountTypeSettings?.availableCurrencies?.filter((currency: CurrencyCodes) => currency !== accountData.baseCurrency) || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  data-testid='availableCurrenciesInput'
                  onBlur={() => setFocused([...focused, 'availableCurrencies'])}
                  label={''}
                  helpText={t('accountsDetail:multiCurrencySelector.availableCurrenciesHelperText')}
                  errorText={errors?.availableCurrencies?.message}
                  error={!!errors?.availableCurrencies}
                  fullWidth
                  renderOption={(props: any, option: any, { selected }: any) => {
                    const { key, ...optionProps } = props;

                    return (
                      <Box
                        key={key}
                        {...optionProps}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '& *MuiCheckbox-root svg.MuiSvgIcon-root': { fontSize: '20px' },
                        }}
                      >
                        <Checkbox size='medium' customStyle={{ marginRight: 1.5 }} checkBoxStyle={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={selected} />
                        {option && hasCurrencyFlag(option) && <img src={getCurrencyFlag(option as CurrencyCodes)} alt={option} style={{ width: '20px', height: '20px', marginRight: '12px' }} />}
                        <Typography variant='bodyLarge' sx={{ fontWeight: 500 }}>
                          {option}
                        </Typography>
                      </Box>
                    );
                  }}
                  renderTags={(tagValue: any, getTagProps: any) => tagValue.map((option: any, index: number) => (
                      <MuiChip
                        {...getTagProps({ index })}
                        label={<Typography variant='labelLarge'>{option}</Typography>}
                        size='medium'
                        deleteIcon={<CloseIcon></CloseIcon>}
                        sx={{
                          '& .MuiChip-deleteIcon': {
                            fontSize: '20px',
                            color: '#17181C',
                          },
                        }}
                        onDelete={() => {
                          const availableCurrenciesWithoutDeletedOption = accountData.availableCurrencies.filter((currency: any) => currency !== option);
                          updateAccount({ ...accountData, availableCurrencies: Array.from(new Set([...availableCurrenciesWithoutDeletedOption, accountData.baseCurrency])) });
                        }}
                      />
                  ))
                  }
                  onChange={(e: React.SyntheticEvent, changedCurrencies: string[]) => {
                    updateAccount({ ...accountData, availableCurrencies: Array.from(new Set([...changedCurrencies, accountData.baseCurrency])) });
                  }}
                ></Autocomplete>
              </Grid>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Form onSubmit={submit}>
      {loadingData ? (
        <>
          <Box display='flex' justifyContent='center' alignItems='center' height='200px'>
            <CircularProgress />
          </Box>
        </>
      ) : sections().length === 0 ? (
        <Card>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant='bodyLarge'>{t('accountsDetail:noAffiliates')}</Typography>
          </CardContent>
        </Card>
      ) : (
        <>
          {options?.title && (
            <Typography variant='displayLarge' sx={{ mt: 1 }}>
              {translateBackend(options?.title)}
            </Typography>
          )}
          {options?.subtitle && (
            <Typography variant='bodyLarge' sx={{ mb: 3 }}>
              <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
                {translateBackend(options?.subtitle)}
              </ReactMarkdown>
            </Typography>
          )}

          <Grid container>
            {sections().map((sec :any[], index: number) => (
              <Grid container columnSpacing={2} key={`${sec[0]?.type}-group-${index}`}>
                {sec.map((section: any, idx: number) => (
                  <Grid key={`${section.type}-${idx}`} item xs={12} md={grid && (sections().length > 1 || sections()[0].length > 1) ? 6 : 12}>
                    {section && section?.type && renderSection(section)}
                  </Grid>
                ))}
              </Grid>
            ))}
            { options?.repCode?.enabled && (
              <Grid item xs={12} spacing={2} mt={2}>
                { options?.showOnlyLinkedAdvisorRepCodes ? (
                  <SelectField
                    value={accountData.repCode?.code ?? ''}
                    onChange={(e: any) => updateAccount({ ...accountData, repCode: { code: e.target.value } })}
                    fullWidth
                    label={translateBackend(options?.repCode?.label)}
                  >
                    {
                      uniq(flatten(accountData?.user?.accessibleBy?.filter((x: any) => x.relation === 'ADVISOR').map((x: any) => x.organizationUser.repCodes))).map((rep: any) => (
                        <MenuItem value={rep?.code}>{`${rep?.code} ${rep?.name && `- ${rep.name}`}`}</MenuItem>
                      ))
                    }
                  </SelectField>
                ) : (
                  <RepCodeSelect
                    setRepCode={(e: any) => updateAccount({ ...accountData, repCode: { code: e } })}
                    value={accountData.repCode?.code ?? ''}
                    label={translateBackend(options?.repCode?.label)}
                  />
                )}
              </Grid>
            )}
            {customOptionSelected && customOptionSelected.length > 0 && (
              <Grid item xs={12} md={12} spacing={2} key='custom'>
                <CustomFieldRenderer customOptions={customOptionSelected} customData={accountData} update={updateAccount} grid={grid} focused={focused} setFocused={setFocused} loading={loading} />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {(!updateMode || edited) && (
        <Box display='flex' justifyContent='end'>
          <Button data-testid='continue-btn' label={updateMode ? t('update') : t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
        </Box>
      )}
    </Form>
  );
};

export default EditAccountVisual;
