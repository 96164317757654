import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination,
  CardContent, TableHeadCell,
  Card,
} from '../../2-component';
import { usePageState } from '../../../util/usePageState';
import {
  BulkUpsertItem, BulkUpsertRequest,
  BulkUpsertRequestStates, getExternalId, mapBulkUpsertItemGqlType,
} from '../../../interfaces/bulkUpsertRequest';
import { FETCH_BULK_UPSERT_REQUEST } from './components/bulkUpsertItemsBreadcrumb';
import { BulkUpsertItemFilter, FilterBulkUpsertItems } from './components/filterBulkUpsertItems';
import UpsertItemModal from './components/upsertItemModal';

const FETCH_BULK_UPSERT_ITEMS = (gqlType?: string) => gql`
  query fetch${gqlType}BulkUpsertItems($input: Fetch${gqlType}BulkUpsertItemsInput!) {
    fetch${gqlType}BulkUpsertItems(input: $input) {
      bulkUpsertItems {
        id
        batchId
        bulkUpsertIntegration
        integrations { externalData externalId integrationId }
        oneVestId
        state
        outputErrors { code field description }
        createdAt
        state
        rawData
      }
      totalCount
    }
  }
`;

export const BulkUpsertItems = () => {
  const { t } = useTranslation(['devSettings']);
  const params = useParams();
  const { bulkUpsertRequestId } = params;
  const [page, setPage] = usePageState(1, 'page');
  const [state, setState] = usePageState<BulkUpsertRequestStates | undefined>(undefined, 'st');
  const [oneVestId, setOneVestId] = usePageState<string | undefined>(undefined, 'ov');
  const [externalId, setExternalId] = usePageState<string | undefined>(undefined, 'iid');
  const [batchId, setBatchId] = usePageState<string | undefined>(undefined, 'bid');
  const [pageSize, setPageSize] = usePageState(15, 'ps');
  const [activeItem, setActiveItem] = useState<BulkUpsertItem>();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    data: requestData, loading: requestLoading,
  } = useQuery(FETCH_BULK_UPSERT_REQUEST, {
    variables: {
      bulkUpsertRequestId,
    },
  });

  const bulkUpsertRequest: BulkUpsertRequest | undefined = requestData?.fetchBulkUpsertRequest?.bulkUpsertRequest;
  const gqlType = mapBulkUpsertItemGqlType(bulkUpsertRequest?.objectType);

  const {
    data: itemsData, loading: itemsLoading, previousData: itemsPrevData,
  } = useQuery(FETCH_BULK_UPSERT_ITEMS(gqlType), {
    fetchPolicy: 'no-cache',
    skip: !gqlType,
    variables: {
      input: {
        filter: {
          bulkUpsertRequestId,
          ...(state ? { state } : {}),
          ...(oneVestId ? { oneVestId } : {}),
          ...(externalId ? { externalId } : {}),
          ...(batchId ? { batchId } : {}),
        },
        pagination: {
          sortField: 'createdAt', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  return (
    <Card loading={requestLoading || itemsLoading}>
      <Box>
        <CardContent>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            <Box display='flex' alignItems='center'>
              <Typography>{t('bulkUpsertRequest.items.requestSummaryLine', {
                queued: bulkUpsertRequest?.queuedItemsCount,
                processed: bulkUpsertRequest?.processedItemsCount,
                failed: bulkUpsertRequest?.failedItemsCount,
              })}</Typography>
            </Box>
            <Box display='flex'>
              <FilterBulkUpsertItems
                filter={{
                  state, oneVestId, externalId, batchId,
                }}
                setFilter={(filter: BulkUpsertItemFilter) => {
                  setState(filter.state);
                  setOneVestId(filter.oneVestId);
                  setExternalId(filter.externalId);
                  setBatchId(filter.batchId);
                }}
              />
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableBody>
              <TableRow>
                <TableHeadCell>{t('bulkUpsertRequest.items.table.batchId')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.items.table.oneVestId')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.items.table.externalId')}</TableHeadCell>
                <TableHeadCell>{t('bulkUpsertRequest.items.table.state')}</TableHeadCell>
              </TableRow>
              { itemsLoading && !itemsPrevData && [...Array(15)].map((_x: any, i: number) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                  <TableCell><Skeleton width='100%' /></TableCell>
                </TableRow>
              ))}
              {(itemsData || itemsPrevData)?.[`fetch${gqlType}BulkUpsertItems`]?.bulkUpsertItems?.map((bulkUpsertItem: BulkUpsertItem) => (
                <TableRow
                  hover
                  key={bulkUpsertItem.id}
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => {
                    setActiveItem(bulkUpsertItem);
                    setModalOpen(true);
                  }}
                >
                  <TableCell>{bulkUpsertItem.batchId}</TableCell>
                  <TableCell>{bulkUpsertItem.oneVestId ?? '-'}</TableCell>
                  <TableCell>{getExternalId(bulkUpsertItem)}</TableCell>
                  <TableCell>{t(`bulkUpsertRequest.items.states.${bulkUpsertItem.state}`)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Pagination
          count={Math.ceil(((itemsData || itemsPrevData)?.[`fetch${gqlType}BulkUpsertItems`]?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box >
      {modalOpen && (
        <UpsertItemModal upsertItem={activeItem} handleClose={() => setModalOpen(false)} />
      )}
    </Card>
  );
};

export default BulkUpsertItems;
