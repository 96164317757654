import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  Typography,
} from 'ovComponents/1-primative';
import {
  Autocomplete,
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, Form, IconButton,
} from 'ovComponents/2-component';
import InputAdornment from '@mui/material/InputAdornment';
import { ClientGroup } from 'interfaces/clientGroup';
import { WorkflowContext } from 'ovComponents/4-module/workflowCompletion/workflowCompletion';
import { WorkflowActions } from './householdSelection';

export const HouseholdSelectionModal = ({
  open, setOpen, action = WorkflowActions.CREATE, refetch, relatedHouseholds, data, updateData,
}: {
  open: boolean, setOpen: (open: boolean) => void,
  refetch?: any, action: WorkflowActions, relatedHouseholds: any[],
  data?: any,
  updateData?: any,
}) => {
  const { t } = useTranslation(['householdSelection']);
  const { workflowData, setWorkflowData } = useContext(WorkflowContext);
  const [linkingHouseholdId, setLinkingHouseholdId] = useState(data.householdClientGroupId ?? '');
  const handleHouseholdChange = (e: React.SyntheticEvent, value: any) => {
    if (value && value.id) {
      setLinkingHouseholdId(value.id);
    }
  };

  function getHouseholdOptions(householdItems: ClientGroup[]): Array<{ id?: string; label?: string }> {
    return (
      householdItems.map((household: ClientGroup) => ({ id: household.id, label: household.name }))
    );
  }

  const currentOption = () => {
    const households = relatedHouseholds.filter((a: any) => a.id === linkingHouseholdId);
    if (households && households.length > 0) {
      return {
        id: households[0].id,
        label: households[0].name,
      };
    }
    return null;
  };
  return (
    <Dialog data-testid='household-selection-modal' open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ border: 'none', marginBottom: '0px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='headingLarge'>{t('householdSelection:modal.title')}</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Form onSubmit={() => {
        if (linkingHouseholdId) {
          updateData({ ...data, householdClientGroupId: linkingHouseholdId });
          setWorkflowData({ ...workflowData, currentHouseholdClientGroupId: linkingHouseholdId, isHouseholdRemoved: false });
        }
        setOpen(false);
      }}>
        <DialogContent>
          <Box display='flex' flexDirection='row' sx={{ flexFlow: 'wrap' }} mt={1}>
            <Grid item xs={12}>
              <Autocomplete
                testId="household-selection"
                label={t('workflowCompletions:household.name')}
                value={currentOption()}
                options={getHouseholdOptions(relatedHouseholds)}
                onChange={handleHouseholdChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: undefined,
                }}
                fullWidth
              ></Autocomplete>
            </Grid>
          </Box>
        </DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='end' p={1}>
            <Button data-testid="done-btn" label={action === WorkflowActions.CREATE ? t('shared:done') : t('client:form.update')} type='submit' variant='tonal' />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
