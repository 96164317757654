/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { formatMoneyValue } from '../../../../util';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import { TRANSITION_TRANSFER, FETCH_TRANSFERS } from './transfers.queries';
import { useLocalization } from '../../../../util/useLocalization';
import {
  Box,
  Skeleton,
  Typography,
} from '../../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, MenuItem,
  TableHeadCell, Card, CardContent, SelectField, Badge,
} from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { TransferModal } from '../pendingTransfers/components/transferModal';

export const Transfers = ({
  objectType, objectId, options,
}: { objectType: PageObjectType, objectId: string, options: any }) => {
  const { t } = useTranslation(['components', 'transfer']);
  const { localizedDate } = useLocalization();
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'tra/page');
  const [state, setState] = usePageState('any', 'tra/state');
  const [activeTransfer, setActiveTransfer] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const { activeOrganization } = useContext(UserContext);
  const [transfers, setTransfers] = useState<{ transfers: any[], totalCount: number }>();
  const pageSize = 15;

  const queryFilter = {
    organizationId: activeOrganization.id,
    state: state === 'any' ? undefined : state,
  };

  let filter: any = {};

  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
    case PageObjectType.NON_INDIVIDUAL:
      filter = { userId: objectId };
      break;
    case PageObjectType.HOUSEHOLD:
      filter = { clientGroupId: objectId };
      break;
    case PageObjectType.ACCOUNT:
      filter = { accountId: objectId };
      break;
    case PageObjectType.SUB_ACCOUNT:
      filter = { subAccountId: objectId };
      break;
    case PageObjectType.GOAL:
      filter = { goalId: objectId };
      break;
    default:
      break;
  }

  const { loading, previousData, refetch } = useQuery(FETCH_TRANSFERS(permissions), {
    notifyOnNetworkStatusChange: true,
    skip: !permissions.includes('read:transfer_basic'),
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
        pagination: {
          sortField: 'createdAt', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted(data) {
      setTransfers({ transfers: data.fetchTransfers?.transfers, totalCount: data.fetchTransfers?.totalCount });

      if (activeTransfer.id) {
        setActiveTransfer(data.fetchTransfers.transfers.find((x: any) => x.id === activeTransfer.id));
      }
    },
  });

  const [transition] = useMutation(TRANSITION_TRANSFER, {
    onCompleted: () => {
      refetch();
    },
  });

  const color = (s: string) : 'neutral' | 'positive' | 'negative' | 'warning' | 'neutral' => {
    switch (s) {
      case 'INITIATED':
      case 'READY':
      case 'PROCESSING':
      case 'REQUESTED':
      case 'REVIEWING':
        return 'neutral';
      case 'DRAFT':
        return 'warning';
      case 'RECONCILED':
      case 'ACTIVE':
        return 'positive';
      case 'FAILED':
      case 'CANCELED':
        return 'negative';
      default:
        return 'neutral';
    }
  };

  const transferStates = [
    'INITIATED',
    'READY',
    'PROCESSING',
    'REQUESTED',
    'REVIEWING',
    'RECONCILED',
    'FAILED',
    'CANCELED',
  ];

  useEffect(() => function cleanupOnUnmount() {
    setState('any');
    setPage(1);
  }, []);

  if (!permissions.includes('read:transfer_basic')) return <></>;

  return (
    <Box>
      { options.customTitle && (<Typography variant='headingSmall' sx={{ mb: 2 }}>{translateBackend(options.customTitle)}</Typography>) }
      <Card>
        <CardContent>
          <Box display='flex' justifyContent='space-between' alignItems='end'>
            <SelectField
              value={state}
              label={t('transferTable.state')}
              onChange={(e: any) => setState(e.target.value)}
              size='small'
              fullWidth
              sx={{ width: '250px' }}
              placeholder={t('any')}
            >
              <MenuItem value={'any'}>{t('any')}</MenuItem>
              {transferStates.map((x: any) => (
                <MenuItem value={x} key={x}>{t(`transfer:states.${x}`)}</MenuItem>
              ))}
            </SelectField>
          </Box>
        </CardContent>
        <Table aria-label="table">
          <TableBody>
            <TableRow>
              <TableHeadCell dense>{t('components:transaction.table.account')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.type')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.source')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.state')}</TableHeadCell>
              <TableHeadCell dense right>{t('components:transaction.table.valueCents')}</TableHeadCell>
              <TableHeadCell dense>{t('components:transaction.table.date')}</TableHeadCell>
            </TableRow>
            {loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
                <TableCell dense><Skeleton /></TableCell>
              </TableRow>
            ))}
            {transfers?.transfers.map((transfer: any) => (
              <TableRow
                hover
                pointer
                onClick={() => {
                  setActiveTransfer(transfer);
                  setModalOpen(true);
                }}
                key={transfer.id}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <TableCell dense>{t(`accountTypes:${transfer.subAccount?.account?.type}`)}</TableCell>
                <TableCell dense>{t(`components:pendingTransfers.types.${transfer.type}`)}</TableCell>
                <TableCell dense>
                  {t(`components:transferSources.${transfer.source}`)}
                  {transfer.dividendFor?.ticker ? ' - ' : ''}
                  {transfer.dividendFor?.ticker && <b>{transfer.dividendFor.ticker}</b>}
                </TableCell>
                <TableCell dense><Badge label={t(`components:pendingTransfers.states.${transfer.state}`)} color={color(transfer.state)}/></TableCell>
                <TableCell dense number>{formatMoneyValue(transfer.amountCents * (transfer.type === 'WITHDRAW' ? -1 : 1))}</TableCell>
                <TableCell dense>{ localizedDate(transfer.createdAt) }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.ceil((transfers?.totalCount ?? 0) / pageSize)}
          page={page}
          size='small'
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
        <TransferModal
          transfer={activeTransfer}
          open={modalOpen}
          setOpen={setModalOpen}
          options={{ ...options, canCancelPending: options.canTransition }}
          transition={(transferId, _isScheduled, variables) => {
            transition({
              variables: {
                input: {
                  transferId,
                  ...variables,
                },
              },
            });
          }}
        />
      </Card>
    </Box>
  );
};

export default Transfers;
