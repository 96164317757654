/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  Typography, Box,
} from '../../../../1-primative';
import {
  Button,
} from '../../../../2-component';

export enum FileTypes {
  PDF = 'pdf',
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  HEIC = 'heic',
  CSV = 'csv',
}

interface Props {
  onFileChosen: (file: File) => void,
  fileMaxSize?: number,
  acceptedFiles?: FileTypes[],
}

/**
 * extension to input type='file' with drag-n-drop feature and some graphical design
 */
const DroppableFileInput = ({ onFileChosen, fileMaxSize, acceptedFiles }: Props): JSX.Element | null => {
  const [file, setFile] = useState<File>();
  const [draggedOver, setDraggedOver] = useState(false);
  const { t } = useTranslation('document');
  const { sys } = useThemeTokens();

  const readableFileSize = (bytes: number): string => {
    if (bytes < 1024) return `${bytes} bytes`;
    const kB = bytes / 1024;
    const fileSizeMb = (kB / 1024).toFixed(1);
    if (kB < 1024) return `${kB.toFixed()} kB`;
    return `${fileSizeMb} MB`;
  };

  const fileSizeRestriction = (choosenFile: File): boolean => {
    const { size } = choosenFile;
    const kB = size / 1024;
    const fileSizeMb = (kB / 1024).toFixed(1);
    if (Number(fileSizeMb) > fileMaxSize!) {
      return false;
    }
    return true;
  };
  const fileTypeRestriction = (chosenFile: File): boolean => {
    const { type } = chosenFile;
    return acceptedFiles!.some((fileType) => type.includes(fileType));
  };
  const onFilesDropped = (files: FileList): void => {
    if (files.length === 0) return;
    if (files.length > 1) {
      // alert(t('droppableFileInput.oneFileAtATime'));
      return;
    }
    if (fileSizeRestriction(files[0]) && fileTypeRestriction(files[0])) {
      setFile(files[0]);
      onFileChosen(files[0]);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '136px',
        height: '136px',
        backgroundColor: draggedOver ? '#bec9d4' : '#E4ECF4',
        borderStyle: file ? 'solid' : 'dashed',
        borderWidth: '1px',
        borderColor: '#AAB4BF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        marginBottom: '16px',
      }}
      onDrop={(e) => {
        onFilesDropped(e.dataTransfer.files);
        e.preventDefault();
        setDraggedOver(false);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        setDraggedOver(true);
      }}
      onDragStart={() => { setDraggedOver(true); }}
      onDragEnter={() => { setDraggedOver(true); }}
      onDragLeave={() => { setDraggedOver(false); }}
      onDragEnd={() => { setDraggedOver(false); }}
    >
      {!file
        && (
          <>
            <CloudUploadIcon fontSize="large" sx={{ color: sys.color.onSurfaceVariant }} />
            <Typography variant="bodyMedium" sx={{ marginBottom: '0px', color: sys.color.onSurfaceVariant }}>
              {t('droppableFileInput.dragFilesHere')}
              &nbsp;
              {t('or')}
            </Typography>
          </>
        )}
      {file
        && (
          <>
            <InsertDriveFileIcon fontSize="large" color="secondary" sx={{ marginTop: '16px', color: sys.color.onSurfaceVariant }} />
            <Typography variant="bodyMedium" sx={{ marginBottom: '0px' }}>
              {file.name}
            </Typography>
            <Typography variant="bodyMedium" sx={{ marginBottom: '0px' }}>
              {t('droppableFileInput.fileSize')}
              :
              &nbsp;
              {readableFileSize(file.size)}
            </Typography>
          </>
        )}
      <Typography variant="bodyMedium">
        <Button
          component='label'
          label={file ? t('droppableFileInput.chooseAnotherFile') : t('droppableFileInput.chooseAFile')}
          sx={{
            background: 'none',
            textDecoration: 'underline',
            cursor: 'pointer',
            m: 1,
          }}
        >
          <input
            hidden
            type="file"
            accept={`.${acceptedFiles?.join(', .')}`}
            data-testid="fileDropzone"
            onChange={(e: ChangeEvent<HTMLInputElement & FileList>) => {
              if (e.target.files && e.target.files[0] && fileSizeRestriction(e.target.files[0])) {
                setFile(e.target.files[0]);
                onFileChosen(e.target.files[0]);
              }
            }}
          />
        </Button>
      </Typography>
      {!file && (
        <Typography fontSize="small" colorVariant='variant' variant="bodySmall">
          {t('droppableFileInput.maxDocumentSize', { fileMaxSize: fileMaxSize!.toString() })}
        </Typography>

      )}
    </Box>
  );
};

DroppableFileInput.defaultProps = {
  fileMaxSize: 4,
  acceptedFiles: Object.values(FileTypes),
};

export default DroppableFileInput;
