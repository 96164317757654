/* eslint-disable object-curly-newline */
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SubTradeRequestsTable, { FETCH_SUB_TRADES, TradeToVerify } from './subTradeRequestsTable';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../providers/globalToastProvider';
import { useLocalization } from '../../../../util/useLocalization';
import DownloadSubTradeRequests from './downloadSubTradeRequests';

const CANCEL_BULK_TRADE = gql`
  mutation transitionBulkTradeRequest($input: TransitionBulkTradeRequestInput!) {
    transitionBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CANCEL_SUB_TRADE_REQUESTS = gql`
  mutation cancelSubTradeRequests($input: CancelSubTradeRequestsInput!) {
    cancelSubTradeRequests(input: $input)
  }
`;

export const VERIFY_SUB_TRADE_REQUESTS = gql`
  mutation verifySubTradeRequests($input: VerifySubTradeRequestsInput!) {
    verifySubTradeRequests(input: $input)
  }
`;

const BulkTradeRequests = ({
  bulkTradeRequestId,
  bulkTradeRequest,
  afterCancel,
  isRefetch,
  isCancelSubTradesPerPortfolioReport,
  sourceId,
  isCancelDisabled,
  portfolioOptimizerCreatedAt,
}: {
  bulkTradeRequestId?: string;
  bulkTradeRequest?: Record<string, any>;
  afterCancel: () => void;
  isRefetch?: boolean;
  isCancelSubTradesPerPortfolioReport?: boolean;
  sourceId?: string;
  isCancelDisabled?: boolean;
  portfolioOptimizerCreatedAt?: Date;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const { t } = useTranslation(['components', 'shared']);
  const { localizedDateTime } = useLocalization();
  const { showToast } = useGlobalToast();
  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);

  const [transitionBulkTradeRequest] = useMutation(CANCEL_BULK_TRADE, {
    variables: {
      input: {
        bulkTradeRequestId,
        transition: 'cancel',
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [cancelSubTradeRequests] = useMutation(CANCEL_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        bulkTradeRequestId,
        sourceId,
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [verifySubTradeRequests] = useMutation(VERIFY_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        subTradeRequestIds: tradesToVerify.map((trade: TradeToVerify) => trade?.id),
      },
    },
    onCompleted: () => {
      setTradesToVerify([]);
      showToast({ severity: 'success', message: t('components:generateTrades.verifyTradesResult') });
    },
    refetchQueries: [FETCH_SUB_TRADES()],
  });

  const cancel = async () => {
    if (isCancelSubTradesPerPortfolioReport && sourceId) {
      cancelSubTradeRequests();
    } else {
      transitionBulkTradeRequest();
    }
  };

  const isShowVerificationBtn = permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false);
  const isShowCancelTradesBtn = permissions.includes('transition:sub_trade_request') && (!(isCancelSubTradesPerPortfolioReport && sourceId) ? true : isCancelSubTradesPerPortfolioReport && sourceId);

  return (
    <Card sx={{ mt: 2 }}>
      {bulkTradeRequestId ? (
        <>
          <Grid container justifyContent='space-between' alignItems='center' p={3}>
            <Grid item>
              <Typography fontWeight={600} fontSize='20px'>
                {t('components:generateTrades.activeTradeRequests')}
              </Typography>

              {!!portfolioOptimizerCreatedAt && (
                <Typography fontWeight={300} fontSize='14px'>
                  ({t('components:generateTrades.generatedByPortfolioOptimizer', { portfolioOptimizerCreatedAt: localizedDateTime(portfolioOptimizerCreatedAt) })})
                </Typography>
              )}
            </Grid>

            <Grid item sx={{ display: 'flex' }}>
              {permissions.includes('read:api_exports') && (
                <Box sx={{ mr: 2 }}>
                  <DownloadSubTradeRequests filter={{ bulkTradeRequestId, sourceId }} />
                </Box>
              )}

              {isShowVerificationBtn && (
                <Button
                  sx={{ mr: 2 }}
                  variant='outlined'
                  color='error'
                  onClick={() => {
                    if (tradesToVerify.length) verifySubTradeRequests();
                  }}
                  disabled={!tradesToVerify.length}
                >
                  {t('components:generateTrades.verifyTrades')}
                </Button>
              )}

              {isShowCancelTradesBtn && (
                <Button variant='outlined' disabled={isCancelDisabled} color='error' onClick={() => cancel()}>
                  {t('components:generateTrades.cancelTrades')}
                </Button>
              )}
            </Grid>
          </Grid>

          <SubTradeRequestsTable
            id={bulkTradeRequestId}
            sourceId={sourceId}
            isRefetch={isRefetch}
            onTradesToVerifyUpdate={(trades: TradeToVerify[]) => setTradesToVerify(trades)}
            afterCancel={() => afterCancel()}
          />
        </>
      ) : (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left'>
            <InfoRoundedIcon />
            <Typography variant='h6' fontWeight='600' sx={{ fontSize: 16, marginLeft: 1 }}>
              {t('components:generateTrades.noActiveTradeRequests')}
            </Typography>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default BulkTradeRequests;
