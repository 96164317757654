import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Button, FormControlLabel, ListItem, MenuItem, Switch, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import { FETCH_GOAL } from '../../../../pages/goalsDetails';
import { usePermissions } from '../../../../providers/userContextProvider';
import ModelPortfoliosSelect from '../../../inputs/modelPortfoliosSelect';
import ThemeSelect from '../../../inputs/themeSelect';
import { styles } from '../../wizardBase';
import { StepProps } from '../wizard';

export const FETCH_ACCOUNT = gql`
  query fetchAccount($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        type
        custodianAccountNumber
      }
    }
  }
`;

const FETCH_GOALS_BY_ACCOUNT = gql`
  query fetchGoalsByAccount($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        user { goals { id name state }}
        clientGroup { goals { id name state }}
      }
    }
  }
`;

export function CreateSubAccountStep({
  context, state, setState, continueTo,
}: StepProps) {
  const { t } = useTranslation(['createSubAccountWizard', 'accountTypes']);
  const { permissions } = usePermissions();
  const [availableGoals, setAvailableGoals] = useState<any[]>([]);
  useQuery(FETCH_GOALS_BY_ACCOUNT, {
    skip: context.forObject !== 'ACCOUNT',
    variables: { id: context.forId },
    onCompleted: (data) => {
      const userGoals = data.fetchAccount.account.user?.goals.filter((a: any) => a.state === 'ACTIVE') ?? [];
      const cgGoals = data.fetchAccount.account.clientGroup?.goals.filter((a: any) => a.state === 'ACTIVE') ?? [];
      const allGoals = uniqBy([...userGoals, ...cgGoals], (goal) => goal.id);
      setAvailableGoals(allGoals);
    },
  });
  const accountQuery = useQuery(FETCH_ACCOUNT, { variables: { id: state.accountId } });
  const [fetchGoal, { called: goalCalled }] = useLazyQuery(FETCH_GOAL(permissions), {
    variables: { id: state.goalId },
    onCompleted: (data: any) => {
      if (data.fetchGoal.goal?.financialProduct) {
        setState({
          ...state,
          isPartial: false,
          themeId: data.fetchGoal.goal.financialProduct.theme.id,
          financialProductId: data.fetchGoal.goal.financialProduct.id,
          isPortfolioGoalAttached: true,
        });
      } else {
        setState({
          ...state,
          isPartial: true,
          themeId: '',
          financialProductId: '',
          isPortfolioGoalAttached: false,
        });
      }
      if (context.forObject === 'GOAL') { setAvailableGoals([data.fetchGoal.goal]); }
    },
  });

  useEffect(() => {
    if ((state.goalId) && !goalCalled) {
      fetchGoal();
    }
  }, [state.goalId, fetchGoal, goalCalled]);

  const disabled = !(state.goalId && state.themeId && state.financialProductId);
  return (
    <>
      <ListItem>
        <Typography variant='h6' sx={styles.titleText}>{t('title')}</Typography>
      </ListItem>
      {accountQuery.data && (
        <ListItem>
          <Typography variant='subtitle2' sx={styles.subText}>
            {t(`accountTypes:${accountQuery.data.fetchAccount.account.type}`)}
            {accountQuery.data.fetchAccount.account.custodianAccountNumber && ` #${accountQuery.data.fetchAccount.account.custodianAccountNumber}`}
          </Typography>
        </ListItem>
      )}
      <ListItem key='accountType'>
        {accountQuery.data && (
          <TextField disabled select label={t('client:accountType')} fullWidth value={accountQuery.data.fetchAccount.account.type}>
            <MenuItem value={accountQuery.data.fetchAccount.account.type}>
              {t(`accountTypes:${accountQuery.data.fetchAccount.account.type}`)}
            </MenuItem>
          </TextField>
        )}
      </ListItem>
      {availableGoals && (
        <ListItem key='selectGoal'>
          <TextField
            disabled={context.forObject === 'GOAL'}
            required
            select
            label={t('client:selectGoal')}
            fullWidth
            value={state.goalId}
            onChange={(e) => setState({ ...state, goalId: e.target.value })}>
              {availableGoals.map((x: any) => (
                <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
              ))}
          </TextField>
        </ListItem>
      )}
      <ListItem>
        <ThemeSelect
          value={state.themeId || ''}
          onChange={(newValue) => setState({ ...state, themeId: newValue })}
          label={t('client:selectTheme')}
          disabled={state.isPortfolioGoalAttached}
          inheritedText={state.isPortfolioGoalAttached ? t('inherited') : undefined}
        />
      </ListItem>
      {state.themeId ? (
        <ListItem>
          <ModelPortfoliosSelect
            value={state.financialProductId}
            themeId={state.themeId}
            onChange={(e) => setState({ ...state, financialProductId: e.target.value })}
            label={t('client:selectProduct')}
            disabled={state.isPortfolioGoalAttached}
            inheritedText={state.isPortfolioGoalAttached ? t('inherited') : undefined}
          />
        </ListItem>
      ) : null}
      <ListItem>
        <FormControlLabel
          control={<Switch
            checked={!state.skipIPS}
            onChange={() => {
              setState({ ...state, skipIPS: !state.skipIPS });
            }}
          />}
          disabled={state.isPortfolioGoalAttached}
          label={t('client:subAccountModal.requireInvestorPolicyStatement')}
        />
      </ListItem>
      <ListItem>
        <FormControlLabel
          control={<Switch
            checked={state.isPartial}
            onChange={() => {
              setState({ ...state, isPartial: !state.isPartial });
            }}
          />}
          label={t('client:subAccountModal.allowFractionalShares')}
          disabled={state.isPortfolioGoalAttached}
        />
      </ListItem>
      <ListItem>
        <FormControlLabel
          control={<Switch
            checked={state.allowClientDeposits}
            onChange={() => {
              setState({ ...state, allowClientDeposits: !state.allowClientDeposits });
            }}
          />}
          label={t('client:allowClientDeposits')}
        />
      </ListItem>
      <Box sx={{ flex: 1 }}></Box>
      <ListItem>
        <Button
          disabled={disabled}
          data-testid='confirmButton'
          fullWidth variant='contained'
          onClick={() => {
            continueTo('SUB_ACCOUNT_SUMMARY');
          }}
        >
          {t('actions.createSubAccount')}
        </Button>
      </ListItem>
    </>
  );
}
