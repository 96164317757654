import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSearchParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import {
  Badge,
  Button,
  Card,
  Dialog, DialogContent,
  DialogFooter,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem, SelectField,
  Table,
  TableBody,
  TableCell,
  TableHeadCell,
  TableRow, TextField,
} from '../../../../2-component';
import { translateBackend } from '../../../../../assets/i18n/config';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { ActionContext, PageObjectType } from '../../../../5-page';
import {
  AffiliationRelations, RelationshipInput, User,
} from '../../../../../interfaces';
import { formatMoneyValue, formatPhoneNumber } from '../../../../../util';
import { CREATE_WORKFLOW_COMPLETION } from '../../../../../components/layout/client/clientSummary';
import { useClientContext } from '../../../../../pages/client';
import { useHouseholdContext } from '../../../../../pages/household';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

const FETCH_HOUSEHOLD = (permissions: string[]) => gql`
  query fetchHousehold($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        name
        primaryUser {
          id
          firstName
          middleName
          lastName
          ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
          ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
          physicalAddress { city province streetName postal unitNumber houseNumber neighborhood country }
          ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
        }
        relationships {
          type
          user {
            id
            state
            firstName
            middleName
            lastName
            primaryEmail
            annualIncomeCents
            ${permissions.includes('read:client_high_risk_pii') ? 'phone' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? '' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'complianceState' : ''}
            ${permissions.includes('read:client_suitability') ? 'annualIncomeCents' : ''}
            ${permissions.includes('read:client_suitability') ? 'lastSuitabilityReviewAt' : ''}
            ${permissions.includes('read:client_suitability') ? 'financialLiquidAssetsCents' : ''}
            ${permissions.includes('read:client_suitability') ? 'financialFixedAssetsCents' : ''}
            ${permissions.includes('read:client_suitability') ? 'totalDebtCents' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
            type
          }
          accessType
        }
      }
    }
  }
`;

export const UPDATE_CLIENTGROUP_RELATIONSHIPS = gql`
  mutation updateClientGroupRelationships($clientGroupId: ObjectID!, $relationships: [RelationshipInput]) {
    updateClientGroup(input: {
      clientGroupId: $clientGroupId
      relationships: $relationships
    }) {
      clientGroup {
        id
      }
    }
  }
`;

export const TableHouseHoldMembers = ({
  objectType,
  objectId,
  options = {},
}: {
  objectType: PageObjectType,
  objectId: string,
  options?: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const primaryUserNameRef = useRef<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const { permissions } = usePermissions();
  const clientContext = useClientContext();
  const { sys } = useThemeTokens();
  const householdContext = useHouseholdContext();
  const { activeOrganization, activeCurrency } = useContext(UserContext);
  const { activeWorkflows, refetch: refetchWorkflow } = useContext(ActionContext);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateRelationshipState, setUpdateRelationshipState] = useState<{
    type?: string,
    accessType?: string,
    user?: any,
  }>();
  const [updateRelationships] = useMutation(UPDATE_CLIENTGROUP_RELATIONSHIPS);
  const [params, setSearchParams] = useSearchParams();

  const { t } = useTranslation(['client', 'accountTypes', 'household']);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(false);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };
  const { data: documents, loading: docsLoading, refetch } = useQuery(FETCH_HOUSEHOLD(permissions), {
    variables: {
      id: objectId,
    },
    fetchPolicy: 'no-cache',
  });
  const doEditMember = () => {
    const existingRelationships = documents.fetchClientGroup.clientGroup.relationships.map((r: any) => ({
      type: r.type,
      userId: r.user.id,
      accessType: r.accessType,
    }));
    const updatedRelationships = existingRelationships.map((rel:RelationshipInput) => (rel.userId === updateRelationshipState?.user.id
      ? {
        userId: rel.userId,
        type: updateRelationshipState.type,
        accessType: updateRelationshipState.accessType,
      }
      : rel
    ));
    updateRelationships({
      variables: {
        clientGroupId: objectId,
        relationships: updatedRelationships,
      },
      onCompleted: () => {
        refetch();
        setOpenDialog(false);
      },
      onError: () => {},
    }).then();
  };
  const netWorthCents = (user: User) => (user?.financialLiquidAssetsCents ?? 0)
        + (user?.financialFixedAssetsCents ?? 0)
        - (user?.totalDebtCents ?? 0);
  const getUserField = (relationship: any, key: string) => {
    if (key === 'name') {
      return (
        <TableCell dense>
          {relationship.user.firstName ? relationship.user.firstName : relationship?.user?.entityName}
        </TableCell>
      );
    }
    if (key === 'relationship') {
      return (
          <TableCell dense>
            {documents.fetchClientGroup.clientGroup.primaryUser.id === relationship.user.id
              ? t('household:summary.primaryContact')
              : t(`affiliationTypes:affiliationRelationship.${relationship.type}`)}
          </TableCell>
      );
    }
    if (key === 'phoneNumber') {
      return (
          <TableCell dense>
            {relationship.user?.phone ? formatPhoneNumber(relationship.user?.phone) : ''}
          </TableCell>
      );
    }
    if (key === 'email') {
      return (
          <TableCell dense>
            {relationship.user?.primaryEmail}
          </TableCell>
      );
    }
    if (key === 'netWorth') {
      return (
          <TableCell number>
            {formatMoneyValue(netWorthCents(relationship.user))}
          </TableCell>
      );
    }
    if (key === 'annualIncome') {
      return (
          <TableCell number>
            {formatMoneyValue(relationship.user?.annualIncomeCents)}
          </TableCell>
      );
    }
    if (key === 'status') {
      return (
          <TableCell dense>
            <Badge label={t(`client:details.state.${relationship.user.state}`)} color="positive" />
          </TableCell>
      );
    }
    return (
        <TableCell dense>
          {t(`household:accessTypes.${relationship.accessType}`)}
        </TableCell>
    );
  };
  const [createWorkflowCompletion] = useMutation(CREATE_WORKFLOW_COMPLETION, {
    onCompleted: (data: any) => {
      refetchWorkflow();
      updateWorkflowCompletion(data.createWorkflowCompletion.workflowCompletion.id);
    },
  });
  const updateWorkflowCompletion = (workflowCompletionId?: string) => {
    if (!workflowCompletionId) return;
    const newParams = new URLSearchParams(params);
    newParams.set('workflowCompletion', workflowCompletionId);
    setSearchParams(newParams);
  };
  const organizationId = clientContext?.orgSettings?.id || householdContext?.orgSettings?.id || activeOrganization?.id;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createHouseHold = (workflowId?: string) => {
    if (!workflowId || workflowId === 'default') {
      setOpen(true);
      return;
    }
    const activeWorkflow = activeWorkflows.find((x: any) => x?.workflow?.id === workflowId);
    if (activeWorkflow) {
      updateWorkflowCompletion(activeWorkflow?.id);
      return;
    }
    createWorkflowCompletion({
      variables: {
        input: {
          objectId,
          objectType: 'HOUSEHOLD',
          workflowId,
          organizationId,
        },
      },
    });
  };
  return (
        <>
          <Box display="flex" justifyContent="space-between" pb={2} minHeight='40px'>
            <Box display="flex" alignItems={'center'}>
              <Typography variant='headingSmall'>
                {options.customTitle || options.accountsCustomTitle ? translateBackend(options.customTitle ?? options.accountsCustomTitle) : t('accountsSummary.header')}
              </Typography>
            </Box>
          </Box>
            <Card sx={{ overflowX: 'auto' }}>
              <Table>
                    <TableBody>
                        <TableRow>
                            <>
                                {options.membersTable.map((x: any, idx: number) => (
                                    <TableHeadCell
                                      key={x.value}
                                      right={x.type === 'annualIncome' || x.type === 'netWorth'}
                                      isFirst={idx === 0}
                                    >
                                      {`${translateBackend(x.label)}${x.type === 'marketValue' ? `(${activeCurrency})` : ''}`}
                                    </TableHeadCell>
                                ))}
                                {
                                    (options.updateAccessPermission) && (
                                        <TableHeadCell key='addOrUpdate'>
                                        </TableHeadCell>
                                    )
                                }
                            </>
                        </TableRow>
                        {docsLoading && (
                            <>
                                {[...Array(15)].map((x, i) => (
                                    <TableRow key={i}>
                                        <>
                                            {options.membersTable.map(() => (
                                                <TableCell dense>
                                                    <Skeleton width='100%' height='16px' />
                                                </TableCell>
                                            ))}
                                        </>
                                    </TableRow>
                                ))}
                            </>
                        )}
                        {!docsLoading && (
                            <>
                                {documents.fetchClientGroup.clientGroup.relationships.map((x: any, i: any) => (
                                    <TableRow key={i}>
                                        <>
                                            {options.membersTable.map((obj: any) => getUserField(x, obj.value ?? obj.type))}
                                            {
                                                (options.updateAccessPermission) && (
                                                    <TableCell dense>
                                                        {x.type.toLowerCase() !== 'primary' && (<IconButton onClick={(e) => {
                                                          // eslint-disable-next-line max-len
                                                          primaryUserNameRef.current = `${documents.fetchClientGroup.clientGroup.primaryUser.firstName} ${documents.fetchClientGroup.clientGroup.primaryUser.lastName}`;
                                                          setUpdateRelationshipState(x);
                                                          handleClick(e);
                                                        }}>
                                                          <MoreVertIcon/>
                                                        </IconButton>)}
                                                    </TableCell>
                                                )
                                            }
                                        </>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
                <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
                    <MenuItem onClick={handleMenuItemClick}>{t('household:membersTable.editHouseholdMember')}</MenuItem>
                </Menu>
            </Card>
            <Dialog open={openDialog} sx={{ '& .MuiDialog-paper': { width: '400px', maxWidth: 'none' } }}>
              <DialogTitle>
                  <Typography variant="headingSmall">{t('household:membersTable.editHouseholdMember')}</Typography>
              </DialogTitle>
                <DialogContent>
                    <TextField value={updateRelationshipState?.user?.firstName ?? ''} label="Client" sx={{ mb: 2 }} fullWidth></TextField>
                    <SelectField
                      fullWidth
                      value={updateRelationshipState?.type ?? ''}
                      label={`Relationship to ${primaryUserNameRef.current} (primary member)`}
                      onChange={(e: any) => setUpdateRelationshipState({ ...updateRelationshipState, type: e.target.value })}
                      sx={{ mb: 2 }}
                    >
                      {Object.values(AffiliationRelations).map((relation, index) => (
                        <MenuItem key={index} value={relation}>
                          {t(`affiliationTypes:affiliationRelationship.${relation}`)}
                        </MenuItem>
                      ))}
                    </SelectField>
                    <SelectField
                      fullWidth
                      label="Permission"
                      onChange={(e: any) => setUpdateRelationshipState({ ...updateRelationshipState, accessType: e.target.value })}
                      value={updateRelationshipState?.accessType ?? ''}
                    >
                      <MenuItem value="VIEW">{t('household:accessTypes.VIEW')}</MenuItem>
                      <MenuItem value="NO_ACCESS">{t('household:accessTypes.NO_ACCESS')}</MenuItem>
                    </SelectField>
                </DialogContent>
              <DialogFooter>
                  <Button
                    sx={{ backgroundColor: `${sys.color.primaryVariant} !important`, color: `${sys.color.onPrimaryVariant} !important` }}
                    label={t('shared:cancel')}
                    onClick={() => setOpenDialog(false)}
                  />
                  <Button
                    label={t('shared:save')}
                    onClick={doEditMember}
                    sx={{ backgroundColor: `${sys.color.navigation} !important`, color: `${sys.color.onPrimary} !important` }}
                  />
              </DialogFooter>
            </Dialog>
        </>
  );
};
