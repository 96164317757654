/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Button,
  DateField,
  Dialog, DialogContent, DialogFooter, DialogTitle, DroppableFileInputAlt, FileTypes, Form, MenuItem, SegmentedControl, SelectField,
  TextField,
} from '../../../../2-component';
import { Box, Grid, Typography } from '../../../../1-primative';
import { Attachment } from '../../../../3-pattern/attachment/attachment';
import { base64ToFile } from '../../../workflowCompletion/subSteps/utils';
import { UserContext } from '../../../../../providers/userContextProvider';

export const documentType = ['CITIZENSHIP_CARD', 'DRIVERS_LICENSE',
  'INDIAN_STATUS_CARD', 'PASSPORT', 'PERMANENT_RESIDENT_CARD', 'PROVINCIAL_ID',
  'BANK_STATEMENT', 'MARRIAGE_LICENCE', 'OTHER', 'UTILITY_STATEMENT', 'CREDIT_FILE', 'PROPERTY_TAX_ASSESSMENT', 'GLOBAL_ENTRY_CARD',
  'NEXUS', 'GOV_ISSUED_CPP_STATEMENT', 'GOV_ISSUED_VEHICLE_REGISTRATION', 'BENEFITS_STATEMENT', 'CANADA_411', 'RECORD_OF_EMPLOYMENT',
  'INSURANCE_DOCUMENT', 'INVESTMENT_ACCOUNT_STATEMENT', 'BIRTH_CERTIFICATE', 'DIVORCE_DOCUMENTATION', 'CITIZENSHIP_CERTIFICATE',
  'TRAVEL_VISA', 'SCHOOL_DOCUMENT', 'CAD_CREDIT_BUREAU_PRODUCT', 'CREDIT_CARD_STATEMENT', 'LOAN_ACCOUNT_STATEMENT', 'PROCESSED_CHEQUE',
  'CONFIRMATION_FROM_FINANCIAL_ENTITY', 'MICRO_DEPOSIT',
];

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input:CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
    }
  }
`;

export const UPDATE_ID_VERIFICATION = gql`
  mutation updateUserIDVerificationInformation($input: UpdateUserIDVerificationInformationInput!) {
    updateUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

export const CREATE_ID_VERIFICATION = gql`
  mutation createUserIDVerificationInformation($input: CreateUserIDVerificationInformationInput!) {
    createUserIDVerificationInformation(input: $input) {
      idVerification {
        id
      }
    }
  }
`;

export const IdDialog = ({
  open, setOpen, idVerification, activeImages, userId, refetch,
}: {
  open: boolean, setOpen: (o: boolean) => void, idVerification: any, activeImages: any[], userId: string, refetch: () => void,
}) => {
  const { t } = useTranslation(['client', 'shared']);
  const [activeId, setActiveId] = useState<any>({});
  const [doc, setDoc] = useState<any>('primary');
  const [newPrimaryDoc, setNewPrimaryDoc] = useState<any>(null);
  const [newPrimaryDocName, setNewPrimaryDocName] = useState<any>('');
  const [newSecondaryDoc, setNewSecondaryDoc] = useState<any>(null);
  const [newSecondaryDocName, setNewSecondaryDocName] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState<string[]>([]);

  const [primaryImage, setPrimaryImage] = useState<any>(null);
  const [secondaryImage, setSecondaryImage] = useState<any>(null);
  const [fetchFileUploadUrl] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [createFileDocument] = useMutation(CREATE_FILE_DOCUMENT);
  const [createIdVerification] = useMutation(CREATE_ID_VERIFICATION, {
    variables: {
      input: {
        userId,
        documentType: activeId.documentType,
        documentID: activeId.documentID,
        documentIssuingJurisdication: activeId.documentIssuingJurisdication,
        documentExpiryDate: dayjs(activeId.documentExpiryDate).format('YYYY-MM-DD'),
        secondaryDocumentType: activeId.secondaryDocumentType ? activeId.secondaryDocumentType : undefined,
        secondaryDocumentID: activeId.secondaryDocumentID ? activeId.secondaryDocumentID : undefined,
        secondaryDocumentIssuingJurisdication: activeId.secondaryDocumentIssuingJurisdication ? activeId.secondaryDocumentIssuingJurisdication : undefined,
        secondaryDocumentExpiryDate: activeId.secondaryDocumentExpiryDate ? dayjs(activeId.secondaryDocumentExpiryDate).format('YYYY-MM-DD') : undefined,
        verifiedDate: dayjs(activeId.verifiedDate).format('YYYY-MM-DD'),
        userName: activeId.userName,
        secondaryDocumentUserName: activeId.secondaryDocumentUserName,
        methodOfIDVerification: activeId.methodOfIDVerification,
      },
    },
    onCompleted: async (data: any) => {
      if (newPrimaryDoc) {
        await doUpload(data.createUserIDVerificationInformation.idVerification.id, true);
      }
      if (newSecondaryDoc) {
        await doUpload(data.createUserIDVerificationInformation.idVerification.id, false);
      }
      refetch();
      setLoading(false);
      close();
    },
  });
  const [updateIdVerification] = useMutation(UPDATE_ID_VERIFICATION, {
    variables: {
      input: {
        idVerificationId: idVerification.id,
        documentType: activeId.documentType,
        documentID: activeId.documentID,
        documentIssuingJurisdication: activeId.documentIssuingJurisdication,
        documentExpiryDate: dayjs(activeId.documentExpiryDate).format('YYYY-MM-DD'),
        secondaryDocumentType: activeId.secondaryDocumentType ? activeId.secondaryDocumentType : undefined,
        secondaryDocumentID: activeId.secondaryDocumentID ? activeId.secondaryDocumentID : undefined,
        secondaryDocumentIssuingJurisdication: activeId.secondaryDocumentIssuingJurisdication ? activeId.secondaryDocumentIssuingJurisdication : undefined,
        secondaryDocumentExpiryDate: activeId.secondaryDocumentExpiryDate ? dayjs(activeId.secondaryDocumentExpiryDate).format('YYYY-MM-DD') : undefined,
        verifiedDate: dayjs(activeId.verifiedDate).format('YYYY-MM-DD'),
        userName: activeId.userName,
        secondaryDocumentUserName: activeId.secondaryDocumentUserName,
      },
    },
    onCompleted: async () => {
      if (newPrimaryDoc) {
        await doUpload(activeId.id, true);
      }
      if (newSecondaryDoc) {
        await doUpload(activeId.id, false);
      }
      refetch();
      setLoading(false);
      close();
    },
  });
  const { userContext } = useContext(UserContext);

  useEffect(() => {
    setActiveId(idVerification);
  }, [idVerification]);

  useEffect(() => {
    setPrimaryImage(activeImages[0]);
    setSecondaryImage(activeImages[1]);
  }, [activeImages]);

  const doUpload = async (sourceId: string, primary: boolean): Promise<any> => {
    setLoading(true);
    const newDoc = primary ? newPrimaryDoc : newSecondaryDoc;
    const newDocName = primary ? newPrimaryDocName : newSecondaryDocName;
    const uploadingFile = base64ToFile(newDoc!, newDocName);
    const createFileInput: any = {
      objectType: 'USER',
      objectId: userId,
      sourceId,
      fileName: newDocName,
      type: 'ID_VERIFICATION',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId: userContext.id } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      return;
    }
    /* (2) do the upload */
    try {
      const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      return;
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = uploadingFile.name;
    createFileInput.mediaType = uploadingFile.type;
    createFileInput.permissionType = 'PUBLIC';
    createFileInput.sourceType = 'ORGANIZATION_USER';

    await createFileDocument({
      variables: { input: createFileInput },
      onCompleted(data: any) {
        if (primary) {
          setPrimaryImage(data.createFileDocument.fileDocument.id);
          setNewPrimaryDoc(null);
          setNewPrimaryDocName('');
        } else {
          setSecondaryImage(data.createFileDocument.fileDocument.id);
          setNewSecondaryDoc(null);
          setNewSecondaryDocName('');
        }
      },
    });
  };

  const setFile = async (file: File, primary: boolean) => {
    primary ? setNewPrimaryDocName(file.name) : setNewSecondaryDocName(file.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      primary ? setNewPrimaryDoc(base64String) : setNewSecondaryDoc(base64String);
    };
  };

  const validate = () => {
    const invalidFieldSet: string[] = [];
    if (activeId.methodOfIDVerification === 'DUAL_METHOD') {
      if (!activeId.userName) {
        invalidFieldSet.push('userName');
      }
      if (!activeId.secondaryDocumentUserName) {
        invalidFieldSet.push('secondaryDocumentUserName');
      }
      if (!activeId.documentType) {
        invalidFieldSet.push('documentType');
      }
      if (!activeId.documentType) {
        invalidFieldSet.push('secondaryDocumentType');
      }
      if (!activeId.documentIssuingJurisdication) {
        invalidFieldSet.push('documentIssuingJurisdication');
      }
      if (!activeId.secondaryDocumentIssuingJurisdication) {
        invalidFieldSet.push('secondaryDocumentIssuingJurisdication');
      }
      if (!activeId.documentID) {
        invalidFieldSet.push('documentID');
      }
      if (!activeId.secondaryDocumentID) {
        invalidFieldSet.push('secondaryDocumentID');
      }
    }
    if (['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'IN_PERSON_VALIDATION'].includes(activeId.methodOfIDVerification)) {
      if (!activeId.userName) {
        invalidFieldSet.push('userName');
      }
      if (!activeId.documentType) {
        invalidFieldSet.push('documentType');
      }
      if (!activeId.documentID) {
        invalidFieldSet.push('documentID');
      }
      if (!activeId.documentIssuingJurisdication) {
        invalidFieldSet.push('documentIssuingJurisdication');
      }
      if (activeId.methodOfIDVerification === 'CREDIT_CHECK') {
        if (!activeId.creditFileSource) {
          invalidFieldSet.push('creditFileSource');
        }
      }
    }
    setFocused(invalidFieldSet);
    return invalidFieldSet.length === 0;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }

    if (!activeId.id) {
      await createIdVerification();
    } else {
      await updateIdVerification();
    }
  };

  const close = () => {
    setDoc('primary');
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={() => close()} maxWidth='sm' fullWidth>
      <DialogTitle onClose={() => close()}>
        {t('client:profile.idVerification')}
      </DialogTitle>
      <Form onSubmit={submit}>
        <DialogContent>
          <SelectField
            label={t('client:methodOfIDVerification')}
            value={activeId.methodOfIDVerification || ''}
            disabled={activeId.id !== undefined}
            fullWidth
            onChange={(e: any) => setActiveId({ ...activeId, methodOfIDVerification: e.target.value })}
            sx={{ mb: 2 }}
          >
            {
              ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'DUAL_METHOD', 'IN_PERSON_VALIDATION'].map((v: string, i: number) => (
                <MenuItem key={i} value={v}>{t(`client:edit.methodOfIDVerificationOptions.${v}`)}</MenuItem>
              ))
            }
          </SelectField>
          {activeId.methodOfIDVerification === 'DUAL_METHOD' && (
            <SegmentedControl fullWidth sx={{ mb: 2 }} segments={[
              { label: t('client:primaryDocument'), value: 'primary' },
              { label: t('client:secondaryDocument'), value: 'secondary' },
            ]} onChange={(value: any) => setDoc(value)} value={doc} />
          )}
          { doc === 'primary' ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('client:nameOnTheID')}
                    value={activeId.userName}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, userName: e.target.value })}
                    onBlur={() => setFocused([...focused, 'userName'])}
                    error={!activeId.userName && focused.includes('userName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectField
                    label={t('client:documentType')}
                    value={activeId.documentType || ''}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, documentType: e.target.value })}
                    error={!activeId.documentType && focused.includes('documentType')}
                  >
                    {documentType.map((v: string, i: number) => (
                      <MenuItem key={i} value={v}>{t(`client:documentTypes.${v}`)}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                { activeId.methodOfIDVerification === 'CREDIT_CHECK' && (
                  <Grid item xs={12}>
                    <TextField
                      label={t('client:creditFileSource')}
                      value={activeId.creditFileSource}
                      fullWidth
                      onChange={(e: any) => setActiveId({ ...activeId, creditFileSource: e.target.value })}
                      onBlur={() => setFocused([...focused, 'creditFileSource'])}
                      error={!activeId.creditFileSource && focused.includes('creditFileSource')}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('client:documentID')}
                    value={activeId.documentID}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, documentID: e.target.value })}
                    onBlur={() => setFocused([...focused, 'documentID'])}
                    error={!activeId.documentID && focused.includes('documentID')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('idVerifications.documentIssuingJurisdication')}
                    value={activeId.documentIssuingJurisdication}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, documentIssuingJurisdication: e.target.value })}
                    onBlur={() => setFocused([...focused, 'documentIssuingJurisdication'])}
                    error={!activeId.documentIssuingJurisdication && focused.includes('documentIssuingJurisdication')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField
                    label={t('client:verifiedDate')}
                    value={activeId.verifiedDate}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, verifiedDate: e })}
                    onBlur={() => setFocused([...focused, 'verifiedDate'])}
                    error={!activeId.verifiedDate && focused.includes('verifiedDate')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField
                    label={t('client:expiryDate')}
                    value={activeId.documentExpiryDate}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, documentExpiryDate: e })}
                    onBlur={() => setFocused([...focused, 'documentExpiryDate'])}
                    error={!activeId.documentExpiryDate && focused.includes('documentExpiryDate')}
                  />
                </Grid>
              </Grid>
              <Typography variant='labelSmall' sx={{ mt: 2 }} colorVariant='variant'>{t('client:supportingDocuments')}</Typography>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('client:nameOnTheID')}
                    value={activeId.secondaryDocumentUserName}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, secondaryDocumentUserName: e.target.value })}
                    onBlur={() => setFocused([...focused, 'secondaryDocumentUserName'])}
                    error={!activeId.secondaryDocumentUserName && focused.includes('secondaryDocumentUserName')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectField
                    label={t('client:documentType')}
                    value={activeId.secondaryDocumentType || ''}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, secondaryDocumentType: e.target.value })}
                    onBlur={() => setFocused([...focused, 'secondaryDocumentType'])}
                    error={!activeId.secondaryDocumentType && focused.includes('secondaryDocumentType')}
                  >
                    {documentType.map((v: string, i: number) => (
                      <MenuItem key={i} value={v}>{t(`client:documentTypes.${v}`)}</MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('client:documentID')}
                    value={activeId.secondaryDocumentID}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, secondaryDocumentID: e.target.value })}
                    onBlur={() => setFocused([...focused, 'secondaryDocumentID'])}
                      error={!activeId.secondaryDocumentID && focused.includes('secondaryDocumentID')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('idVerifications.documentIssuingJurisdication')}
                    value={activeId.secondaryDocumentIssuingJurisdication}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, secondaryDocumentIssuingJurisdication: e.target.value })}
                    onBlur={() => setFocused([...focused, 'secondaryDocumentIssuingJurisdication'])}
                      error={!activeId.secondaryDocumentIssuingJurisdication && focused.includes('secondaryDocumentIssuingJurisdication')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField
                    label={t('client:verifiedDate')}
                    value={activeId.verifiedDate}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, verifiedDate: e })}
                    onBlur={() => setFocused([...focused, 'verifiedDate'])}
                    error={!activeId.verifiedDate && focused.includes('verifiedDate')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DateField
                    label={t('client:expiryDate')}
                    value={activeId.secondaryDocumentExpiryDate}
                    fullWidth
                    onChange={(e: any) => setActiveId({ ...activeId, secondaryDocumentExpiryDate: e })}
                    onBlur={() => setFocused([...focused, 'secondaryDocumentExpiryDate'])}
                    error={!activeId.secondaryDocumentExpiryDate && focused.includes('secondaryDocumentExpiryDate')}
                  />
                </Grid>
              </Grid>
              <Typography variant='labelSmall' sx={{ mt: 2 }} colorVariant='variant'>{t('client:supportingDocuments')}</Typography>
            </>
          )}
          { primaryImage && doc === 'primary' && (
            <Attachment fileDocumentId={primaryImage?.id} onDelete={() => setPrimaryImage(null)}/>
          )}
          { secondaryImage && doc === 'secondary' && (
            <Attachment fileDocumentId={secondaryImage?.id} onDelete={() => setSecondaryImage(null)} />
          )}
          <Box sx={{ mt: 1 }}>
            <DroppableFileInputAlt
              onFileChosen={(file: File) => setFile(file, true)}
              acceptedFiles={[FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.HEIC]}
              fileMaxSize={3}
              hide={primaryImage || doc === 'secondary'}
            />
            <DroppableFileInputAlt
              onFileChosen={(file: File) => setFile(file, false)}
              acceptedFiles={[FileTypes.JPEG, FileTypes.JPG, FileTypes.PNG, FileTypes.HEIC]}
              fileMaxSize={3}
              hide={secondaryImage || doc === 'primary'}
            />
          </Box>
        </DialogContent>
        <DialogFooter>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type='submit' label={activeId.id ? t('shared:update') : t('shared:create')} disabled={loading} />
          </Box>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};
