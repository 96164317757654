import { useContext, useEffect, useState } from 'react';
import {
  gql, useApolloClient, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { IncompleteFormAgreement } from 'interfaces';
import { useGlobalToast } from 'providers/globalToastProvider';
import hash_sum from 'hash-sum';
import { FileDocumentObjectTypes } from 'interfaces/fileDocument';
import { fetchAuthorizedIndividual } from 'util/fetchAuthorizedIndividual';
import { UserContext } from 'providers/userContextProvider';
import { DocumentsVisual, SignableDocumentState, UploadableDocumentState } from './documents.visual';
import { TransferContext } from '../../../depositWorkflow/depositWorkflow';
import { WorkflowCompletionContext } from '../../workflowCompletion.visual';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { uploadWithRetry } from '../../../../../util/fileUploader';

const FETCH_USER = gql`
  query fetchUserIncompleteFormAgreements($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        allIncompleteFormAgreements {
          id
          account { id }
          subAccount { id }
          goal { id }
          user { id }
          scheduledTransfer { id state frequency subAccount { id account { type } }}
          clientGroup { id }
          translatedDisplayName { en }
          type
          templateUrl
          minVersion
          digitalSignatureEnabled
          isPrefilledPdfEnabled
        }
      }
    }
  }
`;

const SIGN_FORM_AGREEMENT = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
      }
    }
  }
`;

const PREVIEW_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) {
      html
    }
  }
`;

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input: FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
    }
  }
`;

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input: CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

const FETCH_PRE_FILLED_PDF_URL = gql`
  query previewFilledPdf($input: PreviewFilledPdfInput!) {
    previewFilledPdf(input: $input) {
      url
    }
  }
`;

const relatedFilterAttribute = (document: IncompleteFormAgreement) => {
  if (document.account?.id) {
    return { accountId: document.account?.id };
  }
  if (document.subAccount?.id) {
    return { subAccountId: document.subAccount?.id };
  }
  if (document.user?.id) {
    return { userId: document.user?.id };
  }
  if (document.goal?.id) {
    return { goalId: document.goal?.id };
  }
  return { scheduledTransferId: document.scheduledTransfer?.id };
};

export const Documents = ({
  options, userId, onNext, stepLoading, workflowCompletion,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [signableDocuments, setSignableDocuments] = useState<SignableDocumentState[]>([]);
  const [uploadableDocuments, setUploadableDocuments] = useState<UploadableDocumentState[]>([]);
  const [signableDocumentIndex, setSignableDocumentIndex] = useState<number | undefined>();
  const [uploadableDocumentIndex, setUploadableDocumentIndex] = useState<number | undefined>();
  const [html, setHtml] = useState<any>('');
  const { userContext, activeEntity } = useContext(UserContext);
  const { scrollWorkflowDialog } = useContext(WorkflowCompletionContext);
  const graphqlClient = useApolloClient();
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const { userId: uId } = useParams<{ id: string, objectId?: string, userId?: string }>();

  const [fetchFileUploadUrl, { loading: uploadUrlLoading }] = useLazyQuery(FETCH_FILE_UPLOAD_URL, { fetchPolicy: 'no-cache' });
  const [uploadPending, setUploadPending] = useState(false);
  const [createFileDocument, { loading: createFilePending }] = useMutation(CREATE_FILE_DOCUMENT);
  const [fetchPreFilledPdfURL, { loading: downloadUrlLoading }] = useLazyQuery(FETCH_PRE_FILLED_PDF_URL, { fetchPolicy: 'no-cache' });

  const { transferData } = useContext(TransferContext);

  if (transferData?.skipDocuments) {
    onNext();
  }

  const getPreFilledPdfUrl = async (doc: IncompleteFormAgreement) => {
    const result = await fetchPreFilledPdfURL({
      variables: {
        input: {
          type: doc.type,
          accountId: doc.account?.id ?? undefined,
          subAccountId: doc.subAccount?.id ?? undefined,
          userId: doc.user?.id ?? undefined,
          goalId: doc.goal?.id ?? undefined,
          scheduledTransferId: doc.scheduledTransfer?.id ?? undefined,
          clientGroupId: doc?.clientGroup?.id ?? undefined,
        },
      },
    });
    const url = result?.data?.previewFilledPdf?.url;
    return url;
  };

  const downloadPreFilledPdf = async (doc: IncompleteFormAgreement) => {
    const url = await getPreFilledPdfUrl(doc);
    if (url) {
      window.open(url, '_blank');
    }
  };

  const { showToast } = useGlobalToast();

  const { loading: incompleteAgreementsLoading } = useQuery(FETCH_USER, {
    variables: { userId: uId || activeEntity?.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.fetchUser.user.allIncompleteFormAgreements) {
        setSignableDocuments(data.fetchUser.user.allIncompleteFormAgreements
          .filter((x: IncompleteFormAgreement) => x.digitalSignatureEnabled)
          .map((x: IncompleteFormAgreement): SignableDocumentState => ({
            signed: false,
            isPrefilledPdfEnabled: x.isPrefilledPdfEnabled,
            ...x,
            id: hash_sum(x), // need unique ID across more incompleteFormAgreements of the same type
          })));

        setUploadableDocuments(data.fetchUser.user.allIncompleteFormAgreements
          .filter((x: IncompleteFormAgreement) => !x.digitalSignatureEnabled)
          .map((x: IncompleteFormAgreement): UploadableDocumentState => ({
            uploaded: false,
            isPrefilledPdfEnabled: x.isPrefilledPdfEnabled,
            ...x,
          })));
      }
    },
  });

  const [preview, { loading: previewLoading }] = useLazyQuery(PREVIEW_AGREEMENT, {
    onCompleted: ((d: any) => {
      setHtml(d.previewFormAgreement.html);
    }),
  });

  const [signFormAgreement, { loading: signFormAgreementPending }] = useMutation(SIGN_FORM_AGREEMENT);

  const signAll = async () => {
    const unsignedDocuments = signableDocuments.filter((y) => !y.signed);

    await Promise.all(unsignedDocuments.map(doSignDocument));
  };

  const doSignDocument = async (doc: SignableDocumentState) => {
    setSignableDocuments((prevSignableDocuments) => prevSignableDocuments.map((x) => (x.id === doc.id ? { ...x, signed: true } : x)));
    let responsibleUserId = null;
    if (userId) {
      responsibleUserId = await fetchAuthorizedIndividual({
        userId,
        apolloClient: graphqlClient,
        userContext,
      });
    }
    await signFormAgreement({
      variables: {
        input: {
          ...relatedFilterAttribute(doc),
          formAgreements: [
            {
              type: doc.type,
              version: doc.minVersion,
            },
          ],
          userId,
          ...(responsibleUserId ? { signedByUserId: responsibleUserId } : {}),
        },
      },
    });
  };

  const downloadTemplatePDF = (type: 'signable' | 'uploadable') => {
    if (type === 'uploadable' && !isNil(uploadableDocumentIndex)) {
      downloadPreFilledPdf(uploadableDocuments[uploadableDocumentIndex]);
    } else if (type === 'signable' && !isNil(signableDocumentIndex)) {
      downloadPreFilledPdf(signableDocuments[signableDocumentIndex]);
    }
  };

  const doTheUpload = async (file: File, name: string) => {
    if (uploadableDocumentIndex === undefined) return;
    const documentIndex = uploadableDocumentIndex;

    setUploadableDocumentIndex(undefined);

    setUploadableDocuments(uploadableDocuments.map((x: UploadableDocumentState, i: number) => (i === documentIndex ? { ...x, pending: true } : x)));
    let associatedToObjectType = FileDocumentObjectTypes.USER;
    let associatedToObjectID = userId;

    if (uploadableDocuments[documentIndex].account?.id) {
      associatedToObjectType = FileDocumentObjectTypes.ACCOUNT;
      associatedToObjectID = uploadableDocuments[documentIndex].account?.id ?? '';
    }

    const createFileInput: any = {
      objectType: associatedToObjectType,
      objectId: associatedToObjectID,
      fileName: file.name,
      type: 'FORM',
    };

    /* (1) fetch the S3 upload URL from backend */
    const queryResult = await fetchFileUploadUrl({ variables: { input: { ...createFileInput, userId } } });
    const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
    if (!uploadUrl || queryResult?.error) {
      showToast({ severity: 'error', message: `Upload failed, step 1 of 3 (FETCH_FILE_UPLOAD_URL) ${queryResult?.error}` });
      return;
    }

    /* (2) do the upload */
    try {
      setUploadPending(true);
      const uploaded: Response = await uploadWithRetry(uploadUrl, file, 'FORM');
      if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
    } catch (e: any) {
      showToast({ severity: 'error', message: `Upload failed, step 2 of 3 (HTTP PUT) ${e}` });
      return;
    } finally {
      setUploadPending(false);
    }

    /* (3) create the fileDocument within backend */
    createFileInput.name = name;
    createFileInput.mediaType = file.type;
    createFileInput.permissionType = 'ORGANIZATIONAL';
    createFileInput.sourceType = uploadableDocuments[documentIndex].type;

    try {
      await createFileDocument({
        variables: { input: createFileInput },
        onCompleted: () => {
        },
      });
    } catch (error) {
      showToast({ severity: 'error', message: `Upload failed, step 3 of 3 (CREATE_FILE_DOCUMENT) ${error}` });
      return;
    }

    showToast({ severity: 'success', message: `Document uploaded successfully: ${file.name}` });

    setUploadableDocuments(uploadableDocuments.map((x: any, i: number) => (i === documentIndex ? { ...x, pending: false, uploaded: true } : x)));
  };

  useEffect(() => {
    if (signableDocumentIndex !== undefined) {
      const doc = signableDocuments[signableDocumentIndex];
      if (doc.isPrefilledPdfEnabled) {
        getPreFilledPdfUrl(doc).then((url) => {
          if (url) {
            setPdfUrl(url);
          }
        });
      } else {
        preview({
          variables: {
            input: {
              ...relatedFilterAttribute(signableDocuments[signableDocumentIndex]),
              type: signableDocuments[signableDocumentIndex].type,
            },
          },
        });
      }
    }
    if (signableDocumentIndex === undefined) {
      setHtml('');
      setPdfUrl('');
    }
    // eslint-disable-next-line
  }, [signableDocumentIndex, signableDocuments, preview]);

  const viewNext = () => {
    if (signableDocumentIndex === undefined) return;
    if (signableDocumentIndex + 1 !== signableDocuments.length) {
      setSignableDocumentIndex(signableDocumentIndex + 1);
    }
  };

  return (
    <DocumentsVisual
      options={options}
      signableDocuments={signableDocuments}
      uploadableDocuments={uploadableDocuments}
      signAgreement={() => {
        scrollWorkflowDialog();
        if (signableDocumentIndex === undefined) return;
        if (options.requireSigning) {
          doSignDocument(signableDocuments[signableDocumentIndex]);
        }

        viewNext();
      }}
      downloadTemplatePDF={downloadTemplatePDF}
      doTheUpload={doTheUpload}
      uploadInProgress={uploadUrlLoading || uploadPending || createFilePending}
      signFormAgreementPending={signFormAgreementPending}
      viewNext={viewNext}
      signAll={signAll}
      continueFunc={() => {
        sendAnalytic(
          ovAnalyticsEvents.workflowsDocumentsContinueButtonSelect,
          {
            workflowStepTitle: options?.title,
            workflowId: workflowCompletion?.workflow?.id,
            workflowName: workflowCompletion?.workflow?.name,
            activeWorkflowCompletionId: workflowCompletion?.id,
            objectId: workflowCompletion?.objectId,
            objectType: workflowCompletion?.objectType,
          },
        );
        onNext();
      }}
      loading={incompleteAgreementsLoading || signFormAgreementPending || stepLoading}
      signableDocumentIndex={signableDocumentIndex}
      setSignableDocumentIndex={setSignableDocumentIndex}
      uploadableDocumentIndex={uploadableDocumentIndex}
      setUploadableDocumentIndex={setUploadableDocumentIndex}
      html={html}
      pdfUrl={pdfUrl}
      previewLoading={previewLoading || downloadUrlLoading}
    />
  );
};

export default Documents;
