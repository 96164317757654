/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import { gql, useQuery } from '@apollo/client';
import {
  Button, Typography, Form, SelectionTile, Box, Link,
} from '../../../..';
import { TransferContext } from '../../../depositWorkflow/depositWorkflow';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { UserContext } from '../../../../../providers/userContextProvider';

const FETCH_USER_FOR_PERSONA = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        physicalAddress {
          province
        }
        iDVerified
        iDCheckCompleted
      }
    }
  }
`;

export const DepositType = ({
  options, onNext, direction,
}: {
  options: any, onNext: any, direction: 'FORWARD' | 'BACKWARD',
}) => {
  const { t } = useTranslation('client');
  const { transferData, setTransferData } = useContext(TransferContext);
  const { activeEntity, userContext } = useContext(UserContext);
  const { sys } = useThemeTokens();

  const { data, loading } = useQuery(FETCH_USER_FOR_PERSONA, {
    skip: !activeEntity?.id,
    variables: { userId: activeEntity?.id },
    fetchPolicy: 'no-cache',
  });

  const showDepositBlockedBannerButton = () => {
    if (loading || !data?.fetchUser) return false;
    return data.fetchUser?.user?.iDVerified === false;
  };

  const resetEFTFields = () => {
    setTransferData({
      ...transferData,
      schedule: 'ONE_TIME',
      amountCents: 0,
      bankAccount: {},
      subAccount: {},
    });
  };
  const resetInternalTransferFields = () => {
    setTransferData({
      ...transferData,
      fromSubAccount: {},
      amountCents: 0,
      subAccount: {},
    });
  };
  const resetExternalTransferFields = () => {
    setTransferData({
      ...transferData,
      amountCents: 0,
      institution: {},
      subAccount: {},
      accountNumber: '',
      accountType: '',
      transferType: 'ALL_IN_CASH',
    });
  };

  useEffect(() => {
    if (direction === 'BACKWARD') {
      if (transferData.type === 'EFT') {
        resetEFTFields();
      } else if (transferData.type === 'INTERNAL_TRANSFER') {
        resetInternalTransferFields();
      } else {
        resetExternalTransferFields();
      }
    }
  }, [direction]);

  const submit = () => {
    if (transferData.type && !showDepositBlockedBannerButton()) {
      onNext();
    }
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <SelectionTile
        onChange={(e: any) => setTransferData({ ...transferData, type: e.target.value })}
        value={transferData.type}
        options={options?.options?.map((option: any) => (
          { value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description) }
        ))}
      />
      {
          showDepositBlockedBannerButton() && (
          <Box mt={sys.spacing.xl} bgcolor={sys.color.negativeVariant} padding={sys.spacing.xl} marginRight={sys.spacing.xl} borderRadius={sys.borderRadius.md}>
            <Typography variant="bodySmall" sx={{ color: sys.color.negativeOutline, textAlign: 'justify' }}>
              {t('transfer:verifyIdentity.1')}
              <Link
                target="_blank"
                sx={{ color: sys.color.negativeOutline, textDecorationColor: sys.color.negativeOutline }}
                href={userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new'}>
                {t('transfer:verifyIdentity.2')}
              </Link>
              {t('transfer:verifyIdentity.3')}
            </Typography>
          </Box>
          )
      }
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-form-continue-button' disabled={loading} label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default DepositType;
