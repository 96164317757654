import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, Typography, Box, ActionContext,
} from '../../../..';
import { TransferContext } from '../../../withdrawWorkflow/withdrawWorkflow';
import { getSubAccountName } from '../../../../../interfaces';
import { formatMoneyValue } from '../../../../../util';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import {
  CREATE_SCHEDULED_WITHDRAW_TRANSFER,
  CREATE_WITHDRAW_ALL_TRANSFER,
  CREATE_WITHDRAW_TRANSFER,
} from './withdrawReview.queries';

export const WithdrawReview = ({
  options, onNext,
}: {
  options: any, onNext: any,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'transfer']);
  const { transferData } = useContext(TransferContext);
  const { refetch, refreshWidgets } = useContext(ActionContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { showToast } = useGlobalToast();

  const [createWithdrawTransfer] = useMutation(CREATE_WITHDRAW_TRANSFER);
  const [createScheduledWithdrawTransfer] = useMutation(CREATE_SCHEDULED_WITHDRAW_TRANSFER);
  const [createWithdrawAllTransfer] = useMutation(CREATE_WITHDRAW_ALL_TRANSFER);

  const fullTransfer = () => transferData?.subAccount?.statistics?.marketValueCents && transferData.amountCents > (transferData?.subAccount?.statistics?.marketValueCents || 0) * 0.97;

  const submit = () => {
    setLoading(true);
    if (transferData.frequency !== 'ONE_TIME') {
      createScheduledWithdrawTransfer({
        variables: {
          input: {
            bankAccountId: transferData.bankAccount.id,
            subAccountId: transferData.subAccount.id,
            amountCents: transferData.amountCents,
            frequency: transferData.frequency,
            scheduledDate: transferData.scheduledDate,
            withdrawalReason: transferData.withdrawalReason,
          },
        },
        onCompleted: () => {
          onNext();
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      }).then();
    } else if (fullTransfer()) {
      createWithdrawAllTransfer({
        variables: {
          input: {
            bankAccountId: transferData.bankAccount.id,
            subAccountId: transferData.subAccount.id,
          },
        },
        onCompleted: () => {
          onNext();
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      });
    } else {
      createWithdrawTransfer({
        variables: {
          input: {
            amountCents: transferData.amountCents,
            bankAccountId: transferData.bankAccount.id,
            subAccountId: transferData.subAccount.id,
            withdrawalReason: transferData.withdrawalReason,
          },
        },
        onCompleted: () => {
          onNext();
          refetch();
          if (refreshWidgets) refreshWidgets();
          setLoading(false);
          showToast({
            message: t('depositReview.withdrawSuccess'),
            severity: 'success',
          });
        },
      });
    }
  };

  return (
    <>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.fromSubAccount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{getSubAccountName(transferData.subAccount)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.toBankAccount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{`${transferData.bankAccount.name} ${transferData.bankAccount.bankAccountNumber}`}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.amount')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{fullTransfer() ? t('depositReview.fullTransfer') : formatMoneyValue(transferData.amountCents)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:withdrawReview.frequency')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`transfer:frequencyOption.${transferData.frequency}`)}</Typography>
      </Box>
      {
        transferData.frequency !== 'ONE_TIME' && (
          <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
            <Typography variant='bodyLarge'>{t('workflowCompletions:withdrawReview.startsOn')}</Typography>
            <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{transferData.scheduledDate}</Typography>
          </Box>
        )
      }
      <Box sx={{ mb: 2 }} display='flex' justifyContent='space-between'>
        <Typography variant='bodyLarge'>{t('workflowCompletions:depositReview.reason')}</Typography>
        <Typography variant='bodyLarge' weight='bold' sx={{ textAlign: 'right' }}>{t(`transfer:withdrawal.withdrawalReasonOptions.${transferData.withdrawalReason}`)}</Typography>
      </Box>
      <Box display='flex' justifyContent='end'>
        <Button label={t('workflowCompletions:depositReview.createWithdraw')} sx={{ mt: 3, textAlign: 'center' }} disabled={loading} onClick={submit} />
      </Box>
    </>
  );
};

export default WithdrawReview;
