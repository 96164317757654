import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import {
  Button, Dialog, DialogContent, DialogTitle, Form, IconButton, Switch, TextField,
  TranslatableTextField,
} from '../../../2-component';
import { Box, Link, Typography } from '../../../1-primative';
import { ClientPageInterface, TabInterface } from '../pageConfiguration';
import { getBackendLanguage } from '../../../../assets/i18n/config';
import { TranslatedLanguage } from '../../../../interfaces';
import { validateUrl } from '../../../../util';

export const NewTab = ({ page, setPage }: { page: ClientPageInterface, setPage: (page: any) => void }) => {
  const { t } = useTranslation('pageConfiguration');
  const [open, setOpen] = useState<boolean>(false);
  const [enableLink, setEnableLink] = useState<boolean>(false);
  const [linkError, setLinkError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const emptyTab: TabInterface = {
    label: { en: '', fr: '' },
    icon: '',
    url: '',
    widgets: [],
  };
  const [tab, setTab] = useState<TabInterface>(emptyTab);

  const validateFields = () => {
    let allValid = true;
    if (getBackendLanguage() === TranslatedLanguage.fr && !tab.label.fr) {
      allValid = false;
      setError(true);
    } else {
      setError(false);
    }

    if (getBackendLanguage() === TranslatedLanguage.en && !tab.label.en) {
      allValid = false;
      setError(true);
    } else {
      setError(false);
    }

    if (enableLink) {
      const isUrlValid = validateUrl(tab.url ?? '');
      if (!isUrlValid) {
        allValid = false;
        setLinkError(true);
      } else {
        setLinkError(false);
      }
    }
    return allValid;
  };

  const savePage = () => {
    const uPage = { ...page };
    tab.label.en = tab.label.en?.trim();
    tab.label.fr = tab.label.en?.trim();
    uPage.tabs = [...uPage.tabs, tab];
    setPage(uPage);
    setError(false);
    setOpen(false);
    setTab(emptyTab);
    setLinkError(false);
    setEnableLink(false);
  };

  const onSubmit = () => {
    if (validateFields()) savePage();
  };

  const onClose = () => {
    setOpen(false);
    setTab(emptyTab);
    setError(false);
    setLinkError(false);
    setEnableLink(false);
  };

  const isValid: boolean = /^(?:[a-z]+(?:_[a-z]+)*)?$/.test(tab.icon);
  const disabled: boolean = !tab.label || (!!tab.icon && !isValid);

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <IconButton onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
        <DialogTitle>
          <Typography variant='headingMedium' sx={{ mr: '4px' }}>{t('addTab')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={onSubmit}>
            <TranslatableTextField
              label={t('label')}
              fullWidth
              error={error}
              value={tab.label}
              onChange={(value) => {
                setTab({ ...tab, label: value });
                if (getBackendLanguage() === TranslatedLanguage.fr) {
                  value.fr ? setError(false) : setError(true);
                } else {
                  value.en ? setError(false) : setError(true);
                }
              }} />
            <TextField
              fullWidth
              label={t('icon')}
              value={tab.icon}
              sx={{ mt: 3 }}
              error={!isValid}
              errorText={t('error.invalidIconErrorText')}
              onChange={(e: any) => setTab({ ...tab, icon: e.target.value })}
              placeholder='account_balance'
            />
            <Typography variant='labelSmall'>
              <Link href='https://mui.com/material-ui/material-icons/' target='_blank'>{t('listOfIcons')}</Link>
            </Typography>
            <Switch
              label={t('addLinkTab.switch')}
              checked={enableLink}
              onChange={(onoff) => {
                setEnableLink(onoff);
                setLinkError(false);
              }}
              sx={{ mt: 3 }}
            />
            {enableLink && (
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                label={t('addLinkTab.label')}
                value={tab.url}
                onChange={(e: any) => setTab({ ...tab, url: e.target.value })}
                error={linkError}
                errorText={t('addLinkTab.error')}
              />
            )}
            <Box display='flex' flexDirection='row' justifyContent='end'>
              <Button type='submit' disabled={disabled} label={t('add')} sx={{ mt: 3 }} />
            </Box>
          </Form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
