import { t } from 'i18next';
import { TextField } from '../../2-component';

interface NumberFieldProps {
  number: string,
  setNumber: (number: string) => void,
  label?: string,
  onBlur?: any,
  error?: any,
  sx?: any,
  disabled?: boolean
  locked?: boolean
  infoTooltip?: string,
  decimalPlaces?: number,
}
export const NumberField = ({
  number, setNumber, label, onBlur, error, sx, infoTooltip, disabled, locked,
}: NumberFieldProps) => (
  <TextField
    onChange={(e: any) => setNumber(e.target.value)}
    label={label ?? t('components:number')}
    fullWidth
    lockMessage={t('pageConfiguration:notEditableMessage')}
    disabled={disabled}
    locked={locked}
    value={number}
    placeholder="0"
    type="number"
    onBlur={onBlur}
    error={error}
    sx={sx}
    infoTooltip={infoTooltip}
  />
);
