import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, ListItem, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DroppableFileInputAlt from 'ovComponents/2-component/droppableFileInputAlt/droppableFileInputAlt';
import { translateBackend } from 'assets/i18n/config';
import {
  Button, TextField, Typography, Form,
  SelectField, DateField, Box, Autocomplete,
} from '../../../..';
import { UploadedFile } from '../../../../../components/wizards/createIdVerificationWizard/steps/uploadedFile';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { IdVerification, useCountrySpecificInformationSources } from '../../../../../interfaces/IdVerification';

const ID_VERIFICATION_METHODS = ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'DUAL_METHOD', 'IN_PERSON_VALIDATION'];

const getInvalidFieldsFromIdData = (idData: IdVerification) => {
  const invalidFields: string[] = [];

  if (idData.methodOfIDVerification === 'DUAL_METHOD') {
    if (!idData.userName) {
      invalidFields.push('userName');
    }
    if (!idData.secondaryDocumentUserName) {
      invalidFields.push('secondaryDocumentUserName');
    }
    if (!idData.documentType) {
      invalidFields.push('documentType');
    }
    if (!idData.documentType) {
      invalidFields.push('secondaryDocumentType');
    }
    if (!idData.documentIssuingJurisdication) {
      invalidFields.push('documentIssuingJurisdication');
    }
    if (!idData.secondaryDocumentIssuingJurisdication) {
      invalidFields.push('secondaryDocumentIssuingJurisdication');
    }
    if (!idData.documentID) {
      invalidFields.push('documentID');
    }
    if (!idData.secondaryDocumentID) {
      invalidFields.push('secondaryDocumentID');
    }
  }

  if (idData.methodOfIDVerification && ['CREDIT_CHECK', 'DIGITAL_DOCUMENT_CHECK', 'IN_PERSON_VALIDATION'].includes(idData.methodOfIDVerification)) {
    if (!idData.userName) {
      invalidFields.push('userName');
    }
    if (!idData.documentType) {
      invalidFields.push('documentType');
    }
    if (!idData.documentID) {
      invalidFields.push('documentID');
    }
    if (!idData.documentIssuingJurisdication) {
      invalidFields.push('documentIssuingJurisdication');
    }
    if (idData.methodOfIDVerification === 'CREDIT_CHECK' && !idData.creditFileSource) {
      invalidFields.push('creditFileSource');
    }
  }

  return invalidFields;
};

export const IdVerificationVisual = ({
  options, idDocumentTypes, idData, loading, updateId, documentData,
  workflowCompletion, continueFunc, openPersona, setDocumentData,
}: {
  options: any, idDocumentTypes?: string[], idData: IdVerification & { idVerificationId?: string }, loading: boolean, updateId: any, documentData?: any,
  workflowCompletion?: any, continueFunc: () => void, openPersona: () => void, setDocumentData?: (data: any) => void,
}) => {
  const { t } = useTranslation('client');
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [focused, setFocused] = useState<string[]>([]);
  const informationSources = useCountrySpecificInformationSources();

  const submit = () => {
    if (options?.manualVerification && validate()) {
      sendAnalytic(ovAnalyticsEvents.workflowsIdVerificationContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    } else if (options?.automatedVerification) {
      sendAnalytic(ovAnalyticsEvents.workflowsIdVerificationContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
      });
      openPersona();
    }
  };

  const validate = () => {
    const invalidFields = getInvalidFieldsFromIdData(idData);
    setFocused(invalidFields);
    return invalidFields.length === 0;
  };

  const translateJurisdictionString = (option: string): string => {
    if (informationSources.includes(option)) {
      return t(`client:edit.jurisdicationOptionsForUsandCanada.${option}`);
    }
    return option;
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
          {translateBackend(options?.subtitle)}
        </ReactMarkdown>
      </Typography>

      {options?.manualVerification && (
        <>
          <ListItem>
            <Grid container>
              <Grid item xs={12}>
                <SelectField
                  onChange={(e: any) => updateId({ ...idData, methodOfIDVerification: e.target.value })}
                  disabled={loading}
                  label={t('methodOfIDVerification')}
                  fullWidth
                  sx={{ mt: 2 }}
                  value={idData.methodOfIDVerification}
                  onBlur={() => setFocused([...focused, 'methodOfIDVerification'])}
                  error={!idData.methodOfIDVerification && focused.includes('methodOfIDVerification')}
                >
                  {ID_VERIFICATION_METHODS.map((x: string) => (
                    <MenuItem key={x} value={x}>{t(`idVerifications.idVerificationMethods.${x}`)}</MenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>
          </ListItem>

          {idData.methodOfIDVerification && (
            <>
              <ListItem>
                <Typography sx={{ mt: 2 }} variant='headingSmall'>{t('documentOne')}</Typography>
              </ListItem>
              {!idData.idVerificationId && (
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        onChange={(e: any) => updateId({ ...idData, userName: e.target.value })}
                        disabled={loading}
                        label={t('idVerifications.clientNameOnDocument')}
                        fullWidth
                        value={idData.userName}
                        onBlur={() => setFocused([...focused, 'userName'])}
                        error={!idData.userName && focused.includes('userName')}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}

              <ListItem>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent='space-between'>
                      <Grid item xs={6}>
                        <SelectField
                          onChange={(e: any) => updateId({ ...idData, documentType: e.target.value })}
                          disabled={loading}
                          label={t('idVerifications.documentType')}
                          fullWidth
                          value={idData.documentType}
                          onBlur={() => setFocused([...focused, 'documentType'])}
                          error={!idData.documentType && focused.includes('documentType')}
                        >
                          {idDocumentTypes?.map((x: string) => (
                            <MenuItem key={x} value={x}>{t(`documentTypes.${x}`)}</MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete fullWidth
                          label={t('idVerifications.documentIssuingJurisdication')}
                          value={idData.documentIssuingJurisdication}
                          disabled={loading}
                          freeSolo
                          onInputChange={(_event: any, value: any) => updateId({ ...idData, documentIssuingJurisdication: value })}
                          onBlur={() => setFocused([...focused, 'documentIssuingJurisdication'])}
                          error={!idData.documentIssuingJurisdication && focused.includes('documentIssuingJurisdication')}
                          options={informationSources.map(translateJurisdictionString)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent='space-between'>
                      <Grid item xs={6}>
                        <TextField
                          onChange={(e: any) => updateId({ ...idData, documentID: e.target.value })}
                          disabled={loading}
                          label={t('idVerifications.documentID')}
                          fullWidth
                          value={idData.documentID}
                          onBlur={() => setFocused([...focused, 'documentID'])}
                          error={!idData.documentID && focused.includes('documentID')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateField
                          onChange={(date: any) => updateId({ ...idData, documentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                          disabled={loading}
                          label={t('idVerifications.expiryDate')}
                          fullWidth
                          value={idData.documentExpiryDate}
                          onBlur={() => setFocused([...focused, 'documentExpiryDate'])}
                          error={!idData.documentExpiryDate && focused.includes('documentExpiryDate')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>

              {idData.methodOfIDVerification === 'CREDIT_CHECK' && (
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        onChange={(e: any) => updateId({ ...idData, creditFileSource: e.target.value })}
                        disabled={loading}
                        label={t('idVerifications.creditFileSource')}
                        fullWidth
                        value={idData.creditFileSource}
                        onBlur={() => setFocused([...focused, 'creditFileSource'])}
                        error={!idData.creditFileSource && focused.includes('creditFileSource')}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
              {!idData.idVerificationId && (
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      {!documentData.documentOnefile && <DroppableFileInputAlt onFileChosen={(file) => setDocumentData?.({ ...documentData, documentOnefile: file })} />}
                      {documentData?.documentOnefile && (
                        <UploadedFile
                          file={documentData?.documentOnefile}
                          onDelete={() => {
                            if (setDocumentData && documentData?.documentOnefile) {
                              setDocumentData({ ...documentData, documentOnefile: undefined });
                            }
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              )}
              {idData.methodOfIDVerification === 'DUAL_METHOD' && (
                <>
                  <ListItem>
                    <Typography sx={{ mt: 2 }} variant='headingSmall'>{t('documentTwo')}</Typography>
                  </ListItem>
                  {!idData.idVerificationId && (
                    <ListItem>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            onChange={(e: any) => updateId({ ...idData, secondaryDocumentUserName: e.target.value })}
                            disabled={loading}
                            label={t('idVerifications.clientNameOnDocument')}
                            fullWidth
                            value={idData.secondaryDocumentUserName}
                            onBlur={() => setFocused([...focused, 'secondaryDocumentUserName'])}
                            error={!idData.secondaryDocumentUserName && focused.includes('secondaryDocumentUserName')}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}

                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent='space-between'>
                          <Grid item xs={6}>
                            <SelectField
                              onChange={(e: any) => updateId({ ...idData, secondaryDocumentType: e.target.value })}
                              disabled={loading}
                              label={t('idVerifications.secondaryDocumentType')}
                              fullWidth
                              value={idData.secondaryDocumentType}
                              onBlur={() => setFocused([...focused, 'secondaryDocumentType'])}
                              error={!idData.secondaryDocumentType && focused.includes('secondaryDocumentType')}
                            >
                              {idDocumentTypes?.map((x: string) => (
                                <MenuItem key={x} value={x}>{t(`documentTypes.${x}`)}</MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              onChange={(e: any) => updateId({ ...idData, secondaryDocumentIssuingJurisdication: e.target.value })}
                              disabled={loading}
                              label={t('idVerifications.secondaryDocumentIssuingJurisdication')}
                              fullWidth
                              value={idData.secondaryDocumentIssuingJurisdication}
                              onBlur={() => setFocused([...focused, 'secondaryDocumentIssuingJurisdication'])}
                              error={!idData.secondaryDocumentIssuingJurisdication && focused.includes('secondaryDocumentIssuingJurisdication')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>

                  <ListItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent='space-between'>
                          <Grid item xs={6}>
                            <TextField
                              onChange={(e: any) => updateId({ ...idData, secondaryDocumentID: e.target.value })}
                              disabled={loading}
                              label={t('idVerifications.secondaryDocumentID')}
                              fullWidth
                              value={idData.secondaryDocumentID}
                              onBlur={() => setFocused([...focused, 'secondaryDocumentID'])}
                              error={!idData.secondaryDocumentID && focused.includes('secondaryDocumentID')}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DateField
                              onChange={(date: any) => updateId({ ...idData, secondaryDocumentExpiryDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                              disabled={loading}
                              label={t('idVerifications.secondaryDocumentExpiryDate')}
                              fullWidth
                              value={idData.secondaryDocumentExpiryDate}
                              onBlur={() => setFocused([...focused, 'secondaryDocumentExpiryDate'])}
                              error={!idData.secondaryDocumentExpiryDate && focused.includes('secondaryDocumentExpiryDate')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>

                  {!idData.idVerificationId && (
                    <ListItem>
                      <Grid container>
                        <Grid item xs={12}>
                          {!documentData?.documentTwofile && <DroppableFileInputAlt onFileChosen={(file) => setDocumentData?.({ ...documentData, documentTwofile: file })} />}
                          {documentData?.documentTwofile && (
                            <UploadedFile
                              file={documentData?.documentTwofile}
                              onDelete={() => {
                                if (setDocumentData && documentData?.documentTwofile) {
                                  setDocumentData({ ...documentData, documentTwofile: undefined });
                                }
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                  )}
                </>
              )}
              {idData.idVerificationId && idData?.verifiedDate && (
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <DateField
                        onChange={(date: any) => updateId({ ...idData, verifiedDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                        disabled={loading}
                        label={t('verifiedDate')}
                        fullWidth

                        value={idData.verifiedDate}
                        onBlur={() => setFocused([...focused, 'verifiedDate'])}
                        error={!idData.verifiedDate && focused.includes('verifiedDate')}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </>
          )}
        </>
      )}

      {!options?.manualVerification && options?.automatedVerification && (
        <Typography variant='bodyLarge' component='div' sx={{ mb: 3, table: { width: '100%' } }}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{translateBackend(options?.automatedVerificationText)}</ReactMarkdown>
        </Typography>
      )}
      <Box display='flex' justifyContent='end'>
        <Button label={t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default IdVerificationVisual;
