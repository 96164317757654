import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import {
  Badge, Box, Button, Card, CardContent, IconButton, MenuItem, SelectField, TextField, Typography, colors,
} from '../../..';
import { EditSubStepDialog } from './editSubStepDialog';
import { reorder } from '../utils';
import BuildEventTriggerRules from './buildEventTriggerRules';

export const STEP_TYPES = [
  'PERSONAL_INFORMATION',
  'NON_INDIVIDUAL_INFORMATION',
  'NON_INDIVIDUAL_SETUP',
  'RELATIONSHIP_INFORMATION',
  'EMPLOYMENT_INFORMATION',
  'RESIDENCY_INFORMATION',
  'PERSONS_OF_INTEREST',
  'INCOME_ASSETS_AND_DEBTS',
  'INVESTMENT_KNOWLEDGE',
  'RISK_QUESTION_1',
  'RISK_QUESTION_2',
  'ID_VERIFICATION',
  'APPROVAL',
  'DOCUMENTS',
  'ACCOUNTS',
  'GOALS',
  'SUB_ACCOUNTS',
  'TRANSFERS',
  'HOUSEHOLD',
  'RELATED_ENTITIES',
  'ACTION_UPDATE_USER',
  'ACTION_ACTIVATE_ALL_SUB_ACCOUNTS',
  'ACTION_NOTIFY',
  'CREATE_GOAL',
  'EDIT_GOAL',
  'REVIEW_GOAL',
  'GOAL_RISK_QUESTION_1',
  'CREATE_ACCOUNT_FOR_GOAL',
  'EDIT_ACCOUNT',
  'REVIEW_ACCOUNT',
  'ASSIGN_PORTFOLIO_TO_SUB_ACCOUNT',
  'FINANCIAL_PROJECTIONS',
  'REVIEW',
  'REVIEW_PORTFOLIO_CHANGES',
  'BANK_ACCOUNT_INFORMATION',
  'REVIEW_BANK_ACCOUNT_INFORMATION',
  'RISK_PROFILE',
];

const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: '16px',
  margin: '0 0 8px 0',
  background: 'white',
  borderRadius: '4px',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? colors.primary300 : colors.neutral300,
  padding: '8px',
  width: 250,
  borderRadius: '4px',
});

export const BasicWorkflowEditor = ({
  columns, setColumns, workflow, setWorkflow, update, eventTypes, handleMenuClick,
}: {
  columns: any[], setColumns: (c: any) => void, workflow: any, setWorkflow: (w: any) => void, update: () => void, eventTypes: any, handleMenuClick: (e: any) => any,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'orgSettings']);
  const [open, setOpen] = useState<boolean>(false);
  const [activeSubStep, setActiveSubStep] = useState<any>(null);
  const [localClickIndicator, setLocalClickIndicator] = useState<number>(0);

  const onDragEnd = (result: any) => {
    const newColumns = reorder(result, columns);
    setColumns(newColumns);
  };

  const updateStep = (updatedStep: any) => {
    const newColumns = columns.map((column: any) => {
      const newSubSteps = column.subSteps.map((subStep: any) => {
        if (subStep.id === updatedStep.id) {
          return updatedStep;
        }
        return subStep;
      });

      return { ...column, subSteps: newSubSteps };
    });
    setColumns(newColumns);
  };

  const removeStep = () => {
    const newColumns = columns.map((column: any) => {
      const newSubSteps = column.subSteps.filter((subStep: any) => subStep.id !== activeSubStep.id);
      return { ...column, subSteps: newSubSteps };
    });
    setColumns(newColumns);
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Box display='flex' flexDirection='row' alignItems='end' gap={2}>
              <TextField label={t('workflowName')} value={workflow.name?.en ?? ''} onChange={(e: any) => {
                setWorkflow({ ...workflow, name: { en: e.target.value, fr: e.target.value } });
              }} />
              <TextField
                label={t('workflowState')}
                value={t(`orgSettings:workflowStates.${workflow.state}`)}
                InputProps={{ readOnly: true }}
              />
              <SelectField label={t('trigger')} value={workflow.triggerType || ''} onChange={(e: any) => {
                setWorkflow({ ...workflow, triggerType: e.target.value });
              }}>
                <MenuItem value='MANUAL'>{t('triggerType.MANUAL')}</MenuItem>
                <MenuItem value='EVENT'>{t('triggerType.EVENT')}</MenuItem>
              </SelectField>
              {workflow.triggerType === 'EVENT' && (
                <SelectField label={t('event')} value={workflow.eventType || ''} onChange={(e: any) => {
                  setWorkflow({ ...workflow, eventType: e.target.value });
                }}>
                  {eventTypes.USER.map((type: string) => (
                    <MenuItem key={type} value={type}>{t(`eventType.${type}`)}</MenuItem>
                  ))}
                </SelectField>
              )}
              <Button label={t('saveWorkflow')} onClick={() => {
                update();
              }} />
            </Box>
            <IconButton
              onClick={handleMenuClick}
              title='Workflow Menu'
              aria-label='workflow-menu'
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
      {workflow.triggerType === 'EVENT' && (<BuildEventTriggerRules workflow={workflow} setWorkflow={setWorkflow} />)}
      <DragDropContext onDragEnd={onDragEnd}>
        <EditSubStepDialog
          localClickCount={localClickIndicator}
          open={open}
          setOpen={setOpen}
          activeSubStep={activeSubStep}
          updateStep={updateStep}
          removeStep={removeStep}
          organizationId={workflow?.organization?.id ?? ''}
        />
        <Box display='flex' flexDirection='row' overflow='auto' height='calc(100vh - 200px)'>
          {columns.map((column: any) => (
            <Box
              key={column.id}
              sx={{
                mr: 2,
              }}
            >
              <Droppable droppableId={column.id}>
                {(provided: DroppableProvided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <TextField fullWidth label='' value={column.name.en} sx={{ mb: 1 }} onChange={(e: any) => {
                      const newColumns = columns.map((x: any) => {
                        if (x.id === column.id) {
                          return { ...x, name: { en: e.target.value, fr: e.target.value } };
                        }
                        return x;
                      });
                      setColumns(newColumns);
                    }} />
                    {column.subSteps.map((subStep: any, index: number) => (
                      <Draggable key={subStep.id} draggableId={subStep.id} index={index}>
                        {(dragProvided: any, dragSnapshot: any) => (
                          <div
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                            style={getItemStyle(
                              dragSnapshot.isDragging,
                              dragProvided.draggableProps.style,
                            )}
                            onClick={() => {
                              setActiveSubStep(subStep);
                              setLocalClickIndicator((localClickIndicator + 1));
                              setOpen(true);
                            }}
                          >
                            <Badge label={subStep.type.replaceAll('_', ' ')} />
                            <Typography sx={{ mt: 1 }}>{subStep.options.title?.en}</Typography>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <SelectField label={t('addStep')} fullWidth value='' onChange={(e: any) => {
                      const newSubStep = {
                        id: `subStep-${Math.random()}`,
                        type: e.target.value,
                        options: {},
                      };
                      const newColumns = columns.map((x: any) => {
                        if (x.id === column.id) {
                          return { ...x, subSteps: [...x.subSteps, newSubStep] };
                        }
                        return x;
                      });
                      setColumns(newColumns);
                      setActiveSubStep(newSubStep);
                      setLocalClickIndicator((localClickIndicator + 1));
                      setOpen(true);
                    }}>
                      {STEP_TYPES.map((x: any) => (
                        <MenuItem key={x} value={x}>{x.replaceAll('_', ' ')}</MenuItem>
                      ))}
                    </SelectField>
                    <Button sx={{ mt: 1 }} variant='text' color='destructive' label={t('remove')} onClick={() => {
                      const newColumns = columns.filter((x: any) => x.id !== column.id);
                      setColumns(newColumns);
                    }} fullWidth />
                  </div>
                )}
              </Droppable>
            </Box>
          ))}
          <Box sx={{ mr: 2 }}>
            <Box sx={getListStyle(false)}>
              <Button label={t('addSection')} sx={{ width: 250 }} fullWidth onClick={() => {
                const newColumns = [...columns, {
                  id: `step-${Math.random()}`,
                  name: { en: 'New Section', fr: 'Nouvelle Section' },
                  subSteps: [],
                }];
                setColumns(newColumns);
              }} />
            </Box>
          </Box>
        </Box>
      </DragDropContext>
    </>
  );
};
