import i18n from 'assets/i18n/config';
import countriesEnglish from 'i18n-iso-countries/langs/en.json';
import countries from 'i18n-iso-countries';
import BuildWealthIcon from '../assets/images/goals/build-wealth.svg';
import RetirementIcon from '../assets/images/goals/retirement.svg';
import MajorPurchaseIcon from '../assets/images/goals/major-purchase.svg';
import BuyACarIcon from '../assets/images/goals/buy-a-car.svg';
import BuyAHouseIcon from '../assets/images/goals/buy-a-house.svg';
import EducationIcon from '../assets/images/goals/education.svg';
import VacationIcon from '../assets/images/goals/vacation.svg';
import StartABusinessIcon from '../assets/images/goals/start-a-business.svg';
import WeddingIcon from '../assets/images/goals/wedding.svg';
import ParentalLeaveIcon from '../assets/images/goals/parental-leave.svg';
import SomethingElseIcon from '../assets/images/goals/something-else.svg';
import SafetyNetIcon from '../assets/images/goals/safety-net.svg';
import OtherIcon from '../assets/images/goals/other.svg';
import { AccountTypes, Goal } from '../interfaces';

export interface Country {
  name: string,
  code: string,
}

countries.registerLocale(countriesEnglish);
const officialISOCountriesEnglish = Object.entries(countries.getNames('en', { select: 'official' })).map(([code, name]) => ({ name, code } as Country));
const initCountryList = officialISOCountriesEnglish
  .map((country: Country) => {
    if (country.code === 'TW') {
      return { code: country.code, name: country.name.replace(', Province of China', '') };
    }
    return country;
  });

const USI = initCountryList.findIndex((country) => country.code === 'US');
const US = initCountryList.splice(USI, 1)[0];
initCountryList.unshift(US);

const CAI = initCountryList.findIndex((country) => country.code === 'CA');
const CA = initCountryList.splice(CAI, 1)[0];
initCountryList.unshift(CA);

export const countryList = initCountryList;

export const getCountryName = (code: string): string => {
  const matching = countryList.find((c) => c.code === code);
  return matching ? matching.name : '';
};

export const provinces = [
  {
    key: 'AB',
    value: 'Alberta',
  },
  {
    key: 'BC',
    value: 'British Columbia',
  },
  {
    key: 'MB',
    value: 'Manitoba',
  },
  {
    key: 'NB',
    value: 'New Brunswick',
  },
  {
    key: 'NL',
    value: 'Newfoundland and Labrador',
  },
  {
    key: 'NS',
    value: 'Nova Scotia',
  },
  {
    key: 'ON',
    value: 'Ontario',
  },
  {
    key: 'PE',
    value: 'Prince Edward Island',
  },
  {
    key: 'SK',
    value: 'Saskatchewan',
  },
  {
    key: 'QC',
    value: 'Quebec',
  },
  {
    key: 'YT',
    value: 'Yukon',
  },
  {
    key: 'NT',
    value: 'Northwest Territories',
  },
  {
    key: 'NU',
    value: 'Nunavut',
  },
  {
    key: 'FEDERAL',
    value: 'Federal',
  },
];

export const applicableProvinces = [
  {
    accountType: AccountTypes.LIRA,
    availability: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'QC', 'SK'],
  },
  {
    accountType: AccountTypes.PRIF,
    availability: ['MB', 'SK'],
  },
  {
    accountType: AccountTypes.LRIF,
    availability: ['NL'],
  },
  {
    accountType: AccountTypes.LRSP,
    availability: ['FEDERAL'],
  },
  {
    accountType: AccountTypes.RLSP,
    availability: ['FEDERAL'],
  },
  {
    accountType: AccountTypes.RLIF,
    availability: ['FEDERAL'],
  },
  {
    accountType: AccountTypes.LIF,
    availability: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'QC', 'FEDERAL'],
  },
  {
    accountType: AccountTypes.RRIF,
    availability: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'QC', 'FEDERAL'],
  },
  {
    accountType: AccountTypes.RIF_SPOUSAL,
    availability: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'QC', 'FEDERAL'],
  },
];

export enum GoalTypes {
  CAR = 'CAR',
  BUILD_WEALTH = 'BUILD_WEALTH',
  RETIREMENT = 'RETIREMENT',
  MAJOR_PURCHASE = 'MAJOR_PURCHASE',
  HOUSE = 'HOUSE',
  EDUCATION = 'EDUCATION',
  VACATION = 'VACATION',
  START_A_BUSINESS = 'START_A_BUSINESS',
  WEDDING = 'WEDDING',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  SAFETY_NET = 'SAFETY_NET',
  OTHER = 'OTHER',
}

export interface GoalTypeAttributes {
  key: GoalTypes,
  iconPath: string,
  defaultTargetAmountCents?: number,
  secondaryGoalTypes?: GoalTypeAttributes[],
}

export const goalTypes: GoalTypeAttributes[] = [
  {
    key: GoalTypes.BUILD_WEALTH,
    iconPath: BuildWealthIcon,
    defaultTargetAmountCents: 5000000,
  },
  {
    key: GoalTypes.RETIREMENT,
    iconPath: RetirementIcon,
    defaultTargetAmountCents: 100000000,
  },
  {
    key: GoalTypes.MAJOR_PURCHASE,
    iconPath: MajorPurchaseIcon,
    secondaryGoalTypes: [
      {
        key: GoalTypes.CAR,
        iconPath: BuyACarIcon,
        defaultTargetAmountCents: 5000000,
      },
      {
        key: GoalTypes.HOUSE,
        iconPath: BuyAHouseIcon,
        defaultTargetAmountCents: 10000000,
      },
      {
        key: GoalTypes.EDUCATION,
        iconPath: EducationIcon,
        defaultTargetAmountCents: 3500000,
      },
      {
        key: GoalTypes.VACATION,
        iconPath: VacationIcon,
        defaultTargetAmountCents: 400000,
      },
      {
        key: GoalTypes.START_A_BUSINESS,
        iconPath: StartABusinessIcon,
        defaultTargetAmountCents: 2500000,
      },
      {
        key: GoalTypes.WEDDING,
        iconPath: WeddingIcon,
        defaultTargetAmountCents: 2500000,
      },
      {
        key: GoalTypes.PARENTAL_LEAVE,
        iconPath: ParentalLeaveIcon,
        defaultTargetAmountCents: 300000,
      },
      {
        key: GoalTypes.MAJOR_PURCHASE,
        iconPath: SomethingElseIcon,
        defaultTargetAmountCents: 1000000,
      },
    ],
  },
  {
    key: GoalTypes.SAFETY_NET,
    iconPath: SafetyNetIcon,
    defaultTargetAmountCents: 500000,
  },
  {
    key: GoalTypes.OTHER,
    iconPath: OtherIcon,
    defaultTargetAmountCents: 1000000,
  },
];

export const getGoalTitle = (goal: Partial<Goal>) => (
  // Yield a title in the following order: goalName > goalTitle > "Goal"
  goal?.name ? goal.name : (goal?.type ? i18n.t(`client:goalType.${goal?.type}`) : i18n.t('workflowCompletions:goals.goal'))
);
