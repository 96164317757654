import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../2-component';
import { Box } from '../../1-primative';
import { DepositWorkflow } from '../depositWorkflow/depositWorkflow';
import { WithdrawWorkflow } from '../withdrawWorkflow/withdrawWorkflow';
import { PageObjectType } from '../../5-page';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';

export const InitiateTransfer = ({ objectId, objectType, options = {} }: { objectId: string, objectType: PageObjectType, options?: any }) => {
  const { t } = useTranslation('pageConfiguration');
  const { sendAnalytic } = useContext(AnalyticsContext);
  const [depositOpen, setDepositOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);

  return (
    <Box display='flex' flex={{ xs: 1, md: 0 }}>
      { !options?.hideWithdraw && (
        <>
          <Button
            variant='outlined'
            dataTestId='withdraw-button'
            onClick={() => {
              if (objectType === 'INDIVIDUAL') {
                sendAnalytic(ovAnalyticsEvents.homepageWithdrawButtonSelect);
              } else if (objectType === PageObjectType.GOAL) {
                sendAnalytic(ovAnalyticsEvents.goalDetailsWithdrawButtonSelect);
              }
              setWithdrawOpen(true);
            }}
            label={t('withdraw')}
            sx={{ mr: 1, flex: 1 }}
          />
          <WithdrawWorkflow open={withdrawOpen} setOpen={setWithdrawOpen} objectId={objectId} objectType={objectType} />
        </>
      )}
      { !options?.hideDeposit && (
        <>
          <Button
            onClick={() => {
              if (objectType === 'INDIVIDUAL') {
                sendAnalytic(ovAnalyticsEvents.homepageDepositButtonSelect);
              } else {
                sendAnalytic(ovAnalyticsEvents.goalDetailsDepositButtonSelect);
              }
              setDepositOpen(true);
            }}
            label={t('deposit')}
            dataTestId='deposit-button'
            sx={{ flex: 1 }}
          />
          <DepositWorkflow open={depositOpen} setOpen={setDepositOpen} objectId={objectId} objectType={objectType} />
        </>
      )}
    </Box>
  );
};
