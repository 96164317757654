import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash/fp';
import { useNavigate } from 'react-router-dom';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import AutoRenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { useContext } from 'react';
import { useHouseholdContext } from '../../../pages/household';
import { MemberTag } from '../../../pages/household/components/memberTag';
import { useClientContext } from '../../../pages/client';
import { Typography, Box } from '../../1-primative';
import { Card, Tooltip, CardContent } from '../../2-component';
import { CurrencyFlag, CurrencyIndicator } from '../../3-pattern';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Account, AccountStates } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import { currencySymbol } from '../../../util/currency';
import { PageObjectType } from '../../5-page';
import { translateBackend } from '../../../assets/i18n/config';

const getSimplifiedState = (accountState: AccountStates) => {
  switch (accountState) {
    case AccountStates.ACTIVE:
      return 'ACTIVE';
    case AccountStates.FAILED:
    case AccountStates.INACTIVE:
    case AccountStates.CANCELED:
      return 'CLOSED';
    default:
      return 'PENDING';
  }
};

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const AccountItem = ({
  account, tag, enableTooltip = true, useCustodianData, objectType, options, showSimplifiedState,
}: {
  account: Account, tag?: Tag, objectId: string, enableTooltip?: boolean, useCustodianData?: boolean,
  objectType: PageObjectType, options?: any, showSimplifiedState?: boolean,
}) => {
  const navigate = useNavigate();
  const { sys } = useThemeTokens();
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();

  const statistics = useCustodianData ? account.custodianStatistics : account.statistics;
  // The simpleReturnAmount is actually coming in cents.
  // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
  const simpleReturnAmountCents = useCustodianData ? statistics?.simpleReturnAmountCents : statistics?.simpleReturnAmount;

  const getPercentageOfTotalAsset = (): number => {
    const totalMarketValueCents: number = objectType === PageObjectType.HOUSEHOLD ? householdContext?.totalMarketValueCents ?? 0 : clientContext?.totalMarketValueCents || 0;
    const percentage = (statistics?.marketValueCents ?? 0) / totalMarketValueCents;
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  const isInactive = account.state === AccountStates.INACTIVE;
  const subAccounts = account.subAccounts?.filter((x) => x.state !== 'INACTIVE');
  const awaitingSubAccountCount: number = account.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  const accountState = showSimplifiedState ? getSimplifiedState(account.state) : account.state;
  const numExternalHoldingsLinked = statistics?.holdings?.length ?? 0;

  return (
    <Card
      hover
      sx={{ cursor: 'pointer', backgroundColor: isInactive ? sys.color.disabled : sys.color.surface }}
      onClick={() => {
        sendAnalytic(ovAnalyticsEvents.homepageAccountSelect);
        navigate(`account/${account.id}`);
      }}
    >
      <CardContent data-testid={`account-${account?.type}`}>
        <Box display="flex" justifyContent="space-between" sx={{
          position: 'relative', top: '-4px', right: '-4px', height: '15px',
        }}>
          <Box display="flex" flexDirection="row" />
          <Box display="flex" flexDirection="row" >
            {(awaitingSubAccountCount > 0 && enableTooltip) && (
              <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
                <Box height='8px' width='8px' borderRadius='50%' bgcolor={sys.color.negative} />
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" marginTop="-15px" marginBottom="15px">
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant='titleMedium'>{t(`accountTypes:${account.type}`)}</Typography>
              <Typography variant='bodySmall' ml={0.5} colorVariant='variant'>
                {account.custodianAccountNumber ? `#${account.custodianAccountNumber}` : (
                  <>
                    <AutoRenewRoundedIcon sx={{ fontSize: '14px', verticalAlign: 'middle' }} />
                    {t(`accountsDetail:accountState.${accountState}`)}
                  </>
                )}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" mt={0.5}>
              {tag ? <MemberTag sequenceNumber={tag.sequenceNumber} name={tag.text} /> : <Box height='16.5px' width={1} />}
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="end">
            <Box display='flex' gap={1} alignItems='center'>
              {(options.displayAccountCurrency && account?.baseCurrency) && <CurrencyFlag baseCurrency={account.baseCurrency} />}
              <CurrencyIndicator statistics={statistics} useExternalStatisticsEnabled={useCustodianData} />
              <Typography variant='titleMedium'>
                {formatMoneyValue(statistics?.marketValueCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])}
              </Typography>
            </Box>
            {(isNil(options.showSimpleRateOfReturn) || options.showSimpleRateOfReturn) && (
              <Typography
                variant='bodySmall'
                color={isInactive ? sys.color.onDisabled : (simpleReturnAmountCents || 0) >= 0 ? sys.color.positive : sys.color.negative}
              >
                {`${formatMoneyValue(simpleReturnAmountCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])} (${formatPercentValue(statistics?.simpleReturnPercent ?? 0)})`}
              </Typography>
            )}
          </Box>
        </Box>

        <Box>
          <Box
            display="flex"
            justifyContent={(isNil(options.showLinkedSubAccounts) || options.showLinkedSubAccounts || options.showExternalHoldings || isNil(options.showExternalHoldings))
              ? 'space-between' : 'flex-end'} marginTop={householdContext?.orgSettings ? '48px' : '26px'}
          >
            {(options.showLinkedSubAccounts === true || isNil(options.showLinkedSubAccounts)) && (
              <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(options.linkedSubAccounts)
                  ? `${subAccounts?.length ?? 0} ${translateBackend(options.linkedSubAccounts)}`
                  : t('accountsSummary.subAccountsLinked', { count: subAccounts?.length ?? 0 })}
              </Typography>
            )}
            {(options.showExternalHoldings === true || isNil(options.showExternalHoldings)) && (
              <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
                {translateBackend(options.linkedExternalHoldings)
                  ? `${numExternalHoldingsLinked ?? 0} ${translateBackend(options.linkedExternalHoldings)}`
                  : t('accountsSummary.externalHoldingsLinked', { count: numExternalHoldingsLinked ?? 0 })}
              </Typography>
            )}
            <Typography variant='bodySmall' sx={{ color: isInactive ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
              {t('accountsSummary.ofTotalAssets', { percentage: formatPercentValue(getPercentageOfTotalAsset(), 0) })}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountItem;
