import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';
import { Goal } from '../../../../../interfaces';

interface ICreateSubAccountForAccountVisualProps {
  options: any;
  goals: Goal[];
  loading: boolean;
  selectedGoal: string | null;
  setSelectedGoal: (goal: string | null) => void;
  continueFunc: () => void;
}

export const CreateSubAccountForAccountVisual: React.FC<ICreateSubAccountForAccountVisualProps> = ({
  options, goals, loading, selectedGoal, setSelectedGoal, continueFunc,
}) => {
  const { t } = useTranslation('client');

  const submit = () => {
    if (selectedGoal) {
      continueFunc();
    }
  };

  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
      </Typography>
      <SelectionTile
        onChange={(e) => setSelectedGoal(e.target.value)}
        value={selectedGoal ?? ''}
        options={goals.map((goal) => ({ value: goal.id, label: goal.name ?? '' }))}
        error={!loading && goals.length === 0}
        errorText={t('goalsSummary.noGoals')}
      />
      <Box display='flex' justifyContent='end'>
        <Button label={t('continue')} disabled={loading || !selectedGoal} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};
