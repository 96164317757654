import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { useContext } from 'react';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const InvestmentKnowledgeVisual = ({
  options, userData, loading, updateUser, continueFunc, workflowCompletion,
}: {
  options: any, userData: any, loading: boolean, updateUser: any, continueFunc: any, workflowCompletion?: any,
}) => {
  const { t } = useTranslation('client');
  const { sendAnalytic } = useContext(AnalyticsContext);

  const submit = () => {
    const fields = invalidFields(options, userData);
    if (fields.length === 0) {
      sendAnalytic(ovAnalyticsEvents.workflowsInvestmentKnowledgeContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography variant='bodyLarge' component='div' display='inline-flex' alignItems='end' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      <SelectionTile
        data-testid="investment-knowledge"
        onChange={(e: any) => updateUser({ ...userData, investmentKnowledge: e.target.value })}
        value={userData.investmentKnowledge ?? ''}
        options={options?.options?.map((option: any) => ({
          value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description),
        }))}
      />
      <Box display='flex' justifyContent='end'>
        <Button
          data-testid="continue-btn"
          label={t('continue')}
          disabled={loading || !userData.investmentKnowledge}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default InvestmentKnowledgeVisual;
