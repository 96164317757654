import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, DateField, AddressField,
  RadioGroup, Radio, Form, Box, Grid, SelectField, MenuItem,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { UserContext } from '../../../../../providers/userContextProvider';
import { CountryName } from '../../../../../components/fields/countryName';

export const NonIndividualInformationVisual = ({
  options, userData, loading, updateUser, continueFunc, grid = false, updateMode = false, activeCustomFields,
  workflowCompletion,
}: {
  options: any, userData: any, loading: boolean, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], workflowCompletion?: any, updateUser: (user: any) => void, continueFunc: () => void,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['client', 'entityTypes', 'corporations']);
  const [focused, setFocused] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const submit = () => {
    sendAnalytic(
      ovAnalyticsEvents.workflowsNonIndividualInformationContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    const fields = invalidFields({
      ...options,
      inProvinceSince: {
        ...options.inProvinceSince,
        workflowId: workflowCompletion?.workflow.id,
        requiredIf: (data: any) => data?.physicalAddress?.province === 'QC',
      },
    }, userData);

    setFocused(fields);
    if (fields.length === 0) {
      continueFunc();
    }
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  const lockMessage = () => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      )}
      {options?.subtitle && (
        <Typography variant='bodyLarge' sx={{ mb: 3 }}>
          <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
            {translateBackend(options?.subtitle)}
          </ReactMarkdown>
        </Typography>
      )}

      <Grid container spacing={2}>
        {options?.entityName?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              onChange={(e: any) => update({ ...userData, entityName: e.target.value })}
              disabled={loading || options?.entityName?.required === 'NOT_EDITABLE'}
              locked={options?.entityName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.entityName?.label)}
              fullWidth
              value={userData.entityName ?? ''}
              onBlur={() => setFocused([...focused, 'entityName'])}
              error={!userData.entityName && focused.includes('entityName') && options?.entityName?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.type?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <SelectField
              onChange={(e: any) => updateUser({ ...userData, type: e.target.value })}
              disabled={loading || options?.type?.required === 'NOT_EDITABLE'}
              locked={options?.type?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.type?.label)}
              fullWidth
              value={userData.type ?? ''}
              onBlur={() => setFocused([...focused, 'type'])}
              error={userData.type === '' && focused.includes('type') && options?.type?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem value='INDIVIDUAL'>{t('entityTypes:INDIVIDUAL')}</MenuItem>
              <MenuItem value='INVESTMENT_FUND'>{t('entityTypes:INVESTMENT_FUND')}</MenuItem>
              <MenuItem value='PARTNERSHIP'>{t('entityTypes:PARTNERSHIP')}</MenuItem>
              <MenuItem value='PRIVATE_COMPANY'>{t('entityTypes:PRIVATE_COMPANY')}</MenuItem>
              <MenuItem value='PUBLICLY_LISTED_ENTITY'>{t('entityTypes:PUBLICLY_LISTED_ENTITY')}</MenuItem>
              <MenuItem value='REGULATED_ENTITY'>{t('entityTypes:REGULATED_ENTITY')}</MenuItem>
              <MenuItem value='SOLE_PROPRIETORSHIP'>{t('entityTypes:SOLE_PROPRIETORSHIP')}</MenuItem>
              <MenuItem value='TRUST'>{t('entityTypes:TRUST')}</MenuItem>
              <MenuItem value='OTHER'>{t('entityTypes:OTHER')}</MenuItem>
            </SelectField>
          </Grid>
        )}
        {options?.physicalAddress?.enabled && (
          <Grid item xs={12}>
            <AddressField
              onChange={(e: any) => update({ ...userData, physicalAddress: e })}
              disabled={loading || options?.physicalAddress?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.physicalAddress?.label)}
              lockMessage={lockMessage()}
              fullWidth
              address={userData.physicalAddress}
              onFocus={() => setFocused([...focused, 'physicalAddress'])}
              error={!userData.physicalAddress?.streetName && focused.includes('physicalAddress') && options?.physicalAddress?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.inProvinceSince?.enabled && userData.physicalAddress?.province === 'QC' && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              onChange={(date: any) => update({ ...userData, inProvinceSince: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.inProvinceSince?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.inProvinceSince?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.inProvinceSince}
              onBlur={() => setFocused([...focused, 'inProvinceSince'])}
              error={!userData.inProvinceSince && focused.includes('inProvinceSince') && options?.inProvinceSince?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.primaryEmail?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              onChange={(e: any) => update({ ...userData, primaryEmail: e.target.value })}
              disabled={loading || options?.primaryEmail?.required === 'NOT_EDITABLE'}
              locked={options?.primaryEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.primaryEmail?.label)}
              fullWidth
              value={userData.primaryEmail ?? ''}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={!userData.primaryEmail && focused.includes('primaryEmail') && options?.primaryEmail?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.phone?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              onChange={(e: any) => update({ ...userData, phone: e.target.value })}
              type='phone'
              disabled={loading || options?.phone?.required === 'NOT_EDITABLE'}
              locked={options?.phone?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.phone?.label)}
              fullWidth
              value={userData.phone ?? ''}
              onBlur={() => setFocused([...focused, 'phone'])}
              error={!userData.phone && focused.includes('phone') && options?.phone?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.companyType?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              onChange={(e: any) => update({ ...userData, companyType: e.target.value })}
              disabled={loading || options?.companyType?.required === 'NOT_EDITABLE'}
              locked={options?.companyType?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.companyType?.label)}
              fullWidth
              value={userData.companyType ?? ''}
              onBlur={() => setFocused([...focused, 'companyType'])}
              error={!userData.companyType && focused.includes('companyType') && options?.companyType?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.registrationNumber?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              onChange={(e: any) => update({ ...userData, registrationNumber: e.target.value })}
              disabled={loading || options?.registrationNumber?.required === 'NOT_EDITABLE'}
              locked={options?.registrationNumber?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.registrationNumber?.label)}
              fullWidth
              value={userData.registrationNumber ?? ''}
              onBlur={() => setFocused([...focused, 'registrationNumber'])}
              error={!userData.registrationNumber && focused.includes('registrationNumber') && options?.registrationNumber?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.establishedDate?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              onChange={(date: any) => update({ ...userData, establishedDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.establishedDate?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.establishedDate?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.establishedDate}
              onBlur={() => setFocused([...focused, 'establishedDate'])}
              error={!userData.establishedDate && focused.includes('establishedDate') && options?.establishedDate?.required !== 'NOT_REQUIRED'}
            />
          </Grid>
        )}
        {options?.countryOfRegistration?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <SelectField
              onChange={(e: any) => updateUser({ ...userData, countryOfRegistration: e.target.value })}
              disabled={loading || options?.countryOfRegistration?.required === 'NOT_EDITABLE'}
              locked={options?.countryOfRegistration?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              label={translateBackend(options?.countryOfRegistration?.label)}
              fullWidth
              value={userData.countryOfRegistration ?? ''}
              onBlur={() => setFocused([...focused, 'countryOfRegistration'])}
              error={userData.countryOfRegistration === '' && focused.includes('countryOfRegistration') && options?.countryOfRegistration?.required !== 'NOT_REQUIRED'}
            >
              {activeOrganization.applicableLocalization.countries.map(
                (countryCode, index) => <MenuItem key={index} value={countryCode}>
                  <CountryName code={countryCode} />
                </MenuItem>,
              )}
            </SelectField>
          </Grid>
        )}
        {options?.preferredMethodOfCommunication?.enabled && (
          <Grid item xs={12}>
            <RadioGroup
              onChange={(e: any) => {
                update({ ...userData, preferredMethodOfCommunication: e.target.value });
                setFocused([...focused, 'preferredMethodOfCommunication']);
              }}
              disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.preferredMethodOfCommunication?.label)}
              fullWidth
              value={userData.preferredMethodOfCommunication}
              onFocus={() => setFocused([...focused, 'preferredMethodOfCommunication'])}
              error={!userData.preferredMethodOfCommunication && focused.includes('preferredMethodOfCommunication') && options?.preferredMethodOfCommunication?.required !== 'NOT_REQUIRED'}
            >
              <Radio value='EMAIL' disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'} label={t('edit.preferredMethodOfCommunicationOptions.EMAIL')} />
              <Radio value='PHONE' disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'} label={t('edit.preferredMethodOfCommunicationOptions.PHONE')} />
              <Radio value='TEXT' disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'} label={t('edit.preferredMethodOfCommunicationOptions.TEXT')} />
            </RadioGroup>
          </Grid>
        )}
        {options?.language?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <RadioGroup
              onChange={(e: any) => {
                update({ ...userData, language: e.target.value });
                setFocused([...focused, 'language']);
              }}
              disabled={loading || options?.language?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.language?.label)}
              fullWidth
              value={userData.language}
              onFocus={() => setFocused([...focused, 'language'])}
              error={!userData.language && focused.includes('language') && options?.language?.required !== 'NOT_REQUIRED'}
            >
              <Radio value='ENGLISH' disabled={loading || options?.language?.required === 'NOT_EDITABLE'} label={t('edit.languageOptions.ENGLISH')} />
              <Radio value='FRENCH' disabled={loading || options?.language?.required === 'NOT_EDITABLE'} label={t('edit.languageOptions.FRENCH')} />
            </RadioGroup>
          </Grid>
        )}

        <CustomFieldRenderer
          customOptions={customOptionSelected}
          customData={userData}
          update={update}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
        />
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button
          label={t(updateMode ? 'update' : 'continue')}
          disabled={loading || (!updated && updateMode)}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default NonIndividualInformationVisual;
