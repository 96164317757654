import { t } from 'i18next';
import { TextField } from '../../2-component';
import { formatAmountValueCents } from '../../../util';

interface AmountFieldProps {
  amount: string,
  setAmount: (amount: string) => void,
  label?: string,
  onBlur?: any,
  error?: any,
  sx?: any,
  disabled?: boolean
  locked?: boolean
  infoTooltip?: string,
  errorText?: string;
  inputProps?: any;
  InputProps?: any;
  size?: any;
  fullWidth?: boolean;
  useFormatAmountValueCents?: boolean;
}
export const AmountField = ({
  amount, setAmount, label, onBlur, error, sx, infoTooltip, disabled, locked,
  errorText, inputProps, InputProps, size, fullWidth = true, useFormatAmountValueCents = false,
}: AmountFieldProps) => {
  const parseValue = (value: unknown): any => {
    if (value === undefined || value === '') {
      return '';
    }

    return useFormatAmountValueCents ? formatAmountValueCents(Number(value)) : value;
  };

  return (
    <TextField
      onChange={(e: any) => setAmount(e.target.value)}
      data-testid='amount-field'
      label={label ?? t('components:amount')}
      fullWidth={fullWidth}
      lockMessage={t('pageConfiguration:notEditableMessage')}
      disabled={disabled}
      locked={locked}
      value={parseValue(amount)}
      placeholder="0.00"
      type="dollar"
      leadingIcon='dollar'
      onBlur={onBlur}
      error={error}
      sx={sx}
      infoTooltip={infoTooltip}
      errorText={errorText}
      inputProps={inputProps}
      size={size}
      InputProps={InputProps}
    />
  );
};
