import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  DateField,
  MenuItem,
  SelectField,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';
import { BulkUpsertRequestObjectTypes, BulkUpsertRequestStates } from '../../../../interfaces/bulkUpsertRequest';

dayjs.extend(utc);

export interface BulkUpsertRequestFilter {
  state?: BulkUpsertRequestStates,
  objectType?: BulkUpsertRequestObjectTypes,
  createdAtStart?: string,
  createdAtEnd?: string,
}

const setDate = (d: any) => (d ? dayjs(d).utc().format('YYYY-MM-DD') : undefined);

export const FilterBulkUpsertRequests = ({
  filter, setFilter,
}: {
  filter: BulkUpsertRequestFilter, setFilter: (filter: BulkUpsertRequestFilter) => void,
}) => {
  const { t } = useTranslation(['devSettings', 'shared']);
  const filterExists = !!filter.state || !!filter.objectType || !!filter.createdAtStart || !!filter.createdAtEnd;
  const [filterState, setFilterState] = useState<BulkUpsertRequestFilter>(filter);

  const onConfirm = () => {
    setFilter(filterState);
  };
  const onClose = () => {
    setFilterState(filter);
  };

  return (
    <FilterModal filterExists={filterExists} onConfirm={onConfirm} onClose={onClose}>
      <SelectField
        value={filterState.state || ''}
        fullWidth
        label={t('devSettings:bulkUpsertRequest.stateLabel')}
        onChange={(e: any) => setFilterState((f) => ({ ...f, state: e.target.value || undefined }))}
        sx={{ mb: 2 }}
      >
        <MenuItem value={''}>-</MenuItem>
        {Object.keys(BulkUpsertRequestStates).map((s) => (
          <MenuItem key={s} value={s}>{t(`devSettings:bulkUpsertRequest.states.${s}`)}</MenuItem>
        ))}
      </SelectField>
      <SelectField
        value={filterState.objectType || ''}
        fullWidth
        label={t('devSettings:bulkUpsertRequest.objectTypeLabel')}
        onChange={(e: any) => setFilterState((f) => ({ ...f, objectType: e.target.value || undefined }))}
        sx={{ mb: 2 }}
      >
        <MenuItem value={''}>-</MenuItem>
        {Object.keys(BulkUpsertRequestObjectTypes).map((o) => (
          <MenuItem key={o} value={o}>{t(`devSettings:integrationsModal.scopeOptions.DATA_INGESTION.${o}`)}</MenuItem>
        ))}
      </SelectField>
      <DateField
        label={t('components:fromDate')}
        value={filterState.createdAtStart ? dayjs.utc(filterState.createdAtStart) : null}
        onChange={(e: any) => setFilterState((f) => ({ ...f, createdAtStart: setDate(e) }))}
        fullWidth
        sx={{ mb: 2 }}
      />
      <DateField
        label={t('components:toDate')}
        value={filterState.createdAtEnd ? dayjs.utc(filterState.createdAtEnd) : null}
        onChange={(e: any) => setFilterState((f) => ({ ...f, createdAtEnd: setDate(e) }))}
        fullWidth sx={{ mb: 2 }}
      />
    </FilterModal>
  );
};
