import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination, Grid,
  TextField, MenuItem,
  DialogTitle, Dialog, DialogContent, Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  useContext, useState,
} from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePageState } from '../../../util/usePageState';

export const FETCH_JOB_EVENTS_QUERY = `
query fetchEvents($input: FetchEventsInput!) {
  fetchEvents(input: $input) {
    events{
      id
      objectType
      eventType
      objectPayload
      createdAt
    }
    totalCount
  }
}`;

const FETCH_CUSTODIAN_JOBS = gql`${FETCH_JOB_EVENTS_QUERY}`;

const statusColors: { [key: string]: string } = {
  INITIATED: 'orange',
  COMPLETED: 'green',
  FAILED: 'red',
};

interface StatusChipProps {
  status: 'INITIATED' | 'COMPLETED' | 'FAILED';
}
const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const color = statusColors[status] || 'default';

  return (
    <TableCell>
      <Chip
        size="small"
        label={status}
        style={{ backgroundColor: color, color: 'white' }}
      />
    </TableCell>
  );
};

const Jobs = () => {
  const { t } = useTranslation(['reconciliation']);
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = usePageState(1, 'page');
  const [pageSize, setPageSize] = usePageState(15, 'pageSize');
  const [open, setOpen] = useState(false);
  const [activeJobEvent, setActiveJobEvent] = useState<any | null>(null);
  const [selectedJobState, setSelectedJobState] = useState('ANY');
  const [selectedEventType, setSelectedEventType] = useState('RECONCILIATION');

  const {
    loading, error, data, previousData,
  } = useQuery(FETCH_CUSTODIAN_JOBS, {
    errorPolicy: 'all',
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          objectType: 'Job',
          eventType: selectedEventType,
          payloadStatus: (selectedJobState === 'ANY') ? undefined : selectedJobState,
        },
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (error) (<Typography>Error</Typography>);

  return (
    <>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container justifyContent='flex-end' spacing={2} sx={{ p: '0 8px' }}>
            <Grid item xs={2} display='flex'>
              <TextField fullWidth select
                value={selectedJobState}
                sx={{ ml: 1 }}
                onChange={(e) => setSelectedJobState(e.target.value)}
                size='small'
                label={t('eventTable.state')}
               >
                <MenuItem value={'ANY'}>{t('jobState.ANY')}</MenuItem>
                <MenuItem value={'INITIATED'}>{t('jobState.INITIATED')}</MenuItem>
                <MenuItem value={'COMPLETED'}>{t('jobState.COMPLETED')}</MenuItem>
                <MenuItem value={'FAILURE'}>{t('jobState.FAILURE')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2} display='flex'>
              <TextField fullWidth select
                value={selectedEventType}
                sx={{ ml: 1 }}
                onChange={(e) => setSelectedEventType(e.target.value)}
                size='small'
                label={t('type')}
              >
                <MenuItem value={'RECONCILIATION'}>{t('jobEventType.RECONCILIATION')}</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Table sx={{ minWidth: 650 }} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant='overline'>{t('eventTable.eventType')}</Typography> </TableCell>
                <TableCell><Typography variant='overline'>{t('eventTable.jobName')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('eventTable.ranAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('eventTable.updatedAt')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('eventTable.state')}</Typography></TableCell>
                <TableCell><Typography variant='overline'>{t('eventTable.organization')}</Typography></TableCell>
                <TableCell align='right'><Typography variant='overline'>{t('eventTable.action')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchEvents?.events?.map((event: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setOpen(true);
                    setActiveJobEvent(event);
                  }}
                  key={event.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                  selected={activeJobEvent?.id === event.id}
                >
                  <TableCell> <Chip size='small' label={event.eventType}/></TableCell>
                  <TableCell>{event.objectPayload.jobName}</TableCell>
                  <TableCell> <DateTime variant='subtitle2' date={event.createdAt} /></TableCell>
                  <TableCell>{}</TableCell>
                  <StatusChip status={event.objectPayload.status} />
                  <TableCell>{event.objectPayload.organizationName || ''}</TableCell>
                  <TableCell align='right'>
                    <Grid container columnSpacing={1} justifyContent='flex-end'>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Grid container sx={{ paddingLeft: 1 }}>
            <Grid item xs={6}>
              <TextField select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))} size='small' label={t('perPage')} sx={{ minWidth: '150px' }}>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={250}>250</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Pagination
              count={Math.ceil(((data || previousData)?.fetchEvents?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{`Additional details for ${activeJobEvent?.objectPayload?.jobName || ''} ran on ${activeJobEvent?.createdAt || ''}`}</DialogTitle>
        <DialogContent>
          <pre>{JSON.stringify(activeJobEvent, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Jobs;
