import { Account } from './account';
import { FinancialProduct } from './financialProduct';
import { SubAccount } from './subAccount';
import { User } from './user';

export enum SubTradeRequestTypes {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum SubTradeRequestStates {
  INITIATED = 'INITIATED',
  READY = 'READY',
  REQUESTED = 'REQUESTED',
  CANCELED = 'CANCELED',
  RECONCILED = 'RECONCILED',
}

export interface SubTradeRequest {
  id: string;
  subAccount?: SubAccount;
  account?: Account;
  user?: User;
  financialProduct?: FinancialProduct;
  settlementDate?: Date;
  type: SubTradeRequestTypes;
  state: SubTradeRequestStates;
  isSellAll: boolean;
  moneyAllocatedCents: number;
  preSplitMoneyAllocatedCents?: number;
  pair?: SubTradeRequest | null;
  isProcessedForPair?: boolean;
  bulkTradeRun?: { id?: string };
  verifiedBy?: string;
  verifiedAt?: Date;
}
