import {
  Typography, Grid, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import uniqueId from 'lodash/uniqueId';
import { useEffect, useState } from 'react';
import { usePermissions } from '../../providers/userContextProvider';
import RichTextEditor from '../inputs/richTextEditor';
import { NoteObjectTypes, NoteTypes } from '../../interfaces/note';
import ConfirmationModal from '../modals/confirmationModal';
import { colors } from '../../theme/colors';

export const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export const TRANSITION_NOTE = gql`
  mutation transitionNote($input: TransitionNoteInput!) {
    transitionNote(input: $input) {
      note {
        id
      }
    }
  }
`;
interface NewNoteProp {
  objectId?: string,
  noteObjectType: NoteObjectTypes,
  afterUpdate?: () => void,
  hidePostAndClearButton?: boolean,
  data?: string,
  title?: string,
  onChange?: ((e: any) => void),
  characterCountLimiter?: number,
  contentLengthChanged?: (contentLength: number) => void,
}
const MAX_CHARACTER_LIMIT = 10000;
const styles = {
  characterCage: {
    textAlign: 'right',
    position: 'relative',
    top: '-54px',
    right: '10px',
    zIndex: '0',
    height: '0px',
    color: '#cccccc',
    width: '100px',
    display: 'flex',
    justifyContent: 'right',
  },
  confirmation: {
    dialogContainer: {
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '424px', // Set Optional Note confirmation Dialog Width
        },
      },
    },
    dialogContentStyles: {
      width: '376px',
      title: {
        fontWeight: 700,
      },
    },
    dialogButton: {
      minWidth: '160px',
      fontWeight: 700,
    },
    dalogClose: {
      color: colors.noteAuthor,
    },
  },
};
const NewNote = ({
  objectId, noteObjectType, afterUpdate, hidePostAndClearButton, data, onChange, title, characterCountLimiter = MAX_CHARACTER_LIMIT, contentLengthChanged,
}: NewNoteProp) => {
  const { permissions } = usePermissions();
  const { t } = useTranslation('buildModels');
  const [clearConfirmationOpen, setClearConfirmationOpen] = useState(false);
  const [note, setNote] = useState({
    content: data ?? '',
    type: NoteTypes.ORGANIZATIONAL,
    objectId,
    objectType: noteObjectType || NoteObjectTypes.USER,
  });
  const [contentLength, setContentLength] = useState(0);
  const [key, setKey] = useState('key');
  const canWrite = (): boolean => (permissions.includes('write:notes') || false);
  const clearContent = () => {
    setNote({
      content: '', type: NoteTypes.ORGANIZATIONAL, objectId, objectType: noteObjectType || NoteObjectTypes.USER,
    });
    setKey(uniqueId());
  };
  useEffect(() => {
    if (contentLengthChanged) contentLengthChanged(contentLength);
  }, [contentLength, contentLengthChanged]);

  const [createNote, { loading }] = useMutation(CREATE_NOTE, {
    variables: {
      input: {
        ...note,
        content: note.content.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
      },
    },
    onCompleted: (value: any) => {
      if (value?.createNote?.note?.id) {
        publishNote({
          variables: {
            input: {
              noteId: value.createNote.note.id,
              transition: 'publish',
            },
          },
          onCompleted: () => {
            clearContent();
            if (afterUpdate) afterUpdate();
          },
        });
      }
    },
  });
  const [publishNote] = useMutation(TRANSITION_NOTE);

  useEffect(() => {
    setKey(uniqueId()); // Run  this once to eleminate Toolbar issue
  }, []);

  return (
    <Grid container spacing={2} justifyContent='flex-end'>
      <Grid item xs={12}>
        <RichTextEditor
          onChange={(value) => {
            if (characterCountLimiter && (contentLength + 1) > characterCountLimiter) return;
            if (onChange) onChange(value);
            setNote((n) => ({ ...n, content: value }));
          }}
          value={note?.content ?? ''}
          label={title ?? t('newNote.title')}
          readonly={!canWrite()}
          noteType={note.type || NoteTypes.ORGANIZATIONAL}
          onChangeNoteType={(newType: NoteTypes) => {
            setNote((n) => ({ ...n, type: newType }));
          }}
          setContentLength={setContentLength}
          key={key}
          customEditorStyle={{
            '.public-DraftEditor-content': {
              paddingBottom: '25px',
              fontFamily: 'Causten Round, "Trebuchet MS", Helvetica, sans-serif,Inconsolata, Menlo, Consolas, monospace',
            },
          }}
          customToolbarStyle={{
            marginTop: '20px',
            bottom: '18px',
            position: 'absolute',
            '>div': {
              border: 'none',
              boxShadow: 'none',
              height: '20px',
            },
          }}
        />
      </Grid>
      <Grid item sx={styles.characterCage} xs={12}>
        <Typography data-testid='content-length' variant="subtitle2" sx={{ color: contentLength > characterCountLimiter ? colors.error : colors.noteTagLine }}>
          {contentLength.toLocaleString()}
        </Typography>
        <Typography data-testid='content-limit' variant="subtitle2" sx={{ color: colors.noteTagLine }}>/ {characterCountLimiter?.toLocaleString()} {t('newNote.characters')}</Typography>
      </Grid>
      {!hidePostAndClearButton && canWrite() && (
        <>
          <Grid item sx={{ position: 'relative', top: '-10px' }}>
            <Button type="button" data-testid='clear-note' disabled={loading || contentLength === 0} fullWidth variant="outlined" onClick={() => setClearConfirmationOpen(true)}>
              {t('buildModels:edit.clear')}
            </Button>
          </Grid>
          <Grid item sx={{ position: 'relative', top: '-10px' }}>
            <Button type='submit' data-testid='save-note' disabled={loading || contentLength === 0 || (contentLength > characterCountLimiter)} fullWidth variant='contained' onClick={async () => {
              await createNote();
            }}>
              {t('buildModels:edit.post')}
            </Button>
          </Grid>
        </>
      )}
       <ConfirmationModal
          open={clearConfirmationOpen}
          loading={loading}
          title={t('newNote.clearTitle')}
          bodyText={t('newNote.clearSubText')}
          onConfirm={() => {
            clearContent();
            setClearConfirmationOpen(false);
          }}
          onCancel={() => setClearConfirmationOpen(false)}
          confirmButton={t('newNote.clear')}
          cancelButton={t('newNote.cancelClear')}
          dialogStyles={styles.confirmation}
        />
    </Grid>
  );
};

export default NewNote;
