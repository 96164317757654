import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';

interface RateOfReturn {
  title?: string,
  stat?: string,
  testId?: string,
  show: boolean,
}

export const RateOfReturnInformation = ({ rateOfReturns, testId = 'rate-of-return-information' }: {
  rateOfReturns: RateOfReturn[], testId?: string,
}) => {
  const { sys } = useThemeTokens();

  return (
    <Box display='flex' data-testid={testId}>
      {rateOfReturns.filter((item) => item.show && item.title && item.stat).map((elem: RateOfReturn, idx: number) => (
        <Box data-testid={elem.testId} sx={{ display: 'flex', mr: 1.5 }} key={idx}>
          <Typography variant='bodyMedium' sx={{ fontWeight: 400, color: sys.color.onSurfaceVariant, display: 'inline-block' }}>
            {elem.title}
          </Typography>
          <Typography variant='bodyMedium' sx={{ color: sys.color.onSurface, ml: 1 }}>{elem.stat}</Typography>
        </Box>
      ))}
    </Box>
  );
};
