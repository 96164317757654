/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import {
  Card, CardContent,
  DateField,
  MenuItem,
  Pagination,
  SelectField,
  Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import {
  SubAccountSelect, AccountTypeSelect, FilterModal, DownloadButton,
  Column,
  ExportApiReportFormatters,
  DownloadButtonProps,
} from '../../../3-pattern';
import { TransactionTypes } from '../../../../interfaces/transaction';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { LedgerCell } from './components/ledgerCell';
import { usePermissions, UserContext } from '../../../../providers/userContextProvider';
import NewTransaction from './components/newTransaction';
import UpdateTransaction from './components/updateTransaction';

const FETCH_TRANSACTIONS_QUERY = (permissions: string[]) => `query fetchTransactions($input: FetchTransactionsInput!) {
  fetchTransactions(input: $input) {
    transactions {
      id
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      pending
      settleDate
      settleDateToWithdraw
      financialProduct {
        id
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
      subAccount {
        id
        goal {
          name
          type
        }
        account {
          id
          type
          householdClientGroup {
            id
          }
        }
      }
      goal { id }
      organization { id }
      objectType
      objectId
      user {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
      }
    }
    totalCount
  }
}`;
const FETCH_LEDGER = (permissions: string[]) => gql`${FETCH_TRANSACTIONS_QUERY(permissions)}`;

const FETCH_CUSTODIAN_TRANSACTIONS_QUERY = `query fetchCustodianTransactions($input: FetchCustodianTransactionsInput!) {
  fetchCustodianTransactions(input: $input) {
    transactions {
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      custodianTransactionType
      financialProduct {
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalCount
  }
}`;
const FETCH_CUSTODIAN_LEDGER = gql`${FETCH_CUSTODIAN_TRANSACTIONS_QUERY}`;

const BASE_COLUMN_DEFINITIONS = {
  account: {
    label: { en: 'Account', fr: 'Compte' },
    type: 'account' as const,
  },
  account_id: {
    label: { en: 'Account ID', fr: 'Compte' },
    type: 'account_id' as const,
  },
  firstName: {
    label: { en: 'User First Name', fr: 'Prénom' },
    type: 'firstName' as const,
  },
  lastName: {
    label: { en: 'User Last Name', fr: 'Nom' },
    type: 'lastName' as const,
  },
  accountType: {
    label: { en: 'Account Type', fr: 'Type de compte' },
    type: 'accountType' as const,
  },
  type: {
    label: { en: 'Type', fr: 'Type' },
    type: 'type' as const,
  },
  security: {
    label: { en: 'Security', fr: 'Valeur mobilière' },
    type: 'security' as const,
  },
  costPrice: {
    label: { en: 'Cost Price', fr: 'Prix d\'achat' },
    type: 'costPrice' as const,
  },
  quantity: {
    label: { en: 'Quantity', fr: 'Quantité' },
    type: 'quantity' as const,
  },
  value: {
    label: { en: 'Value', fr: 'Valeur' },
    type: 'value' as const,
  },
  bookCost: {
    label: { en: 'Book Cost (ACB/unit)', fr: 'Coût comptable (prix de base rajusté/unité)' },
    type: 'costPrice' as const,
  },
  date: {
    label: { en: 'Date', fr: 'Date' },
    type: 'date' as const,
  },
  description: {
    label: { en: 'Description', fr: 'Description' },
    type: 'description' as const,
  },
  // Additional columns
  currency: {
    label: { en: 'Currency', fr: 'Devise' },
    type: 'currency' as const,
  },
  settleDate: {
    label: { en: 'Settlement Date', fr: 'Date de règlement' },
    type: 'settleDate' as const,
  },
  settleDateToWithdraw: {
    label: { en: 'Settlement Date to Withdraw', fr: 'Date de règlement pour retrait' },
    type: 'settleDateToWithdraw' as const,
  },
  subAccount: {
    label: { en: 'Sub-Account', fr: 'Sous-compte' },
    type: 'subAccount' as const,
  },
  goal: {
    label: { en: 'Goal', fr: 'Objectif' },
    type: 'goal' as const,
  },
  user: {
    label: { en: 'User', fr: 'Utilisateur' },
    type: 'user' as const,
  },
  organization: {
    label: { en: 'Organization', fr: 'Organisation' },
    type: 'organization' as const,
  },
  objectType: {
    label: { en: 'Object Type', fr: 'Type d\'objet' },
    type: 'objectType' as const,
  },
  objectId: {
    label: { en: 'Object ID', fr: 'ID d\'objet' },
    type: 'objectId' as const,
  },
  householdClientGroup: {
    label: { en: 'Household Client Group', fr: 'Groupe client ménage' },
    type: 'householdClientGroup' as const,
  },
} as const;

const DEFAULT_TABLE = [
  BASE_COLUMN_DEFINITIONS.account,
  BASE_COLUMN_DEFINITIONS.type,
  BASE_COLUMN_DEFINITIONS.currency,
  BASE_COLUMN_DEFINITIONS.costPrice,
  BASE_COLUMN_DEFINITIONS.quantity,
  BASE_COLUMN_DEFINITIONS.value,
  BASE_COLUMN_DEFINITIONS.date,
];

const DOWNLOAD_COLUMNS = [
  BASE_COLUMN_DEFINITIONS.account_id,
  BASE_COLUMN_DEFINITIONS.firstName,
  BASE_COLUMN_DEFINITIONS.lastName,
  BASE_COLUMN_DEFINITIONS.accountType,
  BASE_COLUMN_DEFINITIONS.type,
  BASE_COLUMN_DEFINITIONS.security,
  BASE_COLUMN_DEFINITIONS.costPrice,
  BASE_COLUMN_DEFINITIONS.quantity,
  BASE_COLUMN_DEFINITIONS.value,
  BASE_COLUMN_DEFINITIONS.bookCost,
  BASE_COLUMN_DEFINITIONS.date,
  BASE_COLUMN_DEFINITIONS.description,
];

const DOWNLOAD_ADDITIONAL_COLUMNS = [
  BASE_COLUMN_DEFINITIONS.currency,
  BASE_COLUMN_DEFINITIONS.settleDate,
  BASE_COLUMN_DEFINITIONS.settleDateToWithdraw,
  BASE_COLUMN_DEFINITIONS.subAccount,
  BASE_COLUMN_DEFINITIONS.goal,
  BASE_COLUMN_DEFINITIONS.user,
  BASE_COLUMN_DEFINITIONS.organization,
  BASE_COLUMN_DEFINITIONS.objectType,
  BASE_COLUMN_DEFINITIONS.objectId,
  BASE_COLUMN_DEFINITIONS.householdClientGroup,
];

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalIds: [objectId] };
    case PageObjectType.ACCOUNT:
      return { accountIds: [objectId] };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountIds: [objectId] };
    default:
      return {};
  }
};

const baseSubAccountFilter = (objectType: PageObjectType, objectId: string, filter: any) => {
  const availableAccountType = filter.accountType && filter.accountType !== 'ANY' ? filter.accountType : undefined;
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId, accountType: availableAccountType };
    case PageObjectType.GOAL:
      return { goalId: objectId, accountType: availableAccountType };
    default:
      return { accountType: availableAccountType };
  }
};

export const Ledger = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { permissions } = usePermissions();
  const [filter, setFilter] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [pagination, setPagination] = useState<any>({
    offSet: 0, perPage: 15,
  });
  const [transactions, setTransactions] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState({
    id: '',
    type: TransactionTypes.ADJUSTMENT,
  });

  const useCustodianData = [PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && options.useCustodianStatistics;
  const enableAccountTypeFilter = [PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType);
  const [fetchLedger, { loading, refetch }] = useLazyQuery(FETCH_LEDGER(permissions), {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchTransactions.transactions);
      setTotalCount(Number(data.fetchTransactions.totalCount || 0));
    },
  });

  const [fetchCustodianLedger, { loading: custodianLoading }] = useLazyQuery(FETCH_CUSTODIAN_LEDGER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchCustodianTransactions.transactions);
      setTotalCount(Number(data.fetchCustodianTransactions.totalCount || 0));
    },
  });

  const dateAfter = dayjs(filter?.dateAfter).format('YYYY-MM-DD');
  const dateBefore = dayjs(filter?.dateBefore).format('YYYY-MM-DD');

  const queryFilter = {
    ...(useCustodianData ? {} : { subAccountIds: filter.subAccount ? [filter.subAccount.id] : undefined }),
    ...baseFilter(objectType, objectId),
    ...(!enableAccountTypeFilter ? {} : { accountType: filter.accountType || undefined }),
    types: filter.type ? [filter.type] : undefined,
    dateAfter: filter.dateAfter && dayjs(dateAfter).isValid() ? dateAfter : undefined,
    dateBefore: filter.dateBefore && dayjs(dateBefore).isValid() ? dateBefore : undefined,
    pending: filter.pending === 'true' ? true : (filter.pending === 'false' ? false : undefined),
  };

  useEffect(() => {
    const variables = {
      input: {
        filter: queryFilter,
        pagination: {
          ...pagination, sortField: 'date', sortDesc: false,
        },
      },
    };
    if (useCustodianData) {
      fetchCustodianLedger({ variables });
    } else {
      fetchLedger({ variables });
    }
  }, [objectType, objectId, filter, pagination]);

  useEffect(() => {
    setPagination({ offSet: (page - 1) * pagination.perPage, perPage: pagination.perPage });
  }, [page]);

  const columns: Column[] = DOWNLOAD_COLUMNS.map((elem: any) => {
    switch (elem.type) {
      case 'account_id':
        return {
          gqlAlias: 'subAccount.account.id',
          header: translateBackend(elem.label),
        };
      case 'firstName':
        return {
          gqlAlias: 'user.firstName',
          header: translateBackend(elem.label),
        };
      case 'lastName':
        return {
          gqlAlias: 'user.lastName',
          header: translateBackend(elem.label),
        };
      case 'accountType':
        return {
          gqlAlias: 'account.type',
          header: translateBackend(elem.label),
        };
      case 'date':
        return {
          gqlAlias: 'date',
          header: translateBackend(elem.label),
        };
      case 'type':
        return {
          gqlAlias: 'type',
          header: translateBackend(elem.label),
        };
      case 'security':
        return {
          gqlAlias: 'financialProduct.ticker',
          header: translateBackend(elem.label),
        };
      case 'quantity':
        return {
          gqlAlias: 'quantity',
          header: translateBackend(elem.label),
        };
      case 'value':
        return {
          gqlAlias: 'valueCents',
          header: translateBackend(elem.label),
          formatter: ExportApiReportFormatters.DIVIDE_BY_100,
        };
      case 'costPrice':
        return {
          gqlAlias: 'priceCents',
          header: translateBackend(elem.label),
          formatter: ExportApiReportFormatters.DIVIDE_BY_100,
        };
      case 'description':
        return {
          gqlAlias: 'description',
          header: translateBackend(elem.label),
        };
      default:
        return {
          gqlAlias: `unknown.${elem.type}`,
          header: translateBackend(elem.label),
        };
    }
  });

  const additionalColumns: Column[] = DOWNLOAD_ADDITIONAL_COLUMNS.map((elem: any) => {
    switch (elem.type) {
      case 'currency':
        return {
          gqlAlias: 'currency',
          header: translateBackend(elem.label),
        };
      case 'settleDate':
        return {
          gqlAlias: 'settleDate',
          header: translateBackend(elem.label),
        };
      case 'settleDateToWithdraw':
        return {
          gqlAlias: 'settleDateToWithdraw',
          header: translateBackend(elem.label),
        };
      case 'subAccount':
        return {
          gqlAlias: 'subAccount.id',
          header: translateBackend(elem.label),
        };
      case 'goal':
        return {
          gqlAlias: 'goal.id',
          header: translateBackend(elem.label),
        };
      case 'user':
        return {
          gqlAlias: 'user.id',
          header: translateBackend(elem.label),
        };
      case 'organization':
        return {
          gqlAlias: 'organization.id',
          header: translateBackend(elem.label),
        };
      case 'objectType':
        return {
          gqlAlias: 'objectType',
          header: translateBackend(elem.label),
        };
      case 'objectId':
        return {
          gqlAlias: 'objectId',
          header: translateBackend(elem.label),
        };
      case 'householdClientGroup':
        return {
          gqlAlias: 'subAccount.account.householdClientGroup.id',
          header: translateBackend(elem.label),
        };
      default:
        return {
          gqlAlias: `unknown.${elem.type}`,
          header: translateBackend(elem.label),
        };
    }
  });

  const getLedgerDownloadConfig = (): DownloadButtonProps => {
    const baseQuery = useCustodianData
      ? FETCH_CUSTODIAN_TRANSACTIONS_QUERY
      : FETCH_TRANSACTIONS_QUERY(permissions);

    const fileName = options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.fileName');

    const config: DownloadButtonProps = {
      gql: baseQuery,
      fileName: `${fileName}_${dayjs().format('YYYY-MM-DD')}`,
      filter: queryFilter,
      queryPath: useCustodianData ? 'fetchCustodianTransactions' : 'fetchTransactions',
      datasetPath: 'transactions',
      columns,
      additionalColumns,
      sortField: 'date',
      sortDesc: false,
      progressTitle: t('components:transaction.progressTitle'),
      configurable: true,
    };

    return config;
  };

  const downloadConfig = getLedgerDownloadConfig();

  return (
    <>
      <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.title')}</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ pb: 2 }}>
          <Box display='flex' justifyContent='end' width='100%' alignItems='end' >
            <Box display='flex'>
              <FilterModal filterExists={filter.type || filter.subAccount || filter.dateAfter || filter.dateBefore || filter.pending}>
                <SelectField
                  value={filter.type}
                  label={t('components:type')}
                  onChange={(e: any) => setFilter({ ...filter, type: e.target.value })}
                  fullWidth
                  placeholder={t('components:any')}
                  sx={{ mb: 2 }}
                  clearable
                >
                  {Object.values(TransactionTypes)
                    .map((x: TransactionTypes) => (
                    <MenuItem value={x} key={x}>
                      {t(`components:transaction.types.${x}`)}
                    </MenuItem>
                    ))}
                </SelectField>
                {enableAccountTypeFilter && (
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      {![PageObjectType.SUB_ACCOUNT, PageObjectType.ACCOUNT].includes(objectType) && (
                        <AccountTypeSelect
                          omitAny
                          householdId={objectType === PageObjectType.HOUSEHOLD ? objectId : undefined}
                          entityId={objectType === PageObjectType.INDIVIDUAL ? objectId : undefined}
                          size='small'
                          value={filter.accountType}
                          onChange={(accountType: any) => setFilter({ ...filter, accountType })}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {objectType !== PageObjectType.SUB_ACCOUNT && !useCustodianData && (
                  <SubAccountSelect
                    sx={{ mb: 2 }}
                    filter={baseSubAccountFilter(objectType, objectId, filter)}
                    selectedSubAccount={filter.subAccount}
                    onSubAccountSelect={(subAccount: any) => setFilter({ ...filter, subAccount })}
                    label={t('components:subAccount')}
                  />
                )}
                {activeOrganization?.allowPendingTransactions && (
                  <SelectField
                    value={filter.pending}
                    label={t('components:transaction.requested')}
                    onChange={(e: any) => setFilter({ ...filter, pending: e.target.value })}
                    fullWidth
                    placeholder={t('components:any')}
                    sx={{ mb: 2 }}
                    clearable
                  >
                    <MenuItem value='true'>{t('components:transaction.requested')}</MenuItem>
                    <MenuItem value='false'>{t('components:transaction.reconciled')}</MenuItem>
                  </SelectField>
                )}
                <DateField label={t('components:fromDate')} value={filter.dateAfter} onChange={(e: any) => setFilter({ ...filter, dateAfter: e })} fullWidth sx={{ mb: 2 }} />
                <DateField label={t('components:toDate')} value={filter.dateBefore} onChange={(e: any) => setFilter({ ...filter, dateBefore: e })} fullWidth sx={{ mb: 2 }} />
              </FilterModal>
              {(options.enableDownload && permissions.includes('read:api_exports')) && <Box sx={{ ml: 1 }}>
                <DownloadButton {...downloadConfig} />
              </Box>}
              {options.canAdd && (
                <Box sx={{ ml: 1 }}>
                  <NewTransaction
                    forObject={objectType}
                    forId={objectId}
                    afterCreate={refetch}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                  <TableHeadCell key={x} isFirst={idx === 0} right={![
                    'account', 'type', 'date',
                    'currency', 'description', 'custodianType',
                    'bookValue', 'security',
                  ].includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
                ))}
              </TableRow>
              {(useCustodianData ? custodianLoading : loading) ? (
                <>
                  {[...Array(15)].map((x, i) => (
                    <TableRow key={i}>
                      {(options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                        <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {transactions.map((transaction: any) => (
                    <TableRow key={transaction.id} hover pointer={options.canEdit} onClick={() => {
                      if (options.canEdit) {
                        setActiveTransaction(transaction);
                        setOpen(true);
                      }
                    }}>
                      {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                        <LedgerCell
                          key={`${transaction.id}-${x.type}`}
                          isFirst={idx === 0}
                          transaction={transaction}
                          type={x.type}
                          view={objectType}
                        />
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
              {transactions.length === 0 && !(useCustodianData ? custodianLoading : loading) && (
                <TableRow>
                  <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <UpdateTransaction open={open} handleClose={() => setOpen(false)} transaction={activeTransaction} afterUpdate={refetch} options={options} />
        <Box display='flex' justifyContent='flex-end' sx={{ p: 1 }}>
          <Pagination
            size='small'
            page={page}
            perPage={pagination.perPage}
            count={Math.ceil(totalCount / pagination.perPage)}
            onChange={(e, newPage) => setPage(newPage)}
            onChangePerPage={(newPerPage) => setPagination({ ...pagination, perPage: newPerPage })}
          />
        </Box>
      </Card>
    </>
  );
};
