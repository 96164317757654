import { DocumentNode } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../interfaces/fileDocument';
import { CREATE_FILE_DOCUMENT, FETCH_FILE_UPLOAD_URL } from '../pages/client/components/documentUpload';

export interface CreateFileInput {
  objectType: FileDocumentObjectTypes,
  objectId: string,
  fileName: string,
  type: FileDocumentTypes,
  userId: string,
  name?: string,
  permissionType?: string,
  mediaType?: string,
  sourceId?: string,
  sourceType?: string,
}

export interface FileUploaderProps {
  file: File,
  createFileInput: CreateFileInput,
  onSuccess?: () => void,
  refetchQueries?: DocumentNode[],
  apolloClient: any,
}

export const fileUploader = async (
  {
    file,
    createFileInput,
    onSuccess,
    refetchQueries,
    apolloClient,
  }: FileUploaderProps,
): Promise<void> => {
  if (!file) return;

  /* (1) fetch the S3 upload URL from backend */
  const queryResult = await apolloClient.query({
    query: FETCH_FILE_UPLOAD_URL,
    variables: {
      input: {
        objectType: createFileInput.objectType,
        objectId: createFileInput.objectId,
        fileName: (createFileInput.type === FileDocumentTypes.ID_VERIFICATION) ? createFileInput.fileName : createFileInput.name,
        type: createFileInput.type,
        userId: createFileInput.userId,
      },
      skipErrorHandler: true,
    },
    fetchPolicy: 'no-cache',
  });

  const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
  if (!uploadUrl || queryResult?.error) {
    return;
  }

  /* (2) do the upload */
  try {
    const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: file }));
    if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
  } catch (e: any) {
    return;
  }

  /* (3) create the fileDocument within backend */
  try {
    await await apolloClient.mutate({
      mutation: CREATE_FILE_DOCUMENT,
      variables: {
        input: {
          fileName: (createFileInput.type === FileDocumentTypes.ID_VERIFICATION) ? createFileInput.fileName : createFileInput.name,
          objectId: createFileInput.objectId,
          objectType: createFileInput.objectType,
          name: createFileInput.name,
          type: createFileInput.type,
          permissionType: createFileInput.permissionType,
          sourceType: createFileInput.sourceType,
          sourceId: createFileInput.sourceId ?? undefined,
        },
      },
      refetchQueries,
    });
  } catch {
    return;
  }
  if (onSuccess) {
    onSuccess();
  }
};

/**
 * uploadWithRetry
 *
 * This function attempts to upload a file to a specified URL using the HTTP PUT method.
 * If the upload fails, it will retry up to a specified number of attempts (`maxRetries`).
 * After each failed attempt, the function logs detailed error information (such as the
 * upload type and error message) to Datadog for monitoring and diagnostics.
 *
 * @param {string} uploadUrl - The URL to which the file should be uploaded.
 * @param {any} uploadingFile - The file or data to be uploaded.
 * @param {string} [type='Document'] - A description of the file type being uploaded. Defaults to 'Document'.
 * @param {number} [maxRetries=3] - The maximum number of retry attempts before the function throws an error. Defaults to 3.
 *
 * @returns {Promise<Response>} - Returns the response from the upload if successful.
 *
 * @throws {Error} - Throws an error if the upload fails after the specified number of retries.
 *
 * @example
 *
 * const response = await uploadWithRetry('https://example.com/upload', file, 'Image', 3);
 * console.log('Upload successful:', response);
 *
 * @description
 * The function logs each failed attempt, including the number of attempts and error details, to Datadog via `datadogLogs.logger.error`.
 * It ensures detailed error tracking for monitoring the status of uploads, particularly in case of repeated failures.
 */
export async function uploadWithRetry(
  uploadUrl: string,
  uploadingFile: any,
  type = 'Document',
  maxRetries = 3,
): Promise<Response> {
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
      if (response.ok) return response;
      datadogLogs.logger.error(`Upload failed with status: ${response.status}, type: ${type}`);
      throw new Error(`Upload failed with status: ${response.status}, type: ${type}`);
    } catch (error: any) {
      attempts++;
      datadogLogs.logger.error(`Attempt ${attempts} failed. Error: ${error}, type: ${type}`);

      if (attempts >= maxRetries) {
        datadogLogs.logger.error(`Attempt ${attempts} failed. Error: ${error}, type: ${type}`);
        throw new Error(`Upload failed, type: ${type}`);
      }
    }
  }

  throw new Error('Unexpected error occurred');
}
