import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { useContext } from 'react';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';
import { InlineImage } from '../../../../../pages/client/components/documents/inlineImage';
import { InlineImageLocal } from '../../../../../pages/client/components/documents/inlineImageLocal';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { User } from '../../../../../interfaces';

export const RiskQuestionVisual = ({
  options, userData, loading, question, workflowCompletion, updateUser, continueFunc,
}: {
  options: any, userData: Partial<User>, loading: boolean, question: number, workflowCompletion?: any,
  updateUser: (user: Partial<User>) => void, continueFunc: () => void,
}) => {
  const { t } = useTranslation('client');
  const { sendAnalytic } = useContext(AnalyticsContext);

  const submit = () => {
    const fields = invalidFields(options, userData);
    if (fields.length === 0) {
      const analyticEvent = question === 1 ? ovAnalyticsEvents.workflowsRiskQuestion1ContinueButtonSelect : ovAnalyticsEvents.workflowsRiskQuestion2ContinueButtonSelect;
      sendAnalytic(analyticEvent, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  const notSelected = question === 1 ? !userData.riskQuestion1 : !userData.riskQuestion2;
  const questionField = question === 1 ? 'riskQuestion1' : 'riskQuestion2';

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component='div' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}

      {options?.attachment?.file && (
        <Box sx={{ mb: '20px' }}>
          <InlineImageLocal data-testid="attachment" file={options?.attachment?.file} style={{ objectFit: 'contain', height: '300px', borderRadius: '15px' }} />
        </Box>
      )}
      {options?.attachment?.value && (
        <Box sx={{ mb: '20px' }}>
          <InlineImage data-testid="attachment" fileDocumentId={options?.attachment?.value} style={{ objectFit: 'contain', height: '300px', borderRadius: '15px' }} />
        </Box>
      )}

      <Box>
        <SelectionTile
          data-testid={`risk-question-${question}`}
          onChange={(e: any) => updateUser({ ...userData, [questionField]: e.target.value })}
          value={userData[questionField] ?? ''}
          options={options?.options?.map((option: any) => (
            { value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description) }
          ))}
        />
      </Box>

      <Box display='flex' justifyContent='end'>
        <Button
          data-testid="continue-button"
          label={t('continue')}
          disabled={loading || notSelected}
          sx={{ mt: 3, textAlign: 'center' }}
          type='submit'
        />
      </Box>
    </Form>
  );
};

export default RiskQuestionVisual;
