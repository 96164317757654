import { CurrencyCodes } from '@onevesthq/ov-enums';
import { Box, Typography, TypographyPropsVariants } from '../../1-primative';
import { formatMoneyValue } from '../../../util';
import { currencySymbol } from '../../../util/currency';

interface ValueSuperscriptedCentsProps {
  value?: number,
  mainVariant: TypographyPropsVariants['variant'],
  centsVariant: TypographyPropsVariants['variant'],
  currency?: CurrencyCodes,
  color?: string,
  testId?: string
}

export const ValueSuperscriptedCents = ({
  value, mainVariant, centsVariant, currency, color, testId = 'value-scripted-cents',
}: ValueSuperscriptedCentsProps) => {
  const formattedValue = formatMoneyValue(value, currencySymbol[currency ?? CurrencyCodes.CAD], undefined, false);
  const [mainValue, centsValue] = formattedValue.split('.');

  return (
    <Box display='flex' data-testid={testId}>
      <Typography variant={mainVariant} sx={{ fontVariant: 'tabular-nums', color }}>
        {mainValue}
      </Typography>
      <Typography variant={centsVariant} sx={{ fontVariant: 'tabular-nums', color }}>
        .{centsValue ?? '00'}
      </Typography>
    </Box>
  );
};
