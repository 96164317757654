import { useContext, useEffect, useState } from 'react';
import { sum } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { calculatePrimaryAssetClassHoldings } from './holdings.resources';
import { Box, Typography } from '../../../1-primative';
import { ObjectType, useStats } from '../../../../providers/statsHooks';
import { PageObjectType } from '../../../5-page';
import { AccordionHoldings } from './components/accordionHoldings';
import { TableHoldings } from './components/tableHoldings';
import { PieChartHoldings } from './components/pieChartHoldings';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext } from '../../../../providers/userContextProvider';
import { Holding } from '../../../../interfaces';

export const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const Holdings = ({
  objectId,
  objectType,
  options = {},
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
}): JSX.Element => {
  const { t } = useTranslation(['client']);

  const [assetClassHoldings, setAssetClassHoldings] = useState<any[]>([]);
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const useCustodianData = [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.HOUSEHOLD, PageObjectType.ACCOUNT, PageObjectType.GOAL].includes(objectType)
    && options.useExternalStatistics;
  const { activeCurrency } = useContext(UserContext);

  const {
    statistics, loading, history, refetchAll,
  } = useStats(
    {
      type: objectTypeMapping(objectType),
      id: objectId,
      ...(useCustodianData ? {} : { startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD') }),
      useCustodianData,
      fetchHistory: !useCustodianData,
      currency: activeCurrency,
    },
  );

  useEffect(() => {
    if ((statistics?.holdings || statistics?.holdingsMissingRate)) {
      const allHoldings = [
        ...(statistics?.holdings ?? []),
        ...(statistics?.holdingsMissingRate ?? []),
      ];
      const marketValueCents = sum(allHoldings.map((holding: Holding) => (holding?.originalCurrency?.totalCents ?? holding?.totalCents) ?? 0));
      setAssetClassHoldings(calculatePrimaryAssetClassHoldings(allHoldings, marketValueCents));
      setHoldings(allHoldings);
    }
  }, [statistics, useCustodianData]);

  const sortHoldings = () => {
    const sortedHoldings = holdings.sort((a: Holding, b: Holding) => {
      const localeComparison = (a.originalCurrency?.currency ?? a.currency ?? '').localeCompare(b.originalCurrency?.currency ?? b.currency ?? '');
      return localeComparison === 0 ? (b.totalCents ?? 0) - (a.totalCents ?? 0) : localeComparison;
    });
    return sortedHoldings;
  };

  return (
    <>
      <Box display="flex" alignItems={'center'}>
        <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('holdings')}</Typography>
      </Box>
      {
        options.display === 'table' ? (
          <TableHoldings
            data-testid='holdings-table'
            holdings={sortHoldings()}
            loading={loading} history={history}
            options={options} useCustodianData={useCustodianData}
            objectId={objectId}
            refetch={refetchAll}
          />
        ) : options.display === 'pie' ? (
          <PieChartHoldings data-testid='holdings-pie-chart' holdings={holdings} loading={loading} options={options} />
        ) : (
          <AccordionHoldings data-testid='holdings-accordion' assetClassHoldings={assetClassHoldings} loading={loading} />
        )
      }
    </>
  );
};
