import {
  ListItem, MenuItem, TextField, Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import dayjs from 'dayjs';
import CreateNewModal from '../../modals/createNewModal';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import AmountField from '../../inputs/amountField';
import { usePermissions } from '../../../providers/userContextProvider';
import { Exchanges } from '../../../interfaces/financialProduct';
import {
  ADJUSTED_COST_BASE_TYPES,
  Currencies,
  getValueSign,
  NEGATIVE_VALUE_TYPES,
  POSITIVE_VALUE_TYPES,
  QUANTITY_PRICE_MULTIPLIERS,
  SwitchTransactionTypes,
  SwitchTypes,
  TRADE_TYPES,
  TransactionObjectTypes,
  TransactionTypes,
} from '../../../interfaces/transaction';
import SecuritySelect from '../../inputs/securitySelect';
import NumberField from '../../inputs/numberField';
import EditSwitchEquitiesModal from '../../modals/editSwitchEquitiesModal';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';

export const CREATE_ADJUSTMENT_TRANSACTION = gql`
  mutation createAdjustmentTransaction($input: CreateAdjustmentTransactionInput!) {
    createAdjustmentTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      transaction {
        id
      }
    }
  }
`;

export const SWITCH_EQUITIES_AT_COST = gql`
  mutation switchEquitiesAtCost($input: SwitchEquitiesAtCostInput!) {
    switchEquitiesAtCost(input: $input) {
      transactions {
        id
        date
        type
        quantity
        priceCents
        valueCents
        description
        financialProduct {
          id
          ticker
          name
          translatedName {
            en
          }
        }
        subAccount {
          account {
            user {
              firstName
              lastName
            }
          }
          goal {
            name
          }
          account {
            type
          }
        }
      }
    }
  }
`;

export const SWITCH_EQUITIES_AT_MARKET = gql`
  mutation switchEquitiesAtMarket($input: SwitchEquitiesAtMarketInput!) {
    switchEquitiesAtMarket(input: $input) {
      transactions {
        id
        date
        type
        quantity
        priceCents
        valueCents
        description
        financialProduct {
          id
          ticker
          name
          translatedName {
            en
          }
        }
        subAccount {
          account {
            user {
              firstName
              lastName
            }
          }
          goal {
            name
          }
          account {
            type
          }
        }
      }
    }
  }
`;

interface TransactionInputPros {
  type: TransactionTypes;
  date?: string;
  subAccountId?: string;
  adjustmentType: 'DEPOSIT' | 'WITHDRAW';
  valueCents?: number;
  description?: string;
  quantity?: number;
  priceCents?: number;
  financialProductId?: string;
  currency?: Currencies;
}

interface SwitchInputPros {
  switchType?: SwitchTypes;
  financialProductOutId?: string;
  financialProductInId?: string;
  isExistingHolding: boolean;
}

const isMissingOrInvalidProducts = (values: any) => !values.financialProductInId || !values.financialProductOutId || values.isInvalidFinancialProduct;

const NewTransaction = ({
  afterCreate,
  forObject,
  forId,
  initialDate = dayjs().format('YYYY-MM-DD'),
  disableButton,
}: {
  afterCreate: () => void;
  forObject: 'USER' | 'CLIENT_GROUP';
  forId: string;
  initialDate?: string;
  disableButton?: boolean;
}) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const { showToast } = useGlobalToast();

  const [transaction, setTransaction] = useState<TransactionInputPros>({
    date: initialDate,
    type: TransactionTypes.ADJUSTMENT,
    adjustmentType: 'DEPOSIT',
    currency: Currencies.CAD,
  });
  const [initialState] = useState({
    date: initialDate,
    type: TransactionTypes.ADJUSTMENT,
    adjustmentType: 'DEPOSIT',
    currency: Currencies.CAD,
  });

  const [switchInput, setSwitchInput] = useState<SwitchInputPros>({
    isExistingHolding: false,
  });

  const [createAdjustmentTransaction, opt1] = useMutation(CREATE_ADJUSTMENT_TRANSACTION, {
    variables: {
      input: {
        ...pick(['description', 'date', 'subAccountId', 'currency'], transaction),
        valueCents: getValueSign(transaction.type, transaction.adjustmentType) * (transaction.valueCents ?? 0),
      },
    },
  });

  const calculateValueCents = (item: TransactionInputPros) => {
    if (QUANTITY_PRICE_MULTIPLIERS.includes(item.type)) {
      return (item.quantity ?? 0) * (item.priceCents ?? 0);
    }
    return getValueSign(item.type, item.adjustmentType) * (item.valueCents ?? 0);
  };

  const [createTransaction, opt2] = useMutation(CREATE_TRANSACTION, {
    variables: {
      input: {
        ...pick(['description', 'date', 'subAccountId', 'type'], transaction),
        ...(TRADE_TYPES.includes(transaction.type)
          ? {
            ...pick(['priceCents', 'financialProductId'], transaction),
            quantity: getValueSign(transaction.type) * -1 * (transaction.quantity ?? 0),
          }
          : pick(['currency'], transaction)),
        objectType: TransactionObjectTypes.ADJUSTMENT,
        valueCents: calculateValueCents(transaction),
      },
    },
  });

  const [switchEquitiesAtCostMutation, opt3] = useMutation(SWITCH_EQUITIES_AT_COST, {
    variables: {
      input: {
        subAccountId: transaction.subAccountId,
        date: transaction.date,
        description: transaction.description,
        financialProductInId: switchInput.financialProductInId,
        financialProductOutId: switchInput.financialProductOutId,
        isExistingHolding: transaction.type as any === 'SWITCH',
      },
    },
  });

  const [switchEquitiesAtMarketMutation, opt4] = useMutation(SWITCH_EQUITIES_AT_MARKET, {
    variables: {
      input: {
        subAccountId: transaction.subAccountId,
        date: transaction.date,
        description: transaction.description,
        financialProductInId: switchInput.financialProductInId,
        financialProductOutId: switchInput.financialProductOutId,
        isExistingHolding: transaction.type as any === 'SWITCH',
      },
    },
  });

  const canWriteOtherTransactionTypes = () => permissions.includes('write:transaction');

  useEffect(() => {
    if (transaction.type === TransactionTypes.DIVIDEND) {
      setTransaction((prev) => ({
        ...prev,
        quantity: 0,
        priceCents: 0,
      }));
    }
  }, [transaction.type]);

  const create = async (event: any) => {
    let response;

    if (transaction.type === TransactionTypes.ADJUSTMENT) {
      response = await createAdjustmentTransaction();
      if (response?.data) {
        showToast({ severity: 'info', message: t('components:transaction.newTransaction.createdToastMessage') });
      }
    } else if (switchInput?.switchType === SwitchTypes.AT_COST) {
      response = await switchEquitiesAtCostMutation();
      if (response?.data) {
        setOutTransaction(response.data.switchEquitiesAtCost.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWO_COST));
        setInTransaction(response.data.switchEquitiesAtCost.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWI_COST));
        showToast({ severity: 'info', message: t('components:transaction.switchTransaction.switchedToastMessage') });

        setTransactionsModalOpen(true);
        setSwitchInput({
          isExistingHolding: false,
        });
      } else {
        showToast({ severity: 'error', message: t('subaccount:switchEquitiesModal:errorToastMessage') });
      }
    } else if (switchInput.switchType === SwitchTypes.AT_MARKET) {
      response = await switchEquitiesAtMarketMutation();
      if (response?.data) {
        setOutTransaction(response.data.switchEquitiesAtMarket.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWO_MARKET));
        setInTransaction(response.data.switchEquitiesAtMarket.transactions.find((tr: any) => tr.type === SwitchTransactionTypes.SWI_MARKET));
        showToast({ severity: 'info', message: t('components:transaction.switchTransaction.switchedToastMessage') });

        setTransactionsModalOpen(true);
        setSwitchInput({
          isExistingHolding: false,
        });
      } else {
        showToast({ severity: 'error', message: t('subaccount:switchEquitiesModal:errorToastMessage') });
      }
    } else {
      response = await createTransaction();
      if (response?.data) {
        showToast({ severity: 'info', message: t('components:transaction.newTransaction.createdToastMessage') });
      }
    }

    setTransaction({
      date: initialDate,
      type: TransactionTypes.ADJUSTMENT,
      adjustmentType: 'DEPOSIT',
      currency: Currencies.CAD,
    });
    afterCreate();
  };

  const disabled = !(
    transaction.subAccountId
    && transaction.date
    && (ADJUSTED_COST_BASE_TYPES.includes(transaction.type) || (transaction.valueCents !== undefined && transaction.valueCents >= 0))
    && (!TRADE_TYPES.includes(transaction.type) || (
      transaction.financialProductId
      && (
        (transaction.type === TransactionTypes.DIVIDEND && transaction.quantity === 0 && transaction.priceCents === 0)
        || (transaction.quantity && transaction.priceCents)
      )
    ))
  );
  const loading = opt1.loading || opt2.loading || opt3.loading || opt4.loading;

  const [transactionsModalOpen, setTransactionsModalOpen] = useState<boolean>(false);
  const [outTransaction, setOutTransaction] = useState<any>(undefined);
  const [inTransaction, setInTransaction] = useState<any>(undefined);
  const [isInvalidFinancialProduct, setIsInvalidFinancialProduct] = useState<boolean>(false);

  const getPriceFieldLabel = () => t(`components:transaction.newTransaction.${ADJUSTED_COST_BASE_TYPES.includes(transaction.type) ? 'adjustedCostBase' : 'price'}`);

  return (
    <>
      <CreateNewModal
        state={transaction}
        initialState={initialState}
        buttonText={transaction.type as any === 'SWITCH' ? 'switch' : undefined}
        loading={loading}
        disableButton={disableButton}
        disabled={transaction.type as any === 'SWITCH' ? isMissingOrInvalidProducts({ ...switchInput, isInvalidFinancialProduct }) : disabled}
        title={t('components:transaction.newTransaction.title')}
        onSubmit={create}
      >
        {canWriteOtherTransactionTypes() && (
          <ListItem>
            <TextField select value={transaction.type} label={t('components:type')} onChange={(e: any) => setTransaction((prev) => ({ ...prev, type: e.target.value }))} fullWidth>
              {
                [
                  ...Object.entries(t('components:transaction.types', { returnObjects: true })),
                  ...Object.entries(t('components:transaction.switchMenuItem', { returnObjects: true })),
                ]
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                  ))}
            </TextField>
          </ListItem>
        )}
        <ListItem>
          <SubAccountSelect
            setSubAccount={(e) => {
              setTransaction((prev) => ({ ...prev, subAccountId: e.target.value }));
            }}
            value={transaction.subAccountId ?? ''}
            label={t('components:transaction.newTransaction.portfolio')}
            forObject={forObject}
            forId={forId}
          />
        </ListItem>
        <ListItem>
          <LocalizedDatePicker
            label={t('components:transaction.newTransaction.date')}
            value={transaction.date}
            onChange={(date) => setTransaction((prev) => ({ ...prev, date: dayjs(date).format('YYYY-MM-DD') }))}
            renderInput={(params) => <TextField fullWidth {...params} />}
            disableFuture={true}
            disabled={transaction.type as any === 'SWITCH'}
          />
        </ListItem>
        {TRADE_TYPES.includes(transaction.type) && (
          <>
            <ListItem>
              <SecuritySelect
                value={transaction.financialProductId ?? ''}
                setSecurity={(e) => setTransaction((prev) => ({ ...prev, financialProductId: e.id }))}
                label={t('components:transaction.newTransaction.financialProduct')}
              />
            </ListItem>
            {transaction.type !== TransactionTypes.DIVIDEND && (
              <>
                <ListItem>
                  <NumberField
                    label={t('components:transaction.newTransaction.quantity')}
                    fullWidth
                    decimalPlaces={4}
                    value={transaction.quantity ?? ''}
                    setNumberValue={(value) => setTransaction((prev) => ({ ...prev, quantity: value }))}
                  />
                </ListItem>
                <ListItem>
                  <AmountField
                    label={getPriceFieldLabel()}
                    variant='outlined'
                    value={transaction.priceCents}
                    fullWidth
                    onChange={(e: any) => setTransaction((prev) => ({ ...prev, priceCents: e.target.valueCents }))}
                  />
                </ListItem>
              </>
            )}
          </>
        )}
        {!POSITIVE_VALUE_TYPES.includes(transaction.type) && !NEGATIVE_VALUE_TYPES.includes(transaction.type) && !['SWITCH', TransactionTypes.INTEREST].includes(transaction.type) && (
          <ListItem>
            <TextField
              select
              value={transaction.adjustmentType}
              label={t('components:transaction.newTransaction.adjustmentType')}
              fullWidth
              onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
            >
              <MenuItem value='DEPOSIT'>{t('components:transferModal.deposit')}</MenuItem>
              <MenuItem value='WITHDRAW'>{t('components:transferModal.withdraw')}</MenuItem>
            </TextField>
          </ListItem>
        )}

        {transaction.type === TransactionTypes.INTEREST && (
          <ListItem>
            <TextField
              select
              value={transaction.adjustmentType}
              label={t('components:transaction.newTransaction.interestType')}
              fullWidth
              onChange={(e: any) => setTransaction((prev) => ({ ...prev, adjustmentType: e.target.value }))}
            >
              <MenuItem value='DEPOSIT'>{t('components:transaction.newTransaction.interestTypes.DEPOSIT')}</MenuItem>
              <MenuItem value='WITHDRAW'>{t('components:transaction.newTransaction.interestTypes.WITHDRAW')}</MenuItem>
            </TextField>
          </ListItem>
        )}

        {!ADJUSTED_COST_BASE_TYPES.includes(transaction.type) && !(transaction.type as any === 'SWITCH') && (
          <ListItem>
            <AmountField
              label={t('components:transaction.newTransaction.amount')}
              variant='outlined'
              value={transaction.valueCents}
              fullWidth
              onChange={(e: any) => setTransaction((prev) => ({ ...prev, valueCents: e.target.valueCents }))}
            />
          </ListItem>
        )}
        {transaction.type as any === 'SWITCH' && (
          <>
            <ListItem>
              <TextField
                select
                value={switchInput.switchType ?? ''}
                label={t('components:transaction:table.switchType')}
                onChange={(e: any) => setSwitchInput((prev: any) => ({ ...prev, switchType: e.target.value }))}
                fullWidth
              >
                {Object.entries(t('components:transaction.switchTypes', { returnObjects: true }))
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => (
                    <MenuItem value={key} key={key}>
                      {value}
                    </MenuItem>
                  ))}
              </TextField>
              {switchInput.switchType && (
                <Tooltip title={t(switchInput.switchType === SwitchTypes.AT_COST ? 'components:transaction.switchTransaction.atCostInfo' : 'components:transaction.switchTransaction.atMarketInfo')}>
                  <InfoIcon fontSize='small' color='secondary' />
                </Tooltip>
              )}
            </ListItem>
            <ListItem>
              <SecuritySelect
                value={switchInput.financialProductOutId ?? ''}
                setSecurity={(e) => {
                  setIsInvalidFinancialProduct(e.id === switchInput.financialProductInId);
                  setSwitchInput((prev: any) => ({ ...prev, financialProductOutId: e.id }));
                }}
                label={t('components:transaction.switchTransaction.outSecurity')}
                exchange={Exchanges.FUNDSERV}
              />
            </ListItem>
            <ListItem>
              <SecuritySelect
                value={switchInput.financialProductInId ?? ''}
                setSecurity={(e) => {
                  setIsInvalidFinancialProduct(e.id === switchInput.financialProductOutId);
                  setSwitchInput((prev: any) => ({ ...prev, financialProductInId: e.id }));
                }}
                label={t('components:transaction.switchTransaction.inSecurity')}
                inputError={isInvalidFinancialProduct}
                errorText={t('subaccount:switchEquitiesModal:sameProductToastMessage')}
                exchange={Exchanges.FUNDSERV}
              />
            </ListItem>
          </>
        )}
        <ListItem>
          <TextField
            label={t('components:transaction.newTransaction.details')}
            fullWidth
            value={transaction.description ?? ''}
            onChange={(e) => setTransaction((prev) => ({ ...prev, description: e.target.value }))}
          />
        </ListItem>
        {![...TRADE_TYPES, 'SWITCH'].includes(transaction.type) && (
          <ListItem>
            <TextField
              select
              label={t('components:transaction.table.currency')}
              fullWidth
              value={transaction.currency ?? ''}
              placeholder={transaction.currency ?? ''}
              onChange={(e) => setTransaction((prev) => ({ ...prev, currency: e.target.value as Currencies }))}
            >
              {Object.values(Currencies).map((x: Currencies) => (
                <MenuItem value={x} key={x}>
                  {t(`components:transaction.currencies.${x}`)}
                </MenuItem>
              ))}
            </TextField>
          </ListItem>
        )}
      </CreateNewModal>
      <EditSwitchEquitiesModal
        open={transactionsModalOpen}
        handleClose={() => {
          setTransactionsModalOpen(false);
          afterCreate();
        }}
        outTransaction={outTransaction}
        inTransaction={inTransaction}
        title={switchInput.switchType === SwitchTypes.AT_COST ? t('subaccount:switchEquitiesModal:atCost') : t('subaccount:switchEquitiesModal:atMarket')}
      />
    </>
  );
};

export default NewTransaction;
