import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PersonOfInterest } from 'ovComponents/4-module/widgets/personOfInterest';
import { EmploymentInformation } from 'ovComponents/4-module/widgets/employmentInformation';
import { IncomeAssetsAndDebts } from 'ovComponents/4-module/widgets/incomeAssetsAndDebts';
// import { ScheduleTransfers } from 'ovComponents/4-module/widgets/scheduleTransfers';
import { RelationshipInformation } from 'ovComponents/4-module/widgets/relationshipInformation';
import { Suitability } from '../../../4-module/widgets/suitability/suitability';
import { ScheduleTransfers } from '../../../4-module/widgets/scheduleTransfers/scheduleTransfer';
import { Box, Grid, Typography } from '../../../1-primative';
import { Card, CardContent, IconButton } from '../../../2-component';
import { ClientName } from '../../../4-module/widgets/clientName/clientName';
import { MarketValueChart } from '../../../4-module/widgets/marketValueChart';
import { InitiateTransfer } from '../../../4-module/widgets/initiateTransfer';
import { Goals } from '../../../4-module/widgets/goals';
import { Accounts } from '../../../4-module/widgets/accounts/accounts';
import { Holdings } from '../../../4-module/widgets/holdings/holdings';
import { Ledger } from '../../../4-module/widgets/ledger/ledger';
import { Documents } from '../../../4-module/widgets/documents/documents';
import { PersonalInformation } from '../../../4-module/widgets/personalInformation';
import { NonIndividualInformation } from '../../../4-module/widgets/nonIndividualInformation';
import { PendingTransfers } from '../../../4-module/widgets/pendingTransfers/pendingTransfers';
import { CreateAccountWizardButton } from '../../../../components/wizards/createAccountWizard/button';
import { SubAccounts } from '../../../4-module/widgets/subAccounts';
import ErrorBoundary from './errorBoundary';
import { PageObjectType } from '../pageConfiguration';
import { RecentTransactions } from '../../../4-module/widgets/recentTransactions/recentTransactions';
import { NewsAndInsights } from '../../../4-module/widgets/newsAndInsights';
import { Contacts } from '../../../4-module/widgets/contacts';
import { EditAccount } from '../../../4-module/widgets/editAccount';
import { Refinitiv } from '../../../4-module/widgets/refinitiv/refinitiv';
import Access from '../../../4-module/widgets/access';
import { AdvisorContactInformation } from '../../../4-module/widgets/advisorContactInformation';
import { ProjectedIncomeReport } from '../../../4-module/widgets/projectedIncomeReport';
import { SearchSecurities } from '../../../4-module/widgets/refinitiv/widgets/searchSecurities/searchSecurities';
import { Notes } from '../../../4-module/widgets/notes/notes';
import { AuditLog } from '../../../4-module/widgets/auditLog/auditLog';
import { TradeOrders } from '../../../4-module/widgets/tradeOrders/tradeOrders';
import { ResidencyInformation } from '../../../4-module/widgets/residencyInformation';
import { FeeDetails } from '../../../4-module/widgets/feeDetails/feeDetails';
import { TableHouseHoldMembers } from '../../../4-module/widgets/householdMembers/components/tableHouseHoldMembers';
import { GoalSuitability } from '../../../4-module/widgets/goalSuitability/goalSuitability';
import { Compliance } from '../../../4-module/widgets/compliance/compliance';
import { IdVerification } from '../../../4-module/widgets/idVerification/idVerification';
import { LinkedBankAccounts } from '../../../4-module/widgets/linkedBankAccounts';
import { Portfolio } from '../../../4-module/widgets/portfolio/portfolio';
import { Transfers } from '../../../4-module/widgets/transfers/transfers';
import { Relationships } from '../../../4-module/widgets/relationships/relationships';
import { HtmlTemplate } from '../../../4-module/widgets/htmlTemplate';
import { BulkTradeRequests } from '../../../4-module/widgets/bulkTradeRequests/bulkTradeRequests';
import { ModelPortfolioHoldings } from '../../../4-module/widgets/modelPortfolioHoldings/modelPortfolioHoldings';

export const Widget = ({
  type,
  i,
  onDelete,
  onEdit,
  objectId,
  viewOnly = false,
  options,
  objectType,
  widgetId,
  userId,
}: {
  type: string;
  i: number;
  onDelete?: (id: number) => void;
  onEdit?: () => void;
  objectId: string;
  viewOnly?: boolean;
  options: any;
  objectType: PageObjectType;
  widgetId?: string;
  userId?: string;
}) => {
  const widget = (widgetType: string) => {
    switch (widgetType) {
      case 'CLIENT_NAME':
        return <ClientName data-testid='client-name' objectId={objectId} objectType={objectType} options={options} />;
      case 'MARKET_VALUE_CHART':
        return <MarketValueChart data-testid='market-value-chart' type={objectType} id={objectId} options={options} />;
      case 'INITIATE_TRANSFER':
        return <InitiateTransfer data-testid='initiate-transfer' objectId={objectId} objectType={objectType} />;
      case 'HOLDINGS':
        return <Holdings data-testid='holdings' objectType={objectType} objectId={objectId} options={options} />;
      case 'GOALS':
        return <Goals data-testid='goals' objectType={objectType} objectId={objectId} options={options} />;
      case 'LEDGER':
        return <Ledger data-testid='ledger' objectType={objectType} objectId={objectId} options={options} />;
      case 'ACCOUNTS':
        return <Accounts
          data-testid='accounts'
          objectType={objectType}
          objectId={objectId}
          options={options}
          newAccountComponent={<CreateAccountWizardButton forObject={objectType === PageObjectType.HOUSEHOLD ? 'CLIENT_GROUP' : 'USER'} forId={objectId} />}
        />;
      case 'GOALS_AND_ACCOUNTS':
        return (
          <Grid data-testid='goals-and-account' container spacing={2}>
            <Grid item xs={12} md={6}>
              <Goals data-testid='goals' objectType={objectType} objectId={objectId} singleColumn options={options} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Accounts
                data-testid='accounts'
                objectType={objectType}
                objectId={objectId}
                singleColumn
                options={options}
                newAccountComponent={<CreateAccountWizardButton forObject={objectType === PageObjectType.HOUSEHOLD ? 'CLIENT_GROUP' : 'USER'} forId={objectId} />}
              />
            </Grid>
          </Grid>
        );
      case 'SUB_ACCOUNTS':
        return <SubAccounts
          data-testid='sub-accounts'
          objectType={objectType}
          objectId={objectId}
          options={options}
        />;
      case 'SCHEDULED_TRANSFERS':
        return <ScheduleTransfers data-testid='scheduled-transfers' objectId={objectId} options={options} objectType={objectType} />;
      case 'UPCOMING_TRANSACTIONS':
        return <PendingTransfers data-testid='upcoming-transfers' objectId={objectId} objectType={objectType} options={options} />;
      case 'DOCUMENTS':
        return <Documents data-testid='documents' objectId={objectId} objectType={objectType} options={options} userId={userId} />;
      case 'INCOME_ASSETS_AND_DEBTS':
        return <IncomeAssetsAndDebts data-testid='assets-and-debts' objectId={objectId} options={options} />;
      case 'PERSONAL_INFORMATION':
        return <PersonalInformation data-testid='personal-information' objectId={objectId} options={options} />;
      case 'RELATIONSHIP_INFORMATION':
        return <RelationshipInformation data-testid='relationship-information' objectId={objectId} options={options} />;
      case 'NON_INDIVIDUAL_INFORMATION':
        return <NonIndividualInformation data-testid='non-individual-information' objectId={objectId} options={options} />;
      case 'PERSONS_OF_INTEREST':
        return <PersonOfInterest data-testid='person-of-interest' objectId={objectId} options={options} />;
      case 'EMPLOYMENT_INFORMATION':
        return <EmploymentInformation data-testid='employment-information' objectId={objectId} options={options} />;
      case 'RESIDENCY_INFORMATION':
        return <ResidencyInformation data-testid='residency-information' objectId={objectId} options={options} />;
      case 'EDIT_ACCOUNT':
        return <EditAccount data-testid='edit-account' objectId={objectId} options={options} />;
      case 'RECENT_TRANSACTIONS':
        return <RecentTransactions data-testid='recent-transaction' objectId={objectId} objectType={objectType} options={options} />;
      case 'REFINITIV':
        return <Refinitiv data-testid='refinitiv' options={options} />;
      case 'SUITABILITY':
        return <Suitability data-testid='suitability' objectId={objectId} options={options} />;
      case 'GOAL_SUITABILITY':
        return <GoalSuitability data-testid='goal-suitability' objectId={objectId} options={options} />;
      case 'NEWS_AND_INSIGHTS':
        return <NewsAndInsights widgetId={widgetId} data-testid='news-and-insights' options={options} />;
      case 'CONTACTS':
        return <Contacts />;
      case 'ADVISOR_CONTACT_INFORMATION':
        return <AdvisorContactInformation data-testid='advisor-contact-information' objectId={objectId} options={options} objectType={objectType} />;
      case 'ACCESS':
        return <Access objectId={objectId} options={options} />;
      case 'SEARCH_SECURITIES':
        return <SearchSecurities />;
      case 'PROJECTED_INCOME_REPORT':
        return <ProjectedIncomeReport objectId={objectId} objectType={objectType} options={options} />;
      case 'NOTES':
        return <Notes objectId={objectId} objectType={objectType} options={options} />;
      case 'AUDIT_LOG':
        return <AuditLog objectId={objectId} objectType={objectType} options={options} />;
      case 'TRADE_ORDERS':
        return <TradeOrders objectId={objectId} objectType={objectType} options={options} />;
      case 'TRANSFERS':
        return <Transfers objectId={objectId} objectType={objectType} options={options} />;
      case 'FEE_DETAILS':
        return <FeeDetails objectId={objectId} objectType={objectType} options={options} />;
      case 'HOUSE_HOLD_MEMBERS':
        return <TableHouseHoldMembers objectId={objectId} objectType={objectType} options={options} />;
      case 'COMPLIANCE':
        return <Compliance objectId={objectId} options={options} />;
      case 'ID_VERIFICATION':
        return <IdVerification objectId={objectId} options={options} />;
      case 'PORTFOLIO':
        return <Portfolio id={objectId} type={objectType} options={options} />;
      case 'RELATIONSHIPS':
        return <Relationships objectId={objectId} options={options} />;
      case 'REVIEW_BANK_ACCOUNT_INFORMATION':
      case 'LINKED_BANK_ACCOUNTS':
        return <LinkedBankAccounts data-testid='linked-bank-accounts' userId={objectId} options={options} />;
      case 'HTML_TEMPLATE':
        return <HtmlTemplate objectId={objectId} options={options} objectType={objectType} />;
      case 'BULK_TRADE_REQUESTS':
        return <BulkTradeRequests objectId={objectId} options={options} objectType={objectType} />;
      case 'MODEL_PORTFOLIO_HOLDINGS':
        return <ModelPortfolioHoldings data-testid='holdings' objectType={objectType} objectId={objectId} options={options} />;
      default:
        return (
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ justifyContent: 'space-between', display: 'flex' }}>
              <Typography variant='headingXSmall'>{type}</Typography>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <ErrorBoundary>
      <Box height='100%' sx={{ position: 'relative', mb: 4 }}>
        {widget(type)}
        {!viewOnly && onEdit && (
          <IconButton
            onClick={() => onEdit()}
            sx={{
              position: 'absolute',
              right: '54px',
              top: '8px',
              zIndex: '2',
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {!viewOnly && onDelete && (
          <IconButton
            onClick={() => onDelete(i)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
              zIndex: '2',
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ErrorBoundary>
  );
};
