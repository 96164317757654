import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash/fp';
import { TabPanel } from '../../../../components/tabs/ovTabs';
import { usePageState } from '../../../../util/usePageState';
import { Box, Typography } from '../../../1-primative';
import {
  Tabs, Tab, CardContent, Card, Button,
} from '../../../2-component';
import { DocumentList } from './components/documentList';
import { Agreements } from './components/agreements';
import { UploadedDocuments } from './components/uploadedDocuments';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext } from '../../../../providers/userContextProvider';
import { UploadDocument } from './components/uploadDocument';
import { ClientReports } from './components/clientReports';

const accountStatementsTypes = ['MANAGEMENT_FEES_ANNUAL_REPORT', 'ADMINISTRATIVE_FEES_ANNUAL_REPORT', 'ANNUAL_PERFORMANCE_REPORT', 'OV_MONTHLY_ACCOUNT_STATEMENTS', 'MONTHLY_ACCOUNT_STATEMENTS'];

const taxStatementsTypes = [
  'TAX_SLIPS_LAST_10_MONTHS',
  'TAX_SLIPS_FIRST_60_DAYS',
  'TAX_SLIPS_T4RSP_RL2',
  'TAX_SLIPS_T4RIF_RL2',
  'TAX_SLIPS_T4A_RL1',
  'TAX_SLIPS_NR4',
  'TAX_SLIPS_T5008',
  'TAX_SLIPS_TRADING_SUMMARY',
  'TAX_SLIPS_T5_RL3',
  'TAX_SLIPS_T3_RL16',
  'TAX_SLIPS_T3_BREAKDOWN',
  'TAX_SLIPS_T5013_RL15',
  'TAX_SLIP_GENERAL',
];

const tradeConfirmationTypes = ['TRADE_CONFIRMATION'];

const uploadedDocumentsTypes = ['OTHER', 'BANKING', 'ID_VERIFICATION'];

export const Documents = ({
  objectId, objectType, options = {}, userId,
}: { objectId: string; objectType: PageObjectType; options?: any; userId?: string }) => {
  const { t } = useTranslation(['document']);
  const [tab, setTab] = usePageState('statements', 'documentsTab');
  const [open, setOpen] = useState<boolean>(false);
  const { sys } = useThemeTokens();
  const { userId: contextUserId, activeOrganization } = useContext(UserContext);
  const clientReportFeatureFlag = activeOrganization.availableFeatureFlags?.includes('CONFIGURABLE_CLIENT_REPORTS');

  return (
    <Box width='100%'>
      <Typography sx={{ mb: 2 }} variant='headingSmall'>
        {options.customTitle ? translateBackend(options.customTitle) : t('document:header')}
      </Typography>
      <Card>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='end'
          sx={{
            borderBottom: `1px solid ${sys.color.outline}`,
            pt: 1,
            pl: 3,
            pr: 3,
          }}
        >
          <Tabs
            scrollButtons='auto'
            value={tab}
            onChange={(e: any, newValue: any) => {
              setTab(newValue);
            }}
            sx={{ overflow: 'auto' }}
          >
            <Tab label={t('document:tabs.statementsTab.title')} value='statements' />
            <Tab label={t('document:tabs.taxDocumentsTab.title')} value='taxDocuments' />
            {(isUndefined(options.showAgreements) || options.showAgreements) && (
              <Tab label={t('document:tabs.agreementsTab.title')} value='agreements' />
            )}
            {(isUndefined(options.showTradeConfirmations) || options.showTradeConfirmations) && <Tab label={t('document:tabs.tradeConfirmationsTab.title')} value='tradeConfirmations' />}
            {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD].includes(objectType)
              && (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && <Tab label={t('document:tabs.uploadedDocuments.title')} value='uploadedDocuments' />}
            {(options.showClientReports && clientReportFeatureFlag) && <Tab label={t('document:tabs.clientReportsTab.title')} value='clientReports' />}
          </Tabs>
          {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD].includes(objectType)
            && (isUndefined(options.allowToUploadDocuments) || options.allowToUploadDocuments) && (
              <Button
                sx={{ mb: 1, mt: { xs: 1, sm: 0 } }}
                onClick={() => {
                  if (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) {
                    setTab('uploadedDocuments');
                  }
                  setOpen(true);
                }}
                label={t('document:button.uploadDocumentsMobile')}
                size='sm'
              />
          )}
        </Box>
        <CardContent>
          <TabPanel value={tab} index='statements'>
            <DocumentList objectId={objectId} objectType={objectType} documentTypes={accountStatementsTypes} options={options} />
          </TabPanel>
          <TabPanel value={tab} index='taxDocuments'>
            <DocumentList objectId={objectId} objectType={objectType} documentTypes={taxStatementsTypes} options={options} />
          </TabPanel>
          {(isUndefined(options.showAgreements) || options.showAgreements) && (
            <TabPanel value={tab} index='agreements'>
              <Agreements objectId={objectId} objectType={objectType} documentTypes={[]}/>
            </TabPanel>
          )}
          {(isUndefined(options.showTradeConfirmations) || options.showTradeConfirmations) && (
            <TabPanel value={tab} index='tradeConfirmations'>
              <DocumentList objectId={objectId} objectType={objectType} documentTypes={tradeConfirmationTypes} options={options} />
            </TabPanel>
          )}
          {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD].includes(objectType)
            && (isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && (
            <TabPanel value={tab} index='uploadedDocuments'>
              <UploadedDocuments objectId={objectId} documentTypes={uploadedDocumentsTypes} open={open} setOpen={setOpen} objectType={objectType} userId={userId || contextUserId} />
            </TabPanel>
          )}
          {(options.showClientReports) && (<TabPanel value={tab} index='clientReports'>
            <ClientReports objectId={objectId} objectType={objectType} />
          </TabPanel>)}
        </CardContent>
      </Card>
      {(isUndefined(options.allowToUploadDocuments) || options.allowToUploadDocuments) && !(isUndefined(options.showUploadedDocuments) || options.showUploadedDocuments) && (
        <UploadDocument open={open} setOpen={setOpen} refetch={() => {}} objectId={objectId} objectType={objectType} userId={userId} />
      )}
    </Box>
  );
};
