import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewHousehold } from './components/newHousehold';
import { FilterHouseholds } from './components/filterHouseholds';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { ClientGroupTypes } from '../../../interfaces';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableHeadCell,
  TableRow, TableCell, TableBody, Pagination, TextField,
  CardContent,
  Card,
} from '../../2-component';
import { formatMoneyValue } from '../../../util';

interface Household {
  id: string,
  type: string,
  name: string,
  organization: {
    name: string,
  },
  primaryUser: {
    firstName: string,
    primaryEmail: string,
  }
  relationships: any[],
  statistics: {
    marketValueCents: number,
  },
}
export const FETCH_HOUSEHOLDS = gql`
  query fetchHouseholds($input: FetchClientGroupsInput!) {
    fetchClientGroups(input: $input) {
      clientGroups{
        id
        name
        type
        primaryUser{
          firstName
          primaryEmail
        }
        organization{
          name
        }
        relationships{
          type
          user{
            id
          }
        }
        statistics {
          marketValueCents
        }
      }
      totalCount
    }
  }
`;

export const Households = () => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const { t } = useTranslation(['clients', 'household']);
  const navigate = useNavigate();
  const [filterByState, setFilterByState] = useState<string>('ACTIVE');
  const [page, setPage] = usePageState(1, 'page');
  const [searchText, setSearchText] = usePageState('', 'q');
  const [sortField, setSortField] = usePageState('name', 'sf');
  const [sortDesc, setSortDesc] = usePageState(true, 'sd');
  const [pageSize, setPageSize] = usePageState(15, 'ps');

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_HOUSEHOLDS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          type: ClientGroupTypes.Household,
          searchText,
          states: filterByState,
        },
        pagination: {
          sortField, sortDesc, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (error) (<Typography>Error</Typography>);

  const onSort = (field: string) => {
    if (sortField === field) {
      setSortDesc(!sortDesc);
    }
    setSortField(field);
  };

  const sortDirection = (field: string) => (sortField === field ? sortDesc ? 'desc' : 'asc' : undefined);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between'>
          <TextField
            sx={{ width: '300px' }}
            value={searchText}
            onChange={(event: any) => {
              setSearchText(event.target.value);
              setPage(1);
            }}
            leadingIcon='search'
          />
          <Box display='flex'>
            <FilterHouseholds state={filterByState} setState={setFilterByState} />
            { permissions.includes('write:client_group') && <NewHousehold afterCreate={refetch} /> }
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell
                isSortable
                onClick={() => onSort('name')}
                sortDirection={sortDirection('name')}
              >{t('household:table.headers.name')}</TableHeadCell>
              <TableHeadCell>{t('household:table.headers.contact')}</TableHeadCell>
              <TableHeadCell>{t('household:table.headers.email')}</TableHeadCell>
              <TableHeadCell right>{t('household:table.headers.relationCount')}</TableHeadCell>
              <TableHeadCell right>{t('household:table.headers.marketValue')}</TableHeadCell>
              <TableHeadCell right>{t('household:table.headers.organization')}</TableHeadCell>
            </TableRow>
            { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
              <TableRow key={i}>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
                <TableCell><Skeleton width='100%' /></TableCell>
              </TableRow>
            ))}
            {(data || previousData)?.fetchClientGroups?.clientGroups?.map((houseHoldItem: Household) => (
              <TableRow
                hover
                key={houseHoldItem.id}
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => navigate(`/households/${houseHoldItem.id}`)}
              >
                <TableCell>
                  {houseHoldItem.name}
                </TableCell>
                <TableCell>
                  {houseHoldItem.primaryUser.firstName}
                </TableCell>
                <TableCell>
                  {houseHoldItem.primaryUser.primaryEmail}
                </TableCell>
                <TableCell number>
                  {houseHoldItem.relationships.length}
                </TableCell>
                <TableCell number>
                  {formatMoneyValue(houseHoldItem.statistics.marketValueCents)}
                </TableCell>
                <TableCell right>
                  {houseHoldItem.organization.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchClientGroups?.totalCount ?? 0) / pageSize)}
        page={page}
        perPage={pageSize}
        onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
    </Card>
  );
};

export { ClientGroupBreadcrumb } from './clientGroupBreadcrumb';
