import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button, CircularProgress, Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { colors } from '../../../ovComponents';
import StatsBlock from '../../../components/misc/statsBlock';
import { Date } from '../../../components/misc/date/date';
import { SubAccountDailyFee } from '../../../interfaces/subAccountDailyFee';
import { formatDecimalMoneyValue, formatMoneyValue } from '../../../util';
import OverrideFeesModal, { UPDATE_SUB_ACCOUNT_BILLING } from '../../../components/modals/overrideFeesModal';
import { SubAccountBilling } from '../../../interfaces/subAccountBilling';
import { BillingCycleStates } from '../../../interfaces/billingCycle';
import { getSubAccountName } from '../../../interfaces/subAccount';
import { DailyFeeGridBox } from './dailyFeeGridBox';
import { useGlobalToast } from '../../../providers/globalToastProvider';

export const FETCH_SUB_ACCOUNT_BILLING = gql`
  query fetchSubAccountBilling ($input: FetchSubAccountBillingsInput!) {
    fetchSubAccountBillings (input: $input) {
      subAccountBillings {
        id
        billingCycle { id state }
        feeTier { id name }
        subAccount {
          id
          account { id type user { id firstName lastName physicalAddress { province } } }
        }
        feeCents
        salesTaxCents
        adjustedFeeCents
        adjustedSalesTaxCents
        notes
        marketValueCentsOnLastDay
        chargeableMarketValueCentsOnLastDay
        billingDate
        startDate
        endDate
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY = `query fetchSubAccountDailyFees($input: FetchSubAccountDailyFeesInput!) {
  fetchSubAccountDailyFees(input: $input) {
    totalCount
    subAccountDailyFees {
      id
      date
      user {
        id
        firstName
        lastName
      }
      subAccount {
        id
        goal {
          id
          name
        }
        account {
          id
          type
        }
      }
      marketValueCents
      moneyAvailableCents
      gridScopeTotalMarketValueCents
      annualFeeBps
      chargeableMarketValueCents
      totalDailyFeeCents
      dailyFeeBps
      dailyFixedFeeCents
    }
  }
}`;

export const FETCH_SUB_ACCOUNT_DAILY_FEES = gql`${FETCH_SUB_ACCOUNT_DAILY_FEES_QUERY}`;

const calculateFeesAndTax = (original: boolean, subAccountBilling?: SubAccountBilling) => {
  if (!original && subAccountBilling && !isNil(subAccountBilling?.adjustedFeeCents) && !isNil(subAccountBilling?.adjustedSalesTaxCents)) {
    const { adjustedFeeCents, adjustedSalesTaxCents } = subAccountBilling;
    return (adjustedFeeCents + adjustedSalesTaxCents);
  }
  return (subAccountBilling?.feeCents ?? 0) + (subAccountBilling?.salesTaxCents ?? 0);
};

const DailyFeeDetailsTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { subAccountId, billingCycleId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const selectedSubAccountBillingId = useRef<string>('');
  const [subBilling, setSubBilling] = useState<SubAccountBilling>();
  const { showToast } = useGlobalToast();

  const pageSize = 32;

  const { refetch, loading: subBillingLoading } = useQuery(FETCH_SUB_ACCOUNT_BILLING, {
    variables: {
      input: {
        filter: {
          subAccountIds: subAccountId,
          billingCycleIds: billingCycleId,
        },
      },
    },
    onCompleted: (res: any) => {
      if (res?.fetchSubAccountBillings?.subAccountBillings?.length > 0) {
        const subAccountBilling = res.fetchSubAccountBillings.subAccountBillings[0];
        setSubBilling(subAccountBilling);
      }
    },
  });

  const [updateSubAccountBilling] = useMutation(UPDATE_SUB_ACCOUNT_BILLING);

  const {
    loading, data, previousData,
  } = useQuery(FETCH_SUB_ACCOUNT_DAILY_FEES, {
    variables: {
      input: {
        filter: {
          subAccountIds: subAccountId,
          billingCycleIds: billingCycleId,
        },
        pagination: {
          sortField: 'date', sortDesc: true, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
  });

  if (loading || subBillingLoading || !subBilling) {
    return (
      <CircularProgress sx={{ m: 18 }} />
    );
  }

  return (
    <>
      <Grid container sx={{ overflow: 'hidden' }}>
        <DailyFeeGridBox row={subBilling} />
        <Grid container sx={{ p: 2 }} spacing={1}>
          <Grid item xs={2}>
            <StatsBlock title={t('feeAndBilling:feeReport.table.billableAumLastDay')} value={formatMoneyValue(subBilling?.marketValueCentsOnLastDay)}/>
          </Grid>
          {!isNil(subBilling?.adjustedFeeCents) ? (
            <>
              <Grid item xs={2} sx={{ color: 'green' }}>
                <StatsBlock
                  title={t('feeAndBilling:feeReport.table.adjustedAccuredFees')}
                  value={formatMoneyValue(calculateFeesAndTax(false, subBilling))}
                />
              </Grid>
              <Grid item xs={2} sx={{ textDecoration: 'line-through', color: colors.neutral400 }}>
                <StatsBlock
                  title='&nbsp;'
                  value={formatMoneyValue(calculateFeesAndTax(true, subBilling))}
                />
              </Grid>
              {subBilling.billingCycle?.state === BillingCycleStates.AWAITING_REVIEW && (
                <Grid item xs={6}>
                  <Button
                    sx={{ float: 'right' }}
                    type='submit'
                    variant='contained'
                    color='secondary'
                    onClick={async () => {
                      if (subBilling.id) {
                        const response = await updateSubAccountBilling({
                          variables: {
                            input: {
                              adjustedFeeCents: null,
                              adjustedSalesTaxCents: null,
                              notes: null,
                              subAccountBillingId: subBilling.id,
                            },
                          },
                        });
                        if (response?.data) {
                          showToast({ severity: 'info', message: t('feeAndBilling:feeReport.overrideFees.undoMessage') });
                          await refetch();
                        }
                      }
                    }}
                  >
                    {t('feeAndBilling:feeReport.overrideFees.undo')}
                  </Button>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <StatsBlock
                  title={t('feeAndBilling:feeReport.table.accuredFees')}
                  value={formatMoneyValue(calculateFeesAndTax(true, subBilling))}
                />
              </Grid>
              {subBilling.billingCycle?.state === BillingCycleStates.AWAITING_REVIEW && (
                <Grid item xs={6}>
                  <Button
                    sx={{ float: 'right' }}
                    type='submit'
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      if (subBilling.id) {
                        selectedSubAccountBillingId.current = subBilling.id;
                        setModalOpen(true);
                      }
                    }}
                  >
                    {t('feeAndBilling:feeReport.overrideFees.title')}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow sx={{ textAlign: 'center' }}>
              <TableCell sx={{ textAlign: 'left' }}><Typography variant='overline'>{t('feeAndBilling:feeReport.dailyFee.date')}</Typography></TableCell>
              <TableCell align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.dailyFee.billableAum')}</Typography></TableCell>
              <TableCell align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.table.billableAumWithReductionsApplied')}</Typography></TableCell>
              <TableCell align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.dailyFee.dailyFeeBps')}</Typography></TableCell>
              <TableCell align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.dailyFee.dailyFixedFeeCents')}</Typography></TableCell>
              <TableCell align="right"><Typography variant='overline'>{t('feeAndBilling:feeReport.dailyFee.totalDailyFeeCents')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { data?.fetchSubAccountDailyFees.subAccountDailyFees.map((x: SubAccountDailyFee) => (
              <TableRow key={x.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}><Date variant='subtitle2' date={x.date} /></TableCell>
                <TableCell align="right">{formatMoneyValue(x.marketValueCents)}</TableCell>
                <TableCell align="right">
                {formatMoneyValue((x?.marketValueCents ?? 0) - (x.chargeableMarketValueCents ?? 0))}
                </TableCell>
                <TableCell align="right">{x.dailyFeeBps?.toFixed(4) ?? 0}</TableCell>
                <TableCell align="right">{formatDecimalMoneyValue(x.dailyFixedFeeCents)}</TableCell>
                <TableCell align="right">{formatDecimalMoneyValue(x.totalDailyFeeCents)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchSubAccountDailyFees?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      </Grid>
      <OverrideFeesModal
        handleClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
        afterUpdate={async () => {
          await refetch();
        }}
        subAccountBilling={subBilling}
        subAccountTitle={getSubAccountName(subBilling?.subAccount, true)}
      />
    </>
  );
};

export default DailyFeeDetailsTable;
